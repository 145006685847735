/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IntegrationActions } from '@dpa-shared-merlot/store';
import { AppConfig, I18NService } from '@ws1c/intelligence-common';
import { AccountService, AlertBannerActions, UserAdminAccountActions, UserAdminAccountService } from '@ws1c/intelligence-core';
import {
  AccountByUserDescriptor,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  CspAccount,
  CspAccountRequest,
  Directory,
  DirectoryType,
  RequestStatusType,
  UserAdminAccount,
  UserAdminAccountSearchRequest,
  UserDescriptor,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for UserAdminAccountEffects actions
 * @exports
 * @class UserAdminAccountEffects
 */
@Injectable()
export class UserAdminAccountEffects {
  /**
   * searchUserAdminAccounts$
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public searchUserAdminAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.searchUserAdminAccounts),
      switchMap((props: { userAdminAccountSearchRequest: UserAdminAccountSearchRequest; addAccountDetails: boolean }) => {
        return this.userAdminAccountService.search(props.userAdminAccountSearchRequest).pipe(
          map((userAdminAccounts: UserAdminAccount[]) => {
            if (props.addAccountDetails && userAdminAccounts?.length > 0) {
              return UserAdminAccountActions.addAccountDetailsToUser({ userAdminAccounts });
            }
            return UserAdminAccountActions.searchUserAdminAccountsSuccess({ userAdminAccounts });
          }),
          catchError(() => {
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('USER_ADMIN_ACCOUNT.SEARCH_FAILURE'),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * addAccountDetailsToUser$
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public addAccountDetailsToUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.addAccountDetailsToUser),
      switchMap((props: { userAdminAccounts: UserAdminAccount[] }) => {
        const userDescriptors = props.userAdminAccounts.map(
          (account: UserAdminAccount) =>
            new UserDescriptor({
              id: account.id,
            }),
        );
        return this.accountService.getAccountsByUserDescriptors(userDescriptors).pipe(
          map((accountByUserDescriptor: AccountByUserDescriptor) => {
            return UserAdminAccountActions.addAccountDetailsToUserSuccess({
              userAdminAccounts: props.userAdminAccounts,
              accountByUserDescriptor,
            });
          }),
          catchError(() => {
            // INTEL-29890 Gracefully handle results in error case.
            // This API is only used to populate additional user information in the search results,
            // it should be able to ignore errors and display what it can
            return of(
              UserAdminAccountActions.addAccountDetailsToUserSuccess({
                userAdminAccounts: props.userAdminAccounts,
                accountByUserDescriptor: {},
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * getAzureDirectory$
   *
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public getAzureDirectory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.getAzureDirectory),
      switchMap(() => {
        return this.userAdminAccountService.getDirectoryByType(DirectoryType.AZURE).pipe(
          map((azureDirectory: Directory) => UserAdminAccountActions.getAzureDirectorySuccess({ azureDirectory })),
          catchError(() => of(UserAdminAccountActions.getAzureDirectoryFailure())),
        );
      }),
    ),
  );

  /**
   * deleteAzureDirectory$
   *
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public deleteAzureDirectory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.deleteAzureDirectory),
      switchMap(({ id }: { id: string }) => {
        return this.userAdminAccountService.deleteDirectory(DirectoryType.AZURE, id).pipe(
          mergeMap(() => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('INTEGRATIONS.AZURE.DEAUTHORIZE_SUCCESS_MESSAGE'),
              }),
              IntegrationActions.showDeleteAzureConnectionModal({
                showDeleteAzureConnectionModal: false,
              }),
              UserAdminAccountActions.getAzureDirectory(),
            ];
          }),
          catchError((reason: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('INTEGRATIONS.AZURE.DEAUTHORIZE_FAILURE_MESSAGE', reason.getFullReason()),
            }),
            IntegrationActions.showDeleteAzureConnectionModal({
              showDeleteAzureConnectionModal: false,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getCspAccountRequest$
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public getCspAccountRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.getCspAccountRequest),
      switchMap(() => {
        return this.userAdminAccountService.getCspAccountRequest().pipe(
          map((cspAccountRequest: CspAccount) =>
            UserAdminAccountActions.getCspAccountRequestSuccess({
              cspAccountRequestDetails: cspAccountRequest,
            }),
          ),
          catchError(({ status, error }: { status: number; error: WebError }) => {
            const action: Action = UserAdminAccountActions.getCspAccountRequestSuccess({
              cspAccountRequestDetails: new CspAccount({ cspMigrationStatus: RequestStatusType.NONE }),
            });
            if (status === AppConfig.NOT_FOUND_STATUS_CODE) {
              return of(action);
            }
            return [
              UserAdminAccountActions.getCspAccountRequestFailure(),
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('USER_ADMIN_ACCOUNT.GET_CSP_ACCOUNT_REQUEST_FAILURE_MSG', {
                  reason: error.getFullReason(),
                }),
                alertType: ALERT_BANNER_TYPE.DANGER,
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * submitRequestForCspAccount$
   * @type {Observable<Action>}
   * @memberof UserAdminAccountEffects
   */
  public submitRequestForCspAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAdminAccountActions.submitRequestForCspAccount),
      switchMap((action: ReturnType<typeof UserAdminAccountActions.submitRequestForCspAccount>) => {
        const request: CspAccountRequest = new CspAccountRequest({
          email: action.email,
          cspMigrationStatus: RequestStatusType.REQUESTED,
        });
        return this.userAdminAccountService.submitRequestForCspAccount(request).pipe(
          mergeMap(() => {
            return [
              UserAdminAccountActions.submitRequestForCspAccountSuccess(),
              UserAdminAccountActions.setCspAccountWithEmailExistenceState({ isCspAccountWithEmailAlreadyExists: false }),
              UserAdminAccountActions.getCspAccountRequest(),
            ];
          }),
          catchError(({ status, error }: { status: number; error: WebError }) => {
            const isCspAccountWithEmailAlreadyExists = status === AppConfig.RECORD_ALREADY_EXISTS_STATUS_CODE;
            const actions: Action[] = [
              UserAdminAccountActions.submitRequestForCspAccountFailure(),
              UserAdminAccountActions.setCspAccountWithEmailExistenceState({ isCspAccountWithEmailAlreadyExists }),
            ];
            if (!isCspAccountWithEmailAlreadyExists) {
              actions.push(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  autoDismiss: true,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('USER_ADMIN_ACCOUNT.SUBMIT_REQUEST_FOR_CSP_ACCOUNT_FAILURE_MSG', {
                    reason: error.getFullReason(),
                  }),
                }),
              );
            }
            return actions;
          }),
        );
      }),
    ),
  );

  /**
   * Creates an instance of UserAdminAccountEffects
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {UserAdminAccountService} userAdminAccountService
   * @param {AccountService} accountService
   * @memberof UserAdminAccountEffects
   */
  constructor(
    private actions$: Actions,
    private i18nService: I18NService,
    private userAdminAccountService: UserAdminAccountService,
    private accountService: AccountService,
  ) {}
}
