/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IncidentDashboardActions } from '@ws1c/intelligence-common';
import { DashboardActions } from '@ws1c/intelligence-core';

/**
 * IncidentDashboardEffects
 * @exports IncidentDashboardEffects
 * @class IncidentDashboardEffects
 */
@Injectable()
export class IncidentDashboardEffects {
  /**
   * setIncidentId$
   * @type {Observable<{incidentId: string}>}
   * @memberof IncidentDashboardEffects
   */
  public setIncidentId$: Observable<{ incidentId: string }> = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentDashboardActions.setIncidentId),
      map(({ incidentId }: ReturnType<typeof IncidentDashboardActions.setIncidentId>) => DashboardActions.setIncidentId({ incidentId })),
    ),
  );

  /**
   * clearIncidentId$
   * @type {Observable<Action>}
   * @memberof IncidentDashboardEffects
   */
  public clearIncidentId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentDashboardActions.clearIncidentId),
      map(() => DashboardActions.clearIncidentId()),
    ),
  );

  /**
   * setWizardUrls$
   * @type {Observable<Action>}
   * @memberof IncidentDashboardEffects
   */
  public setWizardUrls$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentDashboardActions.requestRootUrlAck),
      map(({ rootUrl }: ReturnType<typeof IncidentDashboardActions.requestRootUrlAck>) => DashboardActions.setRootUrl({ rootUrl })),
    ),
  );

  /**
   * resetRootUrl$
   * @type {Observable<Action>}
   * @memberof IncidentDashboardEffects
   */
  public resetRootUrl$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentDashboardActions.resetRootUrl),
      map(() => DashboardActions.resetRootUrl()),
    ),
  );

  /**
   * Create an instance of IncidentDashboardEffects
   * @param {Actions} actions$
   * @memberof IncidentDashboardEffects
   */
  constructor(private actions$: Actions) {}
}
