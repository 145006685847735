/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DashboardActions } from '@ws1c/intelligence-core/store/dashboard/dashboard.actions';
import { StandardDashboardRequest } from '@ws1c/intelligence-models';

/**
 * PerformanceIndicatorsDashboardEffects
 * @export
 * @class PerformanceIndicatorsDashboardEffects
 */
@Injectable()
export class PerformanceIndicatorsDashboardEffects {
  /**
   * loadDashboard$
   * @type {Observable<Action>}
   * @memberof PerformanceIndicatorsDashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadPerformanceIndicatorsDashboard),
      switchMap(({ dashboardType, ruleGroup, isCrossCategory }: ReturnType<typeof DashboardActions.loadPerformanceIndicatorsDashboard>) => {
        const request = new StandardDashboardRequest(dashboardType, ruleGroup);
        return [
          DashboardActions.loadStandardDashboard({
            request,
            isCrossCategory,
          }),
        ];
      }),
    ),
  );

  /**
   * PerformanceIndicatorsDashboardEffects
   * @param {Actions} actions$
   * @memberOf PerformanceIndicatorsDashboardEffects
   */
  constructor(private actions$: Actions) {}
}
