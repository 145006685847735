/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { uniq } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { HorizonDashboardConfig } from '@dpa-shared-merlot/model/dashboard/horizon-dashboard.config';
import { HorizonActions, HorizonSelectors } from '@dpa-shared-merlot/store/dashboard';
import { I18NService } from '@ws1c/intelligence-common';
import { AlertBannerActions, DashboardActions, DashboardSelectors, IntegrationMetaService } from '@ws1c/intelligence-core';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  COLUMN_NAMES,
  FilterRule,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  SuggestionCriteria,
  SuggestionFilterBy,
  SuggestionSearchItem,
  SuggestionSearchResponse,
  Trend,
  TrendDefinition,
} from '@ws1c/intelligence-models';

/**
 * HorizonEffects
 * @export
 * @class HorizonEffects
 */
@Injectable()
export class HorizonEffects {
  /**
   * loadHorizonDashboardWithMergedRequests$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public loadHorizonDashboardWithMergedRequests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.loadHorizonDashboardWithMergedRequests, HorizonActions.setHorizonTrendDateRange),
      withLatestFrom(this.store.select(HorizonSelectors.getHorizonDashboardWithMergedRequests)),
      switchMap(([_action, requests]: [Action, StandardDashboardRequest[]]) => {
        return of(DashboardActions.loadMergedStandardDashboard({ requests, isCrossCategory: true }));
      }),
    ),
  );

  /**
   * convertHorizonFilterRules$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public convertHorizonFilterRules$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.convertHorizonFilterRules),
      switchMap(({ filterRules }: ReturnType<typeof HorizonActions.convertHorizonFilterRules>) => {
        const suggestionFilterBys: SuggestionFilterBy[] = [];
        filterRules?.forEach((filterRule: FilterRule) => {
          if (filterRule.attribute) {
            suggestionFilterBys.push(
              new SuggestionFilterBy({
                fieldName: filterRule.attribute,
                values: [filterRule.data],
              }),
            );
          }
        });
        const suggestionCriteria = new SuggestionCriteria({
          groupFieldName: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
          columnName: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id,
          query: '',
          size: 50,
          filterBys: suggestionFilterBys,
        });
        return this.integrationMetaService.getFilterSuggestionsV2(suggestionCriteria).pipe(
          mergeMap((suggestionSearchResponse: SuggestionSearchResponse) => {
            const convertedFilterRule: FilterRule = new FilterRule({
              attribute: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id,
              condition: FilterRule.FILTER_CONDITION.includes,
              data: suggestionSearchResponse?.values.map((item) => item.value),
            });
            return [
              HorizonActions.setHorizonFilterRuleGroup({
                filterRules: [convertedFilterRule],
              }),
              HorizonActions.setSelectedDeploymentTypes({
                selectedDeploymentTypes: uniq(suggestionSearchResponse?.values.map((item) => item.groupName)),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * convertHorizonTitanFilterRules$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public convertHorizonTitanFilterRules$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.convertHorizonTitanFilterRules),
      switchMap(({ filterRules }: ReturnType<typeof HorizonActions.convertHorizonTitanFilterRules>) => {
        const suggestionFilterBys: SuggestionFilterBy[] = [];
        filterRules?.forEach((filterRule: FilterRule) => {
          if (filterRule.attribute) {
            suggestionFilterBys.push(
              new SuggestionFilterBy({
                fieldName: filterRule.attribute,
                values: [filterRule.data],
              }),
            );
          }
        });
        const suggestionSiteNameFilterBys = suggestionFilterBys.filter(
          (ele: SuggestionFilterBy) => ele.fieldName === COLUMN_NAMES.byFullyQualifiedName.horizon_site_titan_site_name,
        );
        const setHorizonFilter = (res: SuggestionSearchResponse) => {
          const convertedFilterRule: FilterRule = new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: res?.values.map((item) => item.value),
          });
          return [
            HorizonActions.setHorizonFilterRuleGroup({
              filterRules: [convertedFilterRule],
            }),
            HorizonActions.setSelectedDeploymentTypes({
              selectedDeploymentTypes: uniq(res?.values.map((item) => item.groupName)),
            }),
          ];
        };
        if (suggestionSiteNameFilterBys?.length) {
          const criteria = new SuggestionCriteria({
            columnName: COLUMN_NAMES.byFullyQualifiedName.horizon_site_titan_site_id,
            query: '',
            size: 50,
            filterBys: suggestionSiteNameFilterBys,
          });
          return this.integrationMetaService.getFilterSuggestionsV2(criteria).pipe(
            switchMap((response: SuggestionSearchResponse) => {
              const suggestionSiteIdFilterBys = [];
              response?.values.forEach((val: SuggestionSearchItem) => {
                suggestionSiteIdFilterBys.push(
                  new SuggestionFilterBy({
                    fieldName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_site_id,
                    values: [val.value],
                  }),
                );
              });
              const criteriaBySiteId = new SuggestionCriteria({
                groupFieldName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan__edge_type,
                columnName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
                query: '',
                size: 50,
                filterBys: suggestionSiteIdFilterBys,
              });
              return this.integrationMetaService.getFilterSuggestionsV2(criteriaBySiteId).pipe(
                switchMap((resBySiteId: SuggestionSearchResponse) => {
                  return setHorizonFilter(resBySiteId);
                }),
              );
            }),
          );
        }
        const suggestionCriteria = new SuggestionCriteria({
          groupFieldName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan__edge_type,
          columnName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
          query: '',
          size: 50,
          filterBys: suggestionFilterBys,
        });
        return this.integrationMetaService.getFilterSuggestionsV2(suggestionCriteria).pipe(
          switchMap((suggestionSearchResponse: SuggestionSearchResponse) => {
            return setHorizonFilter(suggestionSearchResponse);
          }),
        );
      }),
    ),
  );

  /**
   * selectDeploymentTab$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public selectDeploymentTab$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.selectDeploymentTab),
      withLatestFrom(this.store.select(HorizonSelectors.getHorizonFilterRuleGroup)),
      switchMap(
        ([{ selectedDeploymentTab, isTitan }, horizonFilterRuleGroup]: [
          ReturnType<typeof HorizonActions.selectDeploymentTab>,
          RuleGroup,
        ]) => {
          const podOrEdgeTypeAttribute: string = isTitan
            ? COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan__edge_type
            : COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type;
          const idAttribute: string = isTitan
            ? COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id
            : COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id;
          const suggestionFilterBys: SuggestionFilterBy[] = [];
          let isDeploymentFilterExist = false;
          horizonFilterRuleGroup.rules?.forEach((filterRule: FilterRule) => {
            if (!filterRule.attribute) {
              return;
            }
            isDeploymentFilterExist = selectedDeploymentTab && filterRule.attribute === podOrEdgeTypeAttribute;
            suggestionFilterBys.push(
              new SuggestionFilterBy({
                fieldName: filterRule.attribute,
                values: isDeploymentFilterExist ? [selectedDeploymentTab] : filterRule.data,
              }),
            );
          });
          if (selectedDeploymentTab && !isDeploymentFilterExist) {
            suggestionFilterBys.push(
              new SuggestionFilterBy({
                fieldName: podOrEdgeTypeAttribute,
                values: [selectedDeploymentTab],
              }),
            );
          }
          const suggestionCriteria = new SuggestionCriteria({
            columnName: idAttribute,
            query: '',
            size: 50,
            filterBys: suggestionFilterBys,
          });
          const titanKey: string = isTitan ? 'TITAN' : 'V1';
          const dashboardTypes: StandardDashboardType[] = (HorizonDashboardConfig.dashboardTypeListByDeploymentType[
            selectedDeploymentTab
          ] ?? HorizonDashboardConfig.dashboardTypeListByDeploymentType.DEFAULT)?.[titanKey];
          return this.integrationMetaService.getFilterSuggestionsV2(suggestionCriteria).pipe(
            switchMap((suggestionSearchResponse: SuggestionSearchResponse) => {
              return [
                HorizonActions.setPodIdsForActiveDeploymentTab({
                  podIdsForActiveDeploymentTab: suggestionSearchResponse?.values.map((item: SuggestionSearchItem) => item.value),
                }),
                HorizonActions.loadHorizonDashboardWithMergedRequests({
                  dashboardTypeList: [...dashboardTypes],
                }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * loadHorizonTitanAzureUtilization$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public loadHorizonTitanAzureUtilization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.loadHorizonTitanAzureUtilization),
      withLatestFrom(this.store.select(HorizonSelectors.getHorizonFilterRuleGroup)),
      switchMap(([_action, horizonFilterRuleGroup]: [Action, RuleGroup]) => {
        const suggestionFilterBys: SuggestionFilterBy[] = [];
        horizonFilterRuleGroup.rules?.forEach((filterRule: FilterRule) => {
          if (!filterRule.attribute) {
            return;
          }
          suggestionFilterBys.push(
            new SuggestionFilterBy({
              fieldName: filterRule.attribute,
              values: filterRule.data,
            }),
          );
        });
        const suggestionCriteria = new SuggestionCriteria({
          columnName: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
          query: '',
          size: 50,
          filterBys: suggestionFilterBys,
        });
        return this.integrationMetaService.getFilterSuggestionsV2(suggestionCriteria).pipe(
          switchMap((suggestionSearchResponse: SuggestionSearchResponse) => {
            const convertedFilterRule: FilterRule = new FilterRule({
              attribute: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
              condition: FilterRule.FILTER_CONDITION.includes,
              data: suggestionSearchResponse?.values.map((item) => item.value),
            });
            return [
              HorizonActions.loadHorizonDashboardWithMergedRequests({
                dashboardTypeList: [StandardDashboardType.HORIZON_TITAN_UTILIZATION],
              }),
              HorizonActions.setHorizonFilterRuleGroup({
                filterRules: [convertedFilterRule],
              }),
            ];
          }),
          catchError((error: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_FILTER_FAILURE_MSG', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * updateWidgetDetailDefinitionFilterByUserName$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public updateWidgetDetailDefinitionFilterByUserName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HorizonActions.updateWidgetDetailDefinitionFilterByUserName),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDetailTrend)),
      map(([{ attribute, userName }, trend]: [ReturnType<typeof HorizonActions.updateWidgetDetailDefinitionFilterByUserName>, Trend]) => {
        let newFilter = trend?.trendDefinition?.filter;
        // prettier-ignore
        const reg = new RegExp(`${attribute}\\s*=\\s*\\'([\\w\\W]*)\\'`);
        if (!newFilter || !newFilter.length) {
          newFilter = `${attribute} = '${userName}'`;
        } else if (newFilter.includes(attribute)) {
          newFilter = newFilter.replace(reg, `${attribute} = '${userName}'`);
        } else {
          newFilter = `${newFilter} AND ${attribute} = '${userName}'`;
        }
        const newTrend = new Trend({
          ...trend,
          trendDefinition: new TrendDefinition({
            ...trend.trendDefinition,
            filter: newFilter,
          }),
        });
        return DashboardActions.setWidgetDetailTrend({ trend: newTrend });
      }),
    ),
  );

  /**
   * Creates an instance of HorizonEffects.
   * @param {Actions} actions$
   * @param {IntegrationMetaService} integrationMetaService
   * @param {I18NService} i18nService
   * @param {Store} store
   * @memberof HorizonEffects
   */
  constructor(
    private actions$: Actions,
    private integrationMetaService: IntegrationMetaService,
    public i18nService: I18NService,
    private store: Store,
  ) {}
}
