/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { ServiceAccount } from '@ws1c/intelligence-models';

/**
 * Provides REST Interface for All Service Accounts API's
 *
 * @export
 * @class ServiceAccountService
 */
@Injectable()
export class ServiceAccountService {
  /**
   * Creates an instance of ServiceAccountService.
   * @param {HttpService} httpService
   * @memberof ServiceAccountService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Get list of service accounts
   * @returns {Observable<ServiceAccount[]>}
   * @memberof ServiceAccountService
   */
  public getServiceAccounts(): Observable<ServiceAccount[]> {
    return this.httpService.get(Endpoint.SERVICE_ACCOUNT).pipe(
      map((response: any) => {
        return response.data.map((serviceAccount) => deserialize(ServiceAccount, serviceAccount));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Generate client secret
   * @param {ServiceAccount} serviceAccount
   * @returns {Observable<ServiceAccount>}
   * @memberof ServiceAccountService
   */
  public generateClientSecret(serviceAccount: ServiceAccount): Observable<ServiceAccount> {
    return this.httpService.post(Endpoint.SERVICE_ACCOUNT, serviceAccount).pipe(
      map((response: any) => deserialize(ServiceAccount, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Regenerate client secret
   * @param {string} clientId
   * @returns {Observable<string>}.
   * @memberof ServiceAccountService
   */
  public regenerateClientSecret(clientId: string): Observable<string> {
    return this.httpService.get(Endpoint.SERVICE_ACCOUNTS_REGENERATE(clientId)).pipe(
      map((response) => response.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * delete client secret
   * @param {string} clientId
   * @returns {Observable<ServiceAccount>}
   * @memberof ServiceAccountService
   */
  public deleteServiceAccount(clientId: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.SERVICE_ACCOUNTS_ID(clientId)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
