/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { DataExplorerActions } from '@dpa-shared-merlot/store/data-explorer';
import { AppConfig, RouterExtensions } from '@ws1c/intelligence-common';

/**
 * DataExplorerEffects
 * @export
 * @class DataExplorerEffects
 */
@Injectable()
export class DataExplorerEffects {
  /**
   * setCategory$
   * @memberof DataExplorerEffects
   */
  public setCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataExplorerActions.setCategory),
      tap(({ categoryId }: ReturnType<typeof DataExplorerActions.setCategory>) => {
        if (categoryId) {
          this.routerExt.navigate([], categoryId ? { queryParams: { [AppConfig.QUERY_PARAM_CATEGORY]: categoryId } } : {});
        }
      }),
      switchMap(() => [
        DataExplorerActions.setSelectDataSourceVisible({ isVisible: false }),
        DataExplorerActions.setPreviewQuery({ query: undefined }),
      ]),
    ),
  );

  /**
   * Creates an instance of DataExplorerEffects.
   * @param {Actions} actions$
   * @param {RouterExtensions} routerExt
   */
  constructor(
    private actions$: Actions,
    private routerExt: RouterExtensions,
  ) {}
}
