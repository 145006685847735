/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { helper } from '@dpa-shared-merlot/effects/dashboard/devices-dashboard.effect.helpers';
import { DevicesDashboardActions, DevicesDashboardSelectors, MerlotState } from '@dpa-shared-merlot/store';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { DashboardActions, DashboardService, SolutionSettingMetricService } from '@ws1c/intelligence-core';
import { ReportMetaService } from '@ws1c/intelligence-core/services';
import {
  CustomReportPreviewSearchResponse,
  DashboardThresholdSummary,
  getThresholdsByMetricByEntity,
  ROUTE_NAMES,
  RuleGroup,
  Solution,
  SolutionSettingMetricDetails,
  SolutionSettingMetricKey,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  ThresholdType,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * DevicesDashboardEffects
 * @export
 * @class DevicesDashboardEffects
 */
@Injectable()
export class DevicesDashboardEffects {
  /**
   * loadNetworkSignalDashboardWithMergedRequests$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public loadNetworkSignalDashboardWithMergedRequests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.loadNetworkSignalDashboardWithMergedRequests),
      withLatestFrom(this.store.select(DevicesDashboardSelectors.getNetworkSignalDashboardWithMergedRequests)),
      switchMap(([_action, requests]: [Action, StandardDashboardRequest[]]) => {
        return of(DashboardActions.loadMergedStandardDashboard({ requests, isCrossCategory: true }));
      }),
    ),
  );

  /**
   * unloadNetworkSignalDashboardWithMergedRequests$
   * @type {Observable<Action>}
   * @memberof HorizonEffects
   */
  public unloadNetworkSignalDashboardWithMergedRequests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.unloadNetworkSignalDashboardWithMergedRequests),
      map(() => DashboardActions.clearDrilldownEvents({ widgetId: StandardWidgetSubtype.__DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL })),
    ),
  );

  /**
   * loadDashboard$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DevicesDashboardActions.updateDashboardFilter,
        DevicesDashboardActions.refreshDashboard,
        DevicesDashboardActions.loadDashboard,
      ),
      withLatestFrom(
        this.store.select(DevicesDashboardSelectors.getDashboardType),
        this.store.select(DevicesDashboardSelectors.getAdditionalParams),
        this.store.select(DevicesDashboardSelectors.getFilterRuleGroup),
        this.store.select(DevicesDashboardSelectors.getTrendDateRange),
      ),
      map(
        ([_action, dashboardType, additionalParams, ruleGroup, trendDateRange]: [
          Action,
          StandardDashboardType,
          GenericObject,
          RuleGroup,
          TrendDateRange,
        ]) => {
          if (dashboardType === StandardDashboardType.DEVICES_PERFORMANCE_DESKTOP_BOOT_SHUTDOWN) {
            return DevicesDashboardActions.loadDesktopPerformanceDashboard();
          }
          const request = new StandardDashboardRequest(dashboardType, ruleGroup, trendDateRange, additionalParams);
          return DashboardActions.loadStandardDashboard({ request });
        },
      ),
    ),
  );

  /**
   * loadDesktopPerformanceDashboard$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public loadDesktopPerformanceDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DevicesDashboardActions.loadDesktopPerformanceDashboard,
        DevicesDashboardActions.savePerformanceThresholdSummarySuccess,
        DevicesDashboardActions.loadPerformanceThresholdSummarySuccess,
      ),
      withLatestFrom(
        this.store.select(DevicesDashboardSelectors.getPerformanceThresholdsAdditionalParams),
        this.store.select(DevicesDashboardSelectors.getFilterRuleGroup),
        this.store.select(DevicesDashboardSelectors.getTrendDateRange),
      ),
      filter(([_action, additionalParams]: [Action, GenericObject, RuleGroup, TrendDateRange]) => {
        return Boolean(additionalParams);
      }),
      map(([_action, additionalParams, ruleGroup, trendDateRange]: [Action, GenericObject, RuleGroup, TrendDateRange]) => {
        const request = new StandardDashboardRequest(
          StandardDashboardType.DEVICES_PERFORMANCE_DESKTOP_BOOT_SHUTDOWN,
          ruleGroup,
          trendDateRange,
          additionalParams,
        );
        return DashboardActions.loadStandardDashboard({ request });
      }),
    ),
  );

  /**
   * loadDeviceHealthThresholdsV2$
   * @type {Observable<Action>}
   * @memberof DeemSetupEffects
   */
  public loadDeviceHealthThresholdsV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.loadDeviceHealthThresholdsV2),
      mergeMap(({ entity }: ReturnType<typeof DevicesDashboardActions.loadDeviceHealthThresholdsV2>) => {
        return this.solutionSettingMetricService
          .getSolutionSettingMetrics(new SolutionSettingMetricKey({ solutionType: Solution.DEEM, solutionSetting: entity }))
          .pipe(
            map((response: SolutionSettingMetricDetails[]) => {
              const thresholdsV2Data = getThresholdsByMetricByEntity(response);
              return DevicesDashboardActions.loadDeviceHealthThresholdsV2Success({ data: thresholdsV2Data[entity] });
            }),
          );
      }),
    ),
  );

  /**
   * loadDeviceHealth$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public loadDeviceHealth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.loadDeviceHealth),
      switchMap(({ deviceId }: ReturnType<typeof DevicesDashboardActions.loadDeviceHealth>) => {
        const date = new Date();
        const devicesRequest = helper.loadDeviceHealthRequest(deviceId, moment(date).subtract(1, 'days').valueOf(), moment(date).valueOf());
        return this.reportMetaService.previewReportAny(devicesRequest, true).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return DevicesDashboardActions.loadDeviceHealthSuccess({ deviceId, response });
          }),
          catchError(() => of(DevicesDashboardActions.loadDeviceHealthFailure())),
        );
      }),
    ),
  );

  /**
   * savePerformanceThresholdSummary$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public savePerformanceThresholdSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.savePerformanceThresholdSummary),
      switchMap(({ thresholdSummary }: ReturnType<typeof DevicesDashboardActions.savePerformanceThresholdSummary>) => {
        return this.dashboardService.saveDashboardThresholdSummary(thresholdSummary).pipe(
          switchMap((summary: DashboardThresholdSummary) => {
            return [
              DevicesDashboardActions.savePerformanceThresholdSummarySuccess({ thresholdSummary: summary }),
              // following line to be removed after INTEL-17416
              DevicesDashboardActions.loadPerformanceThresholdSummary(),
            ];
          }),
          catchError((error: WebError) => of(DevicesDashboardActions.savePerformanceThresholdSummaryFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadPerformanceThresholdSummary$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public loadPerformanceThresholdSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDashboardActions.loadPerformanceThresholdSummary),
      switchMap(() => {
        return this.dashboardService.getThresholdData(ThresholdType.DEVICES).pipe(
          map((thresholdSummary: DashboardThresholdSummary) => {
            return DevicesDashboardActions.loadPerformanceThresholdSummarySuccess({ thresholdSummary });
          }),
          catchError((error: WebError) => of(DevicesDashboardActions.loadPerformanceThresholdSummaryFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadDevicesDesktopErrorDetailsDashboard$
   * @type {Observable<Action>}
   * @memberof DevicesDashboardEffects
   */
  public loadDevicesDesktopErrorDetailsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DevicesDashboardActions.loadDevicesDesktopErrorDetailsDashboard,
        DevicesDashboardActions.updateDevicesDesktopErrorDetailsFilters,
      ),
      withLatestFrom(this.store.select(DevicesDashboardSelectors.getDesktopErrorDetailsDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * goToDevicesDesktopErrorDetailsPage$
   * @type {Observable<DevicesDesktopErrorDetailsPage>}
   * @memberof DevicesDashboardEffects
   */
  public goToDevicesDesktopErrorDetailsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDashboardActions.goToDevicesDesktopErrorDetailsPage),
        tap(({ detailsPage }: ReturnType<typeof DevicesDashboardActions.goToDevicesDesktopErrorDetailsPage>) => {
          const devicesDesktopErrorDetailsUrl = this.routerExtensions.getNavigationUrl(
            [
              ROUTE_NAMES.DASHBOARD.DESKTOP_ERROR_DETAILS,
              'platform',
              detailsPage.platform,
              'module',
              detailsPage.module,
              'error',
              detailsPage.error,
              'process',
              detailsPage.process,
              'crashPath',
              detailsPage.crashPath,
            ],
            {
              queryParams: {
                breadCrumbs: JSON.stringify(detailsPage.breadCrumbs || []),
              },
            },
          );
          return this.routerExtensions.navigateByUrl(devicesDesktopErrorDetailsUrl);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of DevicesDashboardEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {ReportMetaService} reportMetaService
   * @param {DashboardService} dashboardService
   * @param {SolutionSettingMetricService} solutionSettingMetricService
   * @param {RouterExtensions} routerExtensions
   * @memberof DevicesDashboardEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private reportMetaService: ReportMetaService,
    private dashboardService: DashboardService,
    private solutionSettingMetricService: SolutionSettingMetricService,
    private routerExtensions: RouterExtensions,
  ) {}
}
