/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, SortOn, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { uniqBy } from 'lodash-es';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { MerlotState, UserProfileActions, UserProfileAdditionalParams, UserProfileSelectors } from '@dpa-shared-merlot/store';
import {
  getUserProfileDetailRequest,
  getUserProfileExperienceAppMultiRequest,
  getUserProfileExperienceMultiRequest,
  getUserProfileMobileDeviceRequest,
} from '@dpa-shared-merlot/store/dashboard/user-profile/user-profile-selector-helpers';
import { RouterExtensions, UserProfileConfig } from '@ws1c/intelligence-common';
import { DashboardActions } from '@ws1c/intelligence-core';
import { ReportMetaService } from '@ws1c/intelligence-core/services';
import {
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DeviceUser,
  Entity,
  Integration,
  PreviewReportContentRequest,
  StandardDashboardRequest,
  StandardDashboardType,
  TrendDateRange,
  UserDetailPageType,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for User Profile Status
 * @export
 * @class UserProfileEffects
 */
@Injectable()
export class UserProfileEffects {
  /**
   * loadUserProfileDashboard$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadUserProfileDashboard),
      withLatestFrom(this.store.select(UserProfileSelectors.getTrendDateRange)),
      switchMap(([{ user }, trendDateRange]: [ReturnType<typeof UserProfileActions.loadUserProfileDashboard>, TrendDateRange]) => {
        const additionalParams = {
          user_guid: user.id,
          user_name: user.userName,
        };
        const requestOverview = new StandardDashboardRequest(
          StandardDashboardType.USER_PROFILE,
          undefined,
          trendDateRange,
          additionalParams,
        );
        return of(DashboardActions.loadStandardDashboard({ request: requestOverview }));
      }),
    ),
  );

  /**
   * loadUserProfileExperienceDashboard$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileExperienceDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadUserProfileExperienceDashboard),
      withLatestFrom(this.store.select(UserProfileSelectors.getTrendDateRange)),
      switchMap(
        ([{ user }, trendDateRange]: [ReturnType<typeof UserProfileActions.loadUserProfileExperienceDashboard>, TrendDateRange]) => {
          const additionalParams = {
            user_guid: user.id,
            user_name: user.userName,
          } as UserProfileAdditionalParams;
          const request = new StandardDashboardRequest(
            StandardDashboardType.USER_PROFILE_EXPERIENCE,
            undefined,
            trendDateRange,
            additionalParams,
          );
          return [
            UserProfileActions.cleanUserDeviceAndApp(),
            DashboardActions.loadStandardDashboard({ request }),
            UserProfileActions.loadUserProfileExperienceTables({ additionalParams, userDetailPageType: UserDetailPageType.USER_PROFILE }),
          ];
        },
      ),
    ),
  );

  /**
   * loadUserProfileExperienceTables$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileExperienceTables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserProfileActions.loadUserProfileExperienceTables,
        UserProfileActions.setDeviceTablePagedRequest,
        UserProfileActions.setDeviceTableSortOns,
      ),
      withLatestFrom(this.store.select(UserProfileSelectors.getUserProfileExperienceDeviceRequest)),
      switchMap(
        ([props, request]: [ReturnType<typeof UserProfileActions.loadUserProfileExperienceTables>, PreviewReportContentRequest[]]) => {
          const requests = getUserProfileExperienceMultiRequest(request, props.additionalParams, props.userDetailPageType);
          return this.reportMetaService.previewReportContentMultiV2(requests).pipe(
            map((responses: CustomReportPreviewSearchResponse[]) =>
              UserProfileActions.loadUserProfileExperienceTablesSuccess({ requests, responses }),
            ),
            catchError((error: WebError) => of(UserProfileActions.loadUserProfileExperienceTablesFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadUserProfileExperienceMobile$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileExperienceMobile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadUserProfileExperienceMobile),
      withLatestFrom(this.store.select(UserProfileSelectors.getUserProfileExperienceMobileDeviceRequest)),
      switchMap(
        ([props, mobileReq]: [ReturnType<typeof UserProfileActions.loadUserProfileExperienceTables>, PreviewReportContentRequest]) => {
          const request = getUserProfileMobileDeviceRequest(mobileReq, props.additionalParams);
          return this.reportMetaService.previewReportAny(request, true).pipe(
            map((response: CustomReportPreviewSearchResponse) =>
              UserProfileActions.loadUserProfileExperienceMobileSuccess({ request, response }),
            ),
            catchError((error: WebError) => of(UserProfileActions.loadUserProfileExperienceMobileFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadUserProfileExperienceApps$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileExperienceApps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserProfileActions.loadUserProfileExperienceApps,
        UserProfileActions.setAppsTablePagedRequest,
        UserProfileActions.setAppsTableSortOns,
      ),
      withLatestFrom(this.store.select(UserProfileSelectors.getUserProfileExperienceAppRequest)),
      switchMap(
        ([props, request]: [ReturnType<typeof UserProfileActions.loadUserProfileExperienceApps>, PreviewReportContentRequest[]]) => {
          const requests = getUserProfileExperienceAppMultiRequest(request, props.response, props.userDetailPageType);
          return this.reportMetaService.previewReportContentMultiV2(requests).pipe(
            map((responses: CustomReportPreviewSearchResponse[]) =>
              UserProfileActions.loadUserProfileExperienceAppsSuccess({ requests, responses }),
            ),
            catchError((error: WebError) => of(UserProfileActions.loadUserProfileExperienceAppsFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadScoreDashboard$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadScoreDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadScoreDashboard),
      switchMap(({ id, userDetailPageType }: ReturnType<typeof UserProfileActions.loadScoreDashboard>) => {
        const additionalParams = {
          device_guid: id,
        };
        const trendDateRange = UserProfileConfig.UserDetailPageConfig[userDetailPageType]?.trendDateRange;
        const request = new StandardDashboardRequest(
          StandardDashboardType.USER_PROFILE_EXPERIENCE_DEVICE_HEALTH,
          undefined,
          trendDateRange,
          additionalParams,
        );
        return [DashboardActions.loadStandardDashboard({ request })];
      }),
    ),
  );

  /**
   * goToUserProfilePage$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public goToUserProfilePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.goToUserProfilePage),
        switchMap(({ user, userDetailPageType }: ReturnType<typeof UserProfileActions.goToUserProfilePage>) => {
          let detailPageUrl: string;
          if (!(user.email && user.firstName && user.lastName)) {
            const request = getUserProfileDetailRequest(user);
            return this.reportMetaService.previewReportV2(request).pipe(
              switchMap((response: CustomReportPreviewSearchResponse) => {
                user = {
                  ...user,
                  email: response.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_email],
                  firstName: response.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_first_name],
                  lastName: response.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_last_name],
                  lastActive: response.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_user_adp_modified_at],
                };
                detailPageUrl = this.getUserDetailPageUrl(user, userDetailPageType);
                return of({ detailPageUrl });
              }),
              catchError((error: WebError) => of(UserProfileActions.getUserProfileDetailRequestFailure({ error }))),
            );
          }
          detailPageUrl = this.getUserDetailPageUrl(user, userDetailPageType);
          return of({ detailPageUrl });
        }),
        tap(({ detailPageUrl }: { detailPageUrl: string }) => {
          return this.routerExtensions.navigateByUrl(detailPageUrl);
        }),
      ),
    { dispatch: false },
  );

  /**
   * loadUserProfileSecurityDashboard$
   * @type {Observable<Action>}
   * @memberof UserProfileEffects
   */
  public loadUserProfileSecurityDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadUserProfileSecurityDashboard),
      withLatestFrom(this.store.select(UserProfileSelectors.getTrendDateRange)),
      switchMap(([{ user }, trendDateRange]: [ReturnType<typeof UserProfileActions.loadUserProfileSecurityDashboard>, TrendDateRange]) => {
        const date = new Date();
        const deviceRequest = new PreviewReportContentRequest({
          filter: `${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_user_guid} = '${user.id}'`,
          fields: [
            COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid,
            COLUMN_NAMES.byFullyQualifiedName.airwatch_device_adp_modified_at,
          ],
          entitiesByIntegration: {
            [Integration.AIRWATCH]: [Entity.DEVICE],
          },
          offset: 0,
          pageSize: 100,
          sortOns: [
            new SortOn({
              by: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_adp_modified_at,
              reverse: true,
            }),
          ],
          startDateMillis: moment(date).subtract(28, 'days').valueOf(),
          endDateMillis: moment(date).valueOf(),
        });
        return this.reportMetaService.previewReportV2(deviceRequest).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const deviceList = uniqBy(response.results, COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid).map(
              (result: GenericObject) => result[COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid],
            );
            const additionalParams = {
              user_guid: user.id,
              user_name: user.userName,
              device_guids: `'${deviceList.join("','")}'`,
            } as UserProfileAdditionalParams;
            const request = new StandardDashboardRequest(
              StandardDashboardType.USER_PROFILE_SECURITY,
              undefined,
              trendDateRange,
              additionalParams,
            );
            return DashboardActions.loadStandardDashboard({ request });
          }),
          catchError((error: WebError) => of(UserProfileActions.getUserProfileDetailRequestFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Creates an instance of UserProfileEffects.
   * @param {Actions} actions$
   * @param {ReportMetaService} reportMetaService
   * @param {RouterExtensions} routerExtensions
   * @param {Store<MerlotState>} store
   * @memberof UserProfileEffects
   */
  constructor(
    private actions$: Actions,
    private reportMetaService: ReportMetaService,
    private routerExtensions: RouterExtensions,
    private store: Store<MerlotState>,
  ) {}

  /**
   * getUserDetailPageUrl
   * @param {DeviceUser} user
   * @param {UserDetailPageType} [userDetailPageType=UserDetailPageType.USER_PROFILE]
   * @param {string} [returnUrl='']
   * @returns {string}
   * @memberof UserProfileEffects
   */
  public getUserDetailPageUrl = (
    user: DeviceUser,
    userDetailPageType: UserDetailPageType = UserDetailPageType.USER_PROFILE,
    returnUrl: string = '',
  ): string => {
    const path: string = UserProfileConfig.UserDetailPageConfig[userDetailPageType]?.path;
    const tab = UserProfileConfig.UserDetailPageConfig[userDetailPageType]?.tab;
    const id = user.id;
    const queryParams = {
      ...user,
      returnUrl,
    };
    return this.routerExtensions.getNavigationUrl(tab ? [path, id, tab] : [path, id], { queryParams });
  };
}
