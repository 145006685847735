/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig, Endpoint, HttpService } from '@ws1c/intelligence-common';
import { AuthService } from '@ws1c/intelligence-core';
import { IntelOrg } from '@ws1c/intelligence-models';

/**
 * UserService
 *
 * @export
 * @class UserService
 */
@Injectable()
export class UserService {
  /**
   * Creates an instance of UserService.
   * @param {HttpService} http
   * @param {AuthService} auth
   * @param {Document} document
   * @memberof UserService
   */
  constructor(
    private http: HttpService,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * invokes session PING
   *
   * @returns {Observable<boolean>}
   * @memberof UserService
   */
  public renewSession(): Observable<boolean> {
    return this.http.get(Endpoint.SESSION_PING).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * invokes ajax LOGOUT
   * @returns {Observable<string>}
   * @memberof UserService
   */
  public silentLogout(): Observable<boolean> {
    const params = new URLSearchParams();
    params.set('redirect', 'false');
    return this.http
      .post(Endpoint.CONSOLE_LOGOUT, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * logout
   * @memberof UserService
   */
  public logout() {
    localStorage.removeItem(AppConfig.ORG_NAME);
    const form = this.document?.createElement('form');
    if (!form) {
      throw new Error('Failed creating logout form.');
    }
    form.method = 'POST';
    form.action = this.http.getFullUrl(Endpoint.CONSOLE_LOGOUT);
    const field = this.document.createElement('input');
    if (field) {
      field.type = 'hidden';
      field.name = AppConfig.XSRF_FIELD_NAME;
      field.value = this.auth.getXSRFFToken();
      form.appendChild(field);
    }
    this.document.body.appendChild(form);
    form.submit();
  }

  /**
   * changeOrg
   * @param {IntelOrg} org
   * @param {string} [originPath]
   * @memberof UserService
   */
  public changeOrg(org: IntelOrg, originPath?: string) {
    const form = this.document?.createElement('form');
    if (!form) {
      throw new Error('Failed creating org switcher form.');
    }
    form.method = 'GET';
    form.action = this.http.getFullUrl(Endpoint.DISCOVERY_LOGIN(org.userName));
    const orgIdField = this.document.createElement('input');
    orgIdField.type = 'hidden';
    orgIdField.name = AppConfig.ORG_ID_FIELD_NAME;
    orgIdField.value = org.id;
    form.appendChild(orgIdField);
    if (originPath) {
      const originPathField = this.document.createElement('input');
      originPathField.type = 'hidden';
      originPathField.name = AppConfig.ORIGIN_PATH_FIELD_NAME;
      originPathField.value = encodeURI(originPath);
      form.appendChild(originPathField);
    }
    this.document.body.appendChild(form);
    form.submit();
  }
}
