/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { IntegrationActions, UserRiskDashboardActions, UserRiskDashboardSelectors } from '@dpa-shared-merlot/store';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core';
import { ReportMetaService } from '@ws1c/intelligence-core/services';
import {
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  Entity,
  getFQNFunction,
  Integration,
  PreviewReportContentRequest,
  RiskyUserDeviceSummaryLocator,
  SettingsFilterTag,
  StandardDashboardRequest,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * UserRiskDashboardEffects
 * @export
 * @class UserRiskDashboardEffects
 */
@Injectable()
export class UserRiskDashboardEffects {
  /**
   * loadDashboard$
   * @type {Observable<Action>}
   * @memberOf UserRiskDashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRiskDashboardActions.loadDashboard, UserRiskDashboardActions.setDashboardFilters),
      withLatestFrom(this.store.select(UserRiskDashboardSelectors.getStandardDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * loadRiskyUserDeviceSummary$
   * @type {Observable<Action>}
   * @memberOf UserRiskDashboardEffects
   */
  public loadRiskyUserDeviceSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRiskDashboardActions.loadRiskyUserDeviceSummary),
      withLatestFrom(this.store.select(DashboardSelectors.getResolvedWidgetDetailTrendDateRange)),
      switchMap(([{ email, timestamp }, trendDateRange]: [RiskyUserDeviceSummaryLocator, TrendDateRange]) => {
        const getFQN = getFQNFunction(Integration.AIRWATCH, Entity.DEVICE_SUMMARY_RISK_SCORE);
        const request = new PreviewReportContentRequest({
          filter: [
            `${getFQN(COLUMN_NAMES.byName.device_enrollment_user_email)} = '${email}'`,
            `AND ${getFQN(COLUMN_NAMES.byName.score_calculated_at)} = ${timestamp}`,
          ].join(' '),
          fields: [
            getFQN(COLUMN_NAMES.byName.device_guid),
            getFQN(COLUMN_NAMES.byName.device_udid),
            getFQN(COLUMN_NAMES.byName.device_friendly_name),
            getFQN(COLUMN_NAMES.byName.score_severity),
            getFQN(COLUMN_NAMES.byName.risk_indicators),
            getFQN(COLUMN_NAMES.byName.risk_indicator_names),
            getFQN(COLUMN_NAMES.byName._device_platform),
          ],
          entitiesByIntegration: {
            airwatch: [Entity.DEVICE_SUMMARY_RISK_SCORE],
          },
          startDateMillis: trendDateRange.startDateMillis,
          endDateMillis: trendDateRange.endDateMillis,
          offset: 0,
          pageSize: 10,
          sortOns: [],
        });
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) =>
            UserRiskDashboardActions.loadRiskyUserDeviceSummarySuccess({
              email,
              timestamp,
              results: response.results,
            }),
          ),
          catchError(() =>
            of(
              UserRiskDashboardActions.loadRiskyUserDeviceSummaryFailure({
                email,
                timestamp,
                results: [],
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * goToIDMSetup$
   * @type {Observable<Action>}
   * @memberof UserRiskDashboardEffects
   */
  public goToIDMSetup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRiskDashboardActions.goToIDMSetup),
        exhaustMap(() => {
          this.routerExtensions.navigate(['/integrations'], {
            queryParams: {
              filter: SettingsFilterTag[SettingsFilterTag.INTEGRATION],
            },
          });
          return [
            IntegrationActions.setCurrentIntegration({ integration: Integration.IDM }),
            IntegrationActions.showSetupModal({ showSetupModal: true }),
          ];
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of UserRiskDashboardEffects.
   * @param {Actions} actions$
   * @param {Store} store
   * @param {ReportMetaService} reportMetaService
   * @param {RouterExtensions} routerExtensions
   * @memberof UserRiskDashboardEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store,
    private reportMetaService: ReportMetaService,
    private routerExtensions: RouterExtensions,
  ) {}
}
