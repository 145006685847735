/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { IntegrationService } from '@dpa-shared-merlot/services';
import { IntegrationActions, IntegrationSelectors, MerlotState } from '@dpa-shared-merlot/store';
import { AppConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import {
  AlertBannerActions,
  OrgActions,
  OrgSelectors,
  UserPreferenceActions,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  DATA_SOURCES_CARD_TYPE,
  GenericSearchRequest,
  Integration,
  INTEGRATION_TYPE_TO_LABEL_MAP,
  IntegrationActionsSearchResponse,
  IntegrationDetails,
  IntegrationDetailView,
  IntegrationHealthReport,
  IntegrationObjectsCounts,
  IntegrationObjectsSearchResponse,
  IntegrationObjectsType,
  IntegrationRegistrationSource,
  IntegrationReprovisionStatus,
  IntegrationReprovisionStatusType,
  LOAD_STATE,
  OrgPreference,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';
import { TenantUrlConfigurationActions } from '@ws1c/intelligence-onboarding/store';

/**
 * Handles side effects for integration UI
 * @export
 * @class IntegrationEffects
 */
@Injectable()
export class IntegrationEffects {
  /**
   * updateAuthorizedState$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public updateAuthorizedState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.updateAuthorizedState),
      withLatestFrom(this.store.select(UserPreferenceSelectors.getActiveIntegrationInstances)),
      switchMap(
        ([{ state, integrationResultCode, errorMessage }, activeInstances]: [
          ReturnType<typeof IntegrationActions.updateAuthorizedState>,
          any,
        ]) => {
          const actions: Action[] = [
            // Clear initialUrlQueryParams from the store after redirected to settings page
            // to avoid the loop when effects keep checking for those params on route change event
            // See https://jira-euc.eng.vmware.com/jira/browse/INTEL-10913
            UserPreferenceActions.setInitialUrlQueryParams({ initialUrlQueryParams: {} }),
          ];
          if (activeInstances && state === true) {
            actions.push(IntegrationActions.authorizeSuccess());
          } else {
            actions.push(
              IntegrationActions.authorizeFailure({
                error: undefined,
                integrationResultCode,
                errorMessage,
              }),
            );
          }
          return actions;
        },
      ),
    ),
  );

  /**
   * downloadCredential$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public downloadCredential$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.downloadCredential),
      switchMap(({ integration }: ReturnType<typeof IntegrationActions.downloadCredential>) => {
        return this.integrationService.downloadCredential(integration).pipe(
          mergeMap((response: GenericObject) => [
            UserPreferenceActions.insertActiveInstances({
              integration,
              details: {
                integration,
                active: true,
                source_system_id: response.oauth_client.source_system_id,
                modified_at: new Date().toLocaleString(),
              },
            }),
            integration === Integration.UAG
              ? IntegrationActions.showUagAuthConfirmModal({
                  showUagAuthConfirmModal: false,
                })
              : IntegrationActions.showItsmAuthConfirmModal({
                  showItsmAuthConfirmModal: false,
                }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DOWNLOAD_CREDENTIAL_SUCCESS_MESSAGE`),
            }),
          ]),
          catchError((error: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              autoDismiss: true,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DOWNLOAD_CREDENTIAL_FAILURE_MESSAGE`, {
                error,
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * deactivateCredential$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public deactivateCredential$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.deactivateCredential),
      withLatestFrom(this.store.select(UserPreferenceSelectors.getActiveIntegrationInstances), this.routerExtensions.url$),
      switchMap(
        ([{ integration }, activeInstances, navigationUrl]: [ReturnType<typeof IntegrationActions.deactivateCredential>, any, string]) => {
          const activeInstance = (activeInstances[integration] || []).find((instance) => instance.active);
          const actions: Action[] = [
            UserPreferenceActions.removeActiveInstances({ integration }),
            integration === Integration.UAG
              ? IntegrationActions.showUagDeauthConfirmModal({
                  showUagDeauthConfirmModal: false,
                })
              : IntegrationActions.showItsmDeauthConfirmModal({
                  showItsmDeauthConfirmModal: false,
                }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DEAUTHORIZE_SUCCESS_MESSAGE`),
            }),
          ];
          if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
            actions.push(IntegrationActions.deauthorizeFromIntegrationViewSuccess());
          }
          return this.integrationService.deactivateCredential(integration, activeInstance.source_system_id).pipe(
            mergeMap(() => actions),
            catchError((error: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                autoDismiss: true,
                message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DEAUTHORIZE_FAILURE_MESSAGE`, { error }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * reactivateCredential$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public reactivateCredential$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.reactivateCredential),
      switchMap(({ integration, sourceSystemId }: ReturnType<typeof IntegrationActions.reactivateCredential>) => {
        return this.integrationService.reactivateCredential(integration, sourceSystemId).pipe(
          mergeMap(() => [
            UserPreferenceActions.insertActiveInstances({
              integration,
              details: {
                integration,
                active: true,
                source_system_id: sourceSystemId,
                modified_at: new Date().toLocaleString(),
              },
            }),
            integration === Integration.UAG
              ? IntegrationActions.showUagAuthConfirmModal({
                  showUagAuthConfirmModal: false,
                })
              : IntegrationActions.showItsmAuthConfirmModal({
                  showItsmAuthConfirmModal: false,
                }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DOWNLOAD_CREDENTIAL_SUCCESS_MESSAGE`),
            }),
          ]),
          catchError((error: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              autoDismiss: true,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DOWNLOAD_CREDENTIAL_FAILURE_MESSAGE`, {
                error,
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * authorizeSuccess$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public authorizeSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.authorizeSuccess),
      map(() =>
        IntegrationActions.finishAuthConfirmModalSuccess({
          finishAuthConfirmModalSuccess: true,
        }),
      ),
    ),
  );

  /**
   * authorizeFailure$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public authorizeFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.authorizeFailure),
      map(() =>
        IntegrationActions.finishAuthConfirmModalFailure({
          finishAuthConfirmModalFailure: true,
        }),
      ),
    ),
  );

  /**
   * deAuthorize$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public deAuthorize$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.deAuthorize),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.isSilentOptInAllowed),
        this.store.select(UserPreferenceSelectors.isHubSilentOptInAllowed),
        this.store.select(IntegrationSelectors.integrationDetailsToBeDeauthorizedSelector),
        this.routerExtensions.url$,
      ),
      switchMap(
        ([_action, isSilentOptInAllowed, isHubSilentOptInAllowed, integrationDetails, navigationUrl]: [
          Action,
          boolean,
          boolean,
          IntegrationDetails,
          string,
        ]) => {
          if (!integrationDetails) {
            return;
          }
          const integration: string = integrationDetails.integration;
          if (
            ((isSilentOptInAllowed && AppConfig.GEM_INTEGRATIONS_SUPPORTED_FOR_OPT_IN.includes(integration)) ||
              (isHubSilentOptInAllowed && integration === Integration.HUB)) &&
            integrationDetails.registrationSource === IntegrationRegistrationSource.GEM
          ) {
            return of(IntegrationActions.optOutOfIntegration({ integrationDetails }));
          }

          const actions: Action[] = [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DEAUTHORIZE_SUCCESS_MESSAGE`),
            }),
            IntegrationActions.deAuthorizeSuccess(),
            UserPreferenceActions.deactivateIntegrationInstance({ integrationDetails }),
          ];
          if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
            actions.push(IntegrationActions.deauthorizeFromIntegrationViewSuccess());
          }
          return this.integrationService.deAuthorizeById(integrationDetails.id).pipe(
            mergeMap(() => actions),
            catchError((error: WebError) => [
              IntegrationActions.deAuthorizeFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.APP,
                autoDismiss: true,
                message: this.i18nService.translate(`INTEGRATIONS.${integration.toUpperCase()}.DEAUTHORIZE_FAILURE_MESSAGE`, { error }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * deauthorizeFromIntegrationViewSuccess$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public deauthorizeFromIntegrationViewSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.deauthorizeFromIntegrationViewSuccess),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`])),
      ),
    { dispatch: false },
  );

  /**
   * handleIntegrationRegistrationRedirect$
   * @type {Observable<any>}
   * @memberof IntegrationEffects
   */
  public handleIntegrationRegistrationRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        startWith(IntegrationActions.handleRedirectfromSetupFlow()),
        withLatestFrom(
          this.store.select(UserPreferenceSelectors.isFromRegistrationFlow),
          this.store.select(UserPreferenceSelectors.getInitialUrlQueryParams),
        ),
        filter(([_action, isFromRegistrationFlow, initialUrlQueryParams]: [Action, boolean, GenericObject]) => {
          return isFromRegistrationFlow && !!initialUrlQueryParams;
        }),
        take(1),
        tap(([_action, _isFromRegistrationFlow, initialUrlQueryParams]: [Action, boolean, GenericObject]) => {
          // Pickup integration from initialUrlQueryParams
          // If it's not available then it must be old IDM registration flow so we can just hardcode it
          const integration: string = initialUrlQueryParams.integration || Integration.IDM;
          const queryParams: GenericObject = {
            integration,
            registration_success:
              initialUrlQueryParams.registration_success ||
              initialUrlQueryParams.idm_registration_success ||
              initialUrlQueryParams.setup_success ||
              initialUrlQueryParams.callback_success ||
              initialUrlQueryParams.callback_status === 'success',
            error_message: initialUrlQueryParams.partner_error_description || undefined,
          };
          const targetUrl =
            integration === Integration.AZURE
              ? ROUTE_NAMES.SETTINGS.ADMIN_USER_IDENTITY_MANAGEMENT
              : ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS;
          this.routerExtensions.navigate([`/${targetUrl}`], { queryParams });
        }),
      ),
    { dispatch: false },
  );

  /**
   * getIntegrationReprovisioningStatus$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public getIntegrationReprovisioningStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.getIntegrationReprovisioningStatus),
      startWith(IntegrationActions.getIntegrationReprovisioningStatus),
      switchMap(() =>
        combineLatest([
          this.store.select(UserPreferenceFeatureControlsSelectors.isWorkspaceOneCloudEnabled),
          this.store.select(UserPreferenceFeatureControlsSelectors.isRenameAccessTenantEnabled),
        ]),
      ),
      filter(([isWorkspaceOneCloudEnabled, isRenameAccessTenantEnabled]: [boolean, boolean]) => {
        return isWorkspaceOneCloudEnabled && isRenameAccessTenantEnabled;
      }),
      take(1),
      switchMap(() => {
        return this.integrationService.getAccessIntegrationReprovisioningStatus().pipe(
          map((accessIntegrationReprovisioningStatusList: IntegrationReprovisionStatus[]) => {
            return IntegrationActions.setIntegrationReprovisioningStatus({
              accessIntegrationReprovisioningStatusList,
            });
          }),
          catchError(() => [IntegrationActions.setIntegrationReprovisioningStatus({ accessIntegrationReprovisioningStatusList: [] })]),
        );
      }),
    ),
  );

  /**
   * updateIntegrationReprovisioningStatus$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public updateIntegrationReprovisioningStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.getTenantUrlUpdateStatusSuccess),
      map((action: ReturnType<typeof TenantUrlConfigurationActions.getTenantUrlUpdateStatusSuccess>) => {
        return IntegrationActions.updateIntegrationReprovisioningStatus({
          integrationReprovisionStatus: new IntegrationReprovisionStatus({
            ...action.tenantUrlUpdateStatus,
            status: IntegrationReprovisionStatusType[action.tenantUrlUpdateStatus.status],
          }),
        });
      }),
    ),
  );

  /**
   * optIntoIntegration$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public optIntoIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.optIntoIntegration),
      withLatestFrom(
        this.store.select(OrgSelectors.isServiceOptInModalOpen),
        this.store.select(OrgSelectors.isServiceManualOptInPerformed),
      ),
      mergeMap(
        ([{ integrationDetails }, isServiceOptInModalOpen, isServiceManualOptInPerformed]: [
          ReturnType<typeof IntegrationActions.optIntoIntegration>,
          boolean,
          boolean,
        ]) => {
          return this.integrationService.optIntoIntegrationById(integrationDetails.id).pipe(
            mergeMap(() => {
              const actions: Action[] = [IntegrationActions.optIntoIntegrationSuccess({ id: integrationDetails.id })];
              if (isServiceOptInModalOpen) {
                actions.push(IntegrationActions.optIntoIntegrationComplete());
              } else {
                actions.push(
                  IntegrationActions.authorizeSuccess(),
                  UserPreferenceActions.activateIntegrationInstance({ integrationDetails }),
                );
                if (!isServiceManualOptInPerformed) {
                  actions.push(
                    OrgActions.updateOrgSettings({
                      orgPreferences: {
                        [OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED]: true,
                      },
                    }),
                  );
                }
              }
              return actions;
            }),
            catchError((error: WebError) => [
              IntegrationActions.optIntoIntegrationFailure({ id: integrationDetails.id }),
              isServiceOptInModalOpen ? IntegrationActions.optIntoIntegrationComplete() : IntegrationActions.authorizeFailure({ error }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * optIntoIntegrationComplete$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public optIntoIntegrationComplete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.optIntoIntegrationComplete),
      withLatestFrom(
        this.store.select(IntegrationSelectors.integrationListByOptInStatusSelector(LOAD_STATE.SUCCESS)),
        this.store.select(IntegrationSelectors.integrationListByOptInStatusSelector(LOAD_STATE.FAILURE)),
        this.store.select(IntegrationSelectors.areIntegrationsOptInCompleteSelector),
        this.store.select(OrgSelectors.isServiceManualOptInPerformed),
      ),
      filter(
        ([
          _action,
          _integrationListByOptInSuccessStatus,
          _integrationListByOptInFailureStatus,
          areIntegrationsOptInComplete,
          _isServiceManualOptInPerformed,
        ]: [Action, IntegrationDetails[], IntegrationDetails[], boolean, boolean]) => {
          return areIntegrationsOptInComplete;
        },
      ),
      map(
        ([
          _action,
          integrationListByOptInSuccessStatus,
          integrationListByOptInFailureStatus,
          _areIntegrationsOptInComplete,
          isServiceManualOptInPerformed,
        ]: [Action, IntegrationDetails[], IntegrationDetails[], boolean, boolean]) => {
          if (!integrationListByOptInSuccessStatus?.length) {
            return AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('INTRO_PAGE.ALL_INTEGRATIONS_OPT_IN_FAILURE_MESSAGE', {
                serviceNames: this.getServiceNames(integrationListByOptInFailureStatus).join(', '),
              }),
            });
          } else if (!isServiceManualOptInPerformed) {
            return OrgActions.updateOrgSettings({
              orgPreferences: {
                [OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED]: true,
              },
            });
          }
        },
      ),
    ),
  );

  /**
   * optIntoIntegrations$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public optIntoIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.optIntoIntegrations),
      mergeMap((action: ReturnType<typeof IntegrationActions.optIntoIntegrations>) => {
        return action.integrationDetailsList.map((integrationDetails: IntegrationDetails) => {
          return IntegrationActions.optIntoIntegration({ integrationDetails });
        });
      }),
    ),
  );

  /**
   * optIntoIntegrationsComplete$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public optIntoIntegrationsComplete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.optIntoIntegrationsComplete),
      withLatestFrom(
        this.store.select(IntegrationSelectors.integrationListByOptInStatusSelector(LOAD_STATE.SUCCESS)),
        this.store.select(IntegrationSelectors.integrationListByOptInStatusSelector(LOAD_STATE.FAILURE)),
      ),
      mergeMap(
        ([_action, integrationListByOptInSuccessStatus, integrationListByOptInFailureStatus]: [
          Action,
          IntegrationDetails[],
          IntegrationDetails[],
        ]) => {
          const actions: Action[] = [];
          const activateIntegrationInstanceActions: Action[] = integrationListByOptInSuccessStatus?.map(
            (integrationDetails: IntegrationDetails) => {
              return UserPreferenceActions.activateIntegrationInstance({ integrationDetails });
            },
          );
          actions.push(...activateIntegrationInstanceActions);
          const integrationDetailsList: IntegrationDetails[] = integrationListByOptInFailureStatus?.length
            ? integrationListByOptInFailureStatus
            : integrationListByOptInSuccessStatus;
          actions.push(
            AlertBannerActions.showAlertBanner({
              alertType: integrationListByOptInFailureStatus?.length ? ALERT_BANNER_TYPE.DANGER : ALERT_BANNER_TYPE.SUCCESS,
              autoDismiss: false,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate(
                integrationListByOptInFailureStatus?.length
                  ? 'INTRO_PAGE.INTEGRATIONS_OPT_IN_FAILURE_MESSAGE'
                  : 'INTRO_PAGE.INTEGRATIONS_OPT_IN_SUCCESS_MESSAGE',
                {
                  serviceNames: this.getServiceNames(integrationDetailsList).join(', '),
                  route: `/#/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`,
                },
              ),
            }),
          );
          return actions;
        },
      ),
    ),
  );

  /**
   * optOutOfIntegration$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public optOutOfIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.optOutOfIntegration),
      mergeMap(({ integrationDetails }: ReturnType<typeof IntegrationActions.optOutOfIntegration>) => {
        return this.integrationService.optOutOfIntegrationById(integrationDetails.id).pipe(
          mergeMap(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate(
                'INTEGRATIONS.' + integrationDetails.integration.toUpperCase() + '.DEAUTHORIZE_SUCCESS_MESSAGE',
              ),
            }),
            IntegrationActions.deAuthorizeSuccess(),
            UserPreferenceActions.deactivateIntegrationInstance({ integrationDetails }),
          ]),
          catchError((error: WebError) => [
            IntegrationActions.deAuthorizeFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.APP,
              autoDismiss: true,
              message: this.i18nService.translate(
                'INTEGRATIONS.' + integrationDetails.integration.toUpperCase() + '.DEAUTHORIZE_FAILURE_MESSAGE',
                { error },
              ),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadIntegrationHealthStatus$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public loadIntegrationHealthStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.loadIntegrationHealthStatus, IntegrationActions.initializeIntegrationView),
      withLatestFrom(this.store.select(IntegrationSelectors.getIntegrationDetailViewModel)),
      exhaustMap(([_action, integrationDetailView]: [Action, IntegrationDetailView]) => {
        let serviceCall: Observable<IntegrationHealthReport>;
        if (integrationDetailView.hasTenantForGemIntegration) {
          serviceCall = this.integrationService.getGemIntegrationTenantHealthStatus(integrationDetailView);
        } else if (integrationDetailView.tenantId) {
          serviceCall = this.integrationService.getIntegrationTenantHealthStatus(integrationDetailView);
        } else {
          serviceCall = this.integrationService.getIntegrationHealthStatus(integrationDetailView);
        }
        return serviceCall.pipe(
          map((integrationHealthReport: IntegrationHealthReport) => {
            return IntegrationActions.loadIntegrationHealthStatusSuccess({
              integration: integrationDetailView.integration,
              integrationHealthReport,
            });
          }),
        );
      }),
      catchError((error: WebError) => [
        IntegrationActions.loadIntegrationHealthStatusFailure(),
        AlertBannerActions.showAlertBanner({
          alertType: ALERT_BANNER_TYPE.DANGER,
          target: AlertBannerTarget.PAGE,
          autoDismiss: true,
          message: this.i18nService.translate('INTEGRATIONS.COMMON.INTEGRATION_HEALTH_FAILURE', {
            reason: error.getFullReason(),
          }),
        }),
      ]),
    ),
  );

  /**
   * performManualOptInOfServices$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public performManualOptInOfServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.performManualOptInOfServices),
      withLatestFrom(this.store.select(UserPreferenceSelectors.integrationsToOptInList)),
      map(([_action, integrationDetailsList]: [Action, IntegrationDetails[]]) => {
        return IntegrationActions.optIntoIntegrations({ integrationDetailsList });
      }),
    ),
  );

  /**
   * performManualOptInOfServicesComplete$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public performManualOptInOfServicesComplete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.performManualOptInOfServicesComplete),
      map(() => IntegrationActions.optIntoIntegrationsComplete()),
    ),
  );

  /**
   * Show the copy success message when copy json status is done
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public copyIntegrationHealthStatusSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.copyIntegrationHealthStatusSuccess),
      switchMap(() => {
        return of(
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.SUCCESS,
            message: this.i18nService.translate('COMMON_MESSAGES.COPIED_TO_CLIPBOARD'),
          }),
        );
      }),
    ),
  );

  /**
   * Show the copy failure message when copy json status is failed
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public copyIntegrationHealthStatusFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.copyIntegrationHealthStatusFailure),
      switchMap(() => {
        return of(
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.DANGER,
            message: this.i18nService.translate('COMMON_MESSAGES.FAILED_TO_COPY_TO_CLIPBOARD'),
          }),
        );
      }),
    ),
  );

  /**
   * updateIntegrationTenantAuthorization$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public updateIntegrationTenantAuthorization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.updateIntegrationTenantAuthorization),
      switchMap((action: ReturnType<typeof IntegrationActions.updateIntegrationTenantAuthorization>) => {
        return [
          IntegrationActions.setActiveIntegrationDetails({ integrationDetails: action.integrationDetails }),
          action.isAuthorizingIntegrationTenant
            ? IntegrationActions.showSetupModal({
                showSetupModal: true,
              })
            : IntegrationActions.showDeauthConfirmModal({
                showDeauthConfirmModal: true,
              }),
        ];
      }),
    ),
  );

  /**
   * viewIntegrationDetails$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public viewIntegrationDetails$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.viewIntegrationDetails),
        tap((action: ReturnType<typeof IntegrationActions.viewIntegrationDetails>) => {
          const queryParams: Params = { ...action.integrationDetailView };
          const dataSourceCardType: DATA_SOURCES_CARD_TYPE = action?.dataSourceCardType;
          if (dataSourceCardType) {
            queryParams.dataSourceCardType = dataSourceCardType;
          }
          this.routerExtensions.navigate([`/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW}`], {
            queryParams,
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * loadIntegrationObjectsCounts$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public loadIntegrationObjectsCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.loadIntegrationObjectsCounts),
      withLatestFrom(this.store.select(IntegrationSelectors.getIntegrationDetailViewModel)),
      exhaustMap(([_action, integrationDetailView]: [Action, IntegrationDetailView]) => {
        const integration: string = integrationDetailView.integration;
        return this.integrationService
          .getIntegrationObjectsCounts(integration)
          .pipe(
            map((objectsCounts: IntegrationObjectsCounts) => IntegrationActions.loadIntegrationObjectsCountsSuccess({ objectsCounts })),
          );
      }),
      catchError((error: WebError) => [
        IntegrationActions.loadIntegrationObjectsCountsFailure(),
        AlertBannerActions.showAlertBanner({
          alertType: ALERT_BANNER_TYPE.DANGER,
          target: AlertBannerTarget.PAGE,
          autoDismiss: true,
          message: this.i18nService.translate('INTEGRATIONS.COMMON.INTEGRATION_OBJECTS_COUNTS_FAILURE', {
            reason: error.getFullReason(),
          }),
        }),
      ]),
    ),
  );

  /**
   * loadIntegrationObjects$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public loadIntegrationObjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.loadIntegrationObjects),
      withLatestFrom(this.store.select(IntegrationSelectors.getIntegrationDetailViewModel)),
      exhaustMap(
        ([{ selectedObjectType, objectsSearchRequest }, integrationDetailView]: [
          ReturnType<typeof IntegrationActions.loadIntegrationObjects>,
          IntegrationDetailView,
        ]) => {
          const integration: string = integrationDetailView.integration;
          return this.integrationService
            .getIntegrationObjects(integration, selectedObjectType, objectsSearchRequest)
            .pipe(
              map((objectsSearchResponse: IntegrationObjectsSearchResponse) =>
                IntegrationActions.loadIntegrationObjectsSuccess({ objectsSearchResponse }),
              ),
            );
        },
      ),
      catchError((error: WebError) => [
        IntegrationActions.loadIntegrationObjectsFailure(),
        AlertBannerActions.showAlertBanner({
          alertType: ALERT_BANNER_TYPE.DANGER,
          target: AlertBannerTarget.PAGE,
          autoDismiss: true,
          message: this.i18nService.translate('INTEGRATIONS.COMMON.INTEGRATION_OBJECTS_FAILURE', {
            reason: error.getFullReason(),
          }),
        }),
      ]),
    ),
  );

  /**
   * updateIntegrationObjects$
   * Trigger {loadIntegrationObjects} effect when UI request paging or refresh action
   * No change on curent request params.
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public updateIntegrationObjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.changeIntegrationObjectsPagination, IntegrationActions.refreshIntegrationObjects),
      withLatestFrom(
        this.store.select(IntegrationSelectors.getIntegrationselectedObjectType),
        this.store.select(IntegrationSelectors.getIntegrationObjectsSearchRequest),
      ),
      map(([_action, integrationObjectsType, integrationObjectsSearchRequest]: [Action, IntegrationObjectsType, GenericSearchRequest]) =>
        IntegrationActions.loadIntegrationObjects({
          selectedObjectType: integrationObjectsType,
          objectsSearchRequest: integrationObjectsSearchRequest,
        }),
      ),
    ),
  );

  /**
   * loadIntegrationActions$
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public loadIntegrationActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.loadIntegrationActions),
      withLatestFrom(this.store.select(IntegrationSelectors.getIntegrationDetailViewModel)),
      switchMap(
        ([{ searchRequest }, integrationDetailView]: [
          ReturnType<typeof IntegrationActions.loadIntegrationActions>,
          IntegrationDetailView,
        ]) => {
          const integration: string = integrationDetailView.integration;
          return this.integrationService
            .getIntegrationActions(integration, searchRequest)
            .pipe(
              map((searchResponse: IntegrationActionsSearchResponse) =>
                IntegrationActions.loadIntegrationActionsSuccess({ searchResponse }),
              ),
            );
        },
      ),
      catchError((error: WebError) => [
        IntegrationActions.loadIntegrationActionsFailure(),
        AlertBannerActions.showAlertBanner({
          alertType: ALERT_BANNER_TYPE.DANGER,
          target: AlertBannerTarget.PAGE,
          autoDismiss: true,
          message: this.i18nService.translate('INTEGRATIONS.COMMON.INTEGRATION_ACTIONS_LOAD_FAILURE', {
            reason: error.getFullReason(),
          }),
        }),
      ]),
    ),
  );

  /**
   * updateIntegrationActions$
   * Trigger {loadIntegrationActions} effect when UI request paging or refresh action
   * No change on curent request params.
   * @type {Observable<Action>}
   * @memberof IntegrationEffects
   */
  public updateIntegrationActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.changeIntegrationActionsPagination, IntegrationActions.refreshIntegrationActions),
      withLatestFrom(this.store.select(IntegrationSelectors.getIntegrationActionsSearchRequest)),
      map(([_action, searchRequest]: [Action, GenericSearchRequest]) => IntegrationActions.loadIntegrationActions({ searchRequest })),
    ),
  );

  /**
   * Creates an instance of IntegrationEffects
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {IntegrationService} integrationService
   * @param {RouterExtensions} routerExtensions
   * @memberof IntegrationEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private i18nService: I18NService,
    private integrationService: IntegrationService,
    private routerExtensions: RouterExtensions,
  ) {}

  /**
   * Returns list of unique service names based on specified integration details list
   * @param {IntegrationDetails[]} integrationDetailsList - Holds list of integrations from which service names needs to be extracted
   * @returns {string[]}
   * @memberof IntegrationEffects
   */
  private getServiceNames(integrationDetailsList: IntegrationDetails[]): string[] {
    const serviceNameSet: Set<string> = new Set<string>();
    integrationDetailsList.forEach((integrationDetails: IntegrationDetails) => {
      serviceNameSet.add(this.i18nService.translate(INTEGRATION_TYPE_TO_LABEL_MAP[integrationDetails.integration]));
    });
    return Array.from(serviceNameSet);
  }
}
