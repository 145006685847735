/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  AutomationSearchRequest,
  COLUMN_NAMES,
  CustomReportSearchRequest,
  DashboardSearchRequest,
  SearchTerm,
} from '@ws1c/intelligence-models';

export const helper = {
  getUserAutomationsSearchRequest,
  getUserCustomReportsSearchRequest,
  getUserDashboardsSearchRequest,
};

/**
 * getUserAutomationsSearchRequest
 * @param {string} accountId
 * @param {AutomationSearchRequest} automationSearchRequest
 * @returns {AutomationSearchRequest}
 * @memberof SystemLimitsEffects
 */
export function getUserAutomationsSearchRequest(
  accountId: string,
  automationSearchRequest: AutomationSearchRequest,
): AutomationSearchRequest {
  const searchTerms: SearchTerm[] = automationSearchRequest.searchTerms ?? [];
  searchTerms.push(getDefaultSearchTerm(accountId));
  return new AutomationSearchRequest({
    ...automationSearchRequest,
    searchTerms,
    filter: `${COLUMN_NAMES.byName.source_object_type} IS NULL`,
  });
}

/**
 * getUserCustomReportsSearchRequest
 * @param {string} accountId
 * @param {CustomReportSearchRequest} customReportSearchRequest
 * @returns {CustomReportSearchRequest}
 * @memberof SystemLimitsEffects
 */
export function getUserCustomReportsSearchRequest(
  accountId: string,
  customReportSearchRequest: CustomReportSearchRequest,
): CustomReportSearchRequest {
  const searchTerms: SearchTerm[] = customReportSearchRequest.searchTerms ?? [];
  searchTerms.push(getDefaultSearchTerm(accountId));
  return new CustomReportSearchRequest({
    ...customReportSearchRequest,
    searchTerms,
  });
}

/**
 * getUserDashboardsSearchRequest
 * @param {string} accountId
 * @param {DashboardSearchRequest} dashboardSearchRequest
 * @returns {DashboardSearchRequest}
 * @memberof SystemLimitsEffects
 */
export function getUserDashboardsSearchRequest(accountId: string, dashboardSearchRequest: DashboardSearchRequest): DashboardSearchRequest {
  const searchTerms: SearchTerm[] = dashboardSearchRequest.searchTerms ?? [];
  searchTerms.push(getDefaultSearchTerm(accountId));
  return new DashboardSearchRequest({
    ...dashboardSearchRequest,
    searchTerms,
  });
}

/**
 * getDefaultSearchTerm
 * @param {string} accountId
 * @returns {SearchTerm}
 * @memberof SystemLimitsEffects
 */
function getDefaultSearchTerm(accountId: string): SearchTerm {
  return new SearchTerm({
    value: accountId,
    fields: [COLUMN_NAMES.byName.created_by],
  });
}
