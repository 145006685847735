/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, getFailureReason, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DataAccessPolicyService } from '@dpa-shared-merlot/services';
import { AccountActions, DataAccessPolicyActions, DataAccessPolicySelectors, MerlotState } from '@dpa-shared-merlot/store';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, NavigationActions } from '@ws1c/intelligence-core';
import {
  AccountSearchResponse,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  BlockerStatus,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicyBulkAssignRequest,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for DataAccessPolicy actions
 * @export
 * @class  DataAccessPolicyEffects
 */
@Injectable()
export class DataAccessPolicyEffects {
  /**
   * getDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public getDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.getDataAccessPolicy),
      withLatestFrom(
        this.store.select(DataAccessPolicySelectors.getDataAccessPolicySearchRequest),
        (action: Action, dataAccessPolicySearchRequest: GenericSearchRequest) => dataAccessPolicySearchRequest,
      ),
      switchMap((dataAccessPolicySearchRequest: GenericSearchRequest) => {
        return this.dataAccessPolicyService.getDataAccessPolicy(dataAccessPolicySearchRequest).pipe(
          map((dataAccessPolicySearchResponse: DataAccessPolicySearchResponse) =>
            DataAccessPolicyActions.getDataAccessPolicySuccess({ dataAccessPolicySearchResponse }),
          ),
          catchError((webError: WebError) => of(DataAccessPolicyActions.getDataAccessPolicyFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * getDataAccessPolicyById$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public getDataAccessPolicyById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.getDataAccessPolicyById),
      switchMap((props: { policyId: string }) => {
        return this.dataAccessPolicyService.getDataAccessPolicyById(props.policyId).pipe(
          map((dataAccessPolicy: DataAccessPolicy) => DataAccessPolicyActions.getDataAccessPolicyByIdSuccess({ dataAccessPolicy })),
          catchError((webError: WebError) => of(DataAccessPolicyActions.getDataAccessPolicyByIdFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * previewAddDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public previewAddDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.previewAddDataAccessPolicy),
      map((props: { preview: DataAccessPolicyAssignPreview }) => {
        return DataAccessPolicyActions.setAssignUsersPreview({
          preview: props.preview,
        });
      }),
    ),
  );

  /**
   * previewEditDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public previewEditDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.previewEditDataAccessPolicy),
      switchMap((props: { dataAccessPolicy: DataAccessPolicy }) => {
        const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
          policyIds: [props.dataAccessPolicy.id],
          accountIds: props.dataAccessPolicy.accessConfiguration.permit,
        });
        return this.dataAccessPolicyService.getAssignUsersPreview(bulkAssignRequest).pipe(
          map((preview: DataAccessPolicyAssignPreview) =>
            DataAccessPolicyActions.setAssignUsersPreview({
              preview,
            }),
          ),
          catchError((webError: WebError) => {
            const reason = getFailureReason(webError);
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.ASSIGN_USER_PREVIEW_FAILED', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * addDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public addDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.addDataAccessPolicy),
      withLatestFrom(this.store.select(DataAccessPolicySelectors.getSelectedDataAccessPolicy)),
      switchMap(([_action, dataAccessPolicy]: [Action, DataAccessPolicy]) => {
        return this.dataAccessPolicyService.addDataAccessPolicy(dataAccessPolicy).pipe(
          mergeMap((response: DataAccessPolicy) => {
            if (!isEmpty(dataAccessPolicy.accessConfiguration.permit)) {
              const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
                policyIds: [response.id],
                accountIds: dataAccessPolicy.accessConfiguration.permit,
              });
              return [
                DataAccessPolicyActions.assignUsers({
                  bulkAssignRequest,
                }),
              ];
            }
            return [
              NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
              DataAccessPolicyActions.goToListView(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_SUCCESS'),
              }),
            ];
          }),
          catchError((webError: WebError) => {
            const reason = getFailureReason(
              webError,
              this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_UPDATE_DUPLICATE_ERROR'),
              this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_GENERIC_ERROR'),
            );
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_FAILURE', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * editDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public editDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.editDataAccessPolicy),
      withLatestFrom(this.store.select(DataAccessPolicySelectors.getSelectedDataAccessPolicy)),
      switchMap(([_action, dataAccessPolicy]: [Action, DataAccessPolicy]) => {
        return this.dataAccessPolicyService.updateDataAccessPolicy(dataAccessPolicy).pipe(
          map((response: DataAccessPolicy) => {
            const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
              policyIds: [response.id],
              accountIds: dataAccessPolicy.accessConfiguration.permit,
            });
            return DataAccessPolicyActions.assignUsers({
              bulkAssignRequest,
            });
          }),
          catchError((webError: WebError) => {
            const reason = getFailureReason(
              webError,
              this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_UPDATE_DUPLICATE_ERROR'),
              this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_GENERIC_ERROR'),
            );
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_FAILURE', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * assignUsers$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public assignUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.assignUsers),
      switchMap((props: { bulkAssignRequest: DataAccessPolicyBulkAssignRequest }) => {
        return this.dataAccessPolicyService.assignUsers(props.bulkAssignRequest).pipe(
          mergeMap(() =>
            props.bulkAssignRequest.redirectToPolicyHome
              ? [
                  NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
                  DataAccessPolicyActions.goToListView(),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_SUCCESS'),
                  }),
                ]
              : [
                  AccountActions.goToAdministratorHome(),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate(props.bulkAssignRequest.successMessageKey),
                  }),
                ],
          ),
          catchError((webError: WebError) => {
            const reason = getFailureReason(webError);
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_ACCOUNTS_ERROR', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * unAssignUsers$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public unAssignUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.unAssignUsers),
      switchMap((props: { bulkAssignRequest: DataAccessPolicyBulkAssignRequest }) => {
        return this.dataAccessPolicyService.unAssignUsers(props.bulkAssignRequest).pipe(
          mergeMap(() => [
            AccountActions.goToAdministratorHome(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMINISTRATOR.EDIT_ADMINISTRATOR_SUCCESS'),
            }),
          ]),
          catchError((webError: WebError) => {
            const reason = getFailureReason(webError);
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_ACCOUNTS_ERROR', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * getAssignedUsers$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public getAssignedUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.getAssignedUsers),
      switchMap((props: { id: string }) => {
        return this.dataAccessPolicyService.getAssignedUsers(props.id).pipe(
          map((assignedAccountsSearchResponse: AccountSearchResponse) => {
            return DataAccessPolicyActions.getAssignedUsersSuccess({ assignedAccountsSearchResponse });
          }),
          catchError((webError: WebError) => of(DataAccessPolicyActions.getDataAccessPolicyFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * updateDataAccessPolicyRequest$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public updateDataAccessPolicyRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DataAccessPolicyActions.filterDataAccessPolicy,
        DataAccessPolicyActions.pageDataAccessPolicy,
        DataAccessPolicyActions.sortDataAccessPolicy,
        DataAccessPolicyActions.refreshDataAccessPolicy,
      ),
      withLatestFrom(
        this.store.select(DataAccessPolicySelectors.getDataAccessPolicySearchRequest),
        (action: Action, dataAccessPolicySearchRequest: GenericSearchRequest) => dataAccessPolicySearchRequest,
      ),
      map((genericSearchRequest: GenericSearchRequest) => DataAccessPolicyActions.getDataAccessPolicy({ genericSearchRequest })),
    ),
  );

  /**
   * toggleDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public toggleDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.toggleDataAccessPolicy),
      switchMap((props: { dataAccessPolicy: DataAccessPolicy }) => {
        return this.dataAccessPolicyService.updateDataAccessPolicy(props.dataAccessPolicy).pipe(
          map(() => DataAccessPolicyActions.refreshDataAccessPolicy()),
          catchError((webError: WebError) => {
            const reason = getFailureReason(webError);
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.SAVE_FAILURE', { reason }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * deleteDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public deleteDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessPolicyActions.deleteDataAccessPolicy),
      withLatestFrom(
        this.store.select(DataAccessPolicySelectors.getSelectedDataAccessPolicy),
        this.store.select(DataAccessPolicySelectors.getDataAccessPolicyDeleteConfirmModal),
        (action: Action, selectedDataAccessPolicy: DataAccessPolicy, showModal: boolean) => {
          return {
            selectedDataAccessPolicy,
            showModal,
          };
        },
      ),
      switchMap((selected: GenericObject) => {
        let deletePolicies = selected.selectedDataAccessPolicy;
        if (selected.showModal && selected.selectedDataAccessPolicy) {
          deletePolicies = [selected.selectedDataAccessPolicy];
        }
        return this.dataAccessPolicyService.deleteDataAccessPolicy(deletePolicies).pipe(
          switchMap(() => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.DELETE_DATA_ACCESS_POLICY_SUCCESS_MSG'),
              }),
              DataAccessPolicyActions.deleteDataAccessPolicySuccess(),
              DataAccessPolicyActions.refreshDataAccessPolicy(),
            ];
          }),
          catchError((webError: WebError) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('DATA_ACCESS_POLICY.DELETE_DATA_ACCESS_POLICY_FAILURE_MSG'),
              }),
              DataAccessPolicyActions.deleteDataAccessPolicyFailure({ webError }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * goToListView$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public goToListView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataAccessPolicyActions.goToListView),
        tap(() => this.routerExtensions.navigate([ROUTE_NAMES.SETTINGS.DATA_ACCESS_POLICY_LIST])),
      ),
    { dispatch: false },
  );

  /**
   * goToAddDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public goToAddDataAccessPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataAccessPolicyActions.goToAddDataAccessPolicy),
        tap(() => this.routerExtensions.navigate([ROUTE_NAMES.SETTINGS.DATA_ACCESS_POLICY_ADD])),
      ),
    { dispatch: false },
  );

  /**
   * goToEditDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof DataAccessPolicyEffects
   */
  public goToEditDataAccessPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataAccessPolicyActions.goToEditDataAccessPolicy),
        tap((props: { dataAccessPolicy: DataAccessPolicy }) => {
          this.routerExtensions.navigate([ROUTE_NAMES.SETTINGS.DATA_ACCESS_POLICY_EDIT(props.dataAccessPolicy.id)]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of DataAccessPolicyEffects.
   * @param {Store<MerlotState>} store
   * @param {I18NService} i18nService
   * @param {Actions} actions$
   * @param {DataAccessPolicyService} dataAccessPolicyService
   * @param {RouterExtensions} routerExtensions
   */
  constructor(
    private store: Store<MerlotState>,
    private i18nService: I18NService,
    private actions$: Actions,
    private dataAccessPolicyService: DataAccessPolicyService,
    private routerExtensions: RouterExtensions,
  ) {}
}
