/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { getFailureReason, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ServiceAccountService } from '@dpa-shared-merlot/services';
import { MerlotState, ServiceAccountsSelectors } from '@dpa-shared-merlot/store';
import { ServiceAccountsActions } from '@dpa-shared-merlot/store/service-accounts/service-accounts.actions';
import { DownloadService, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions } from '@ws1c/intelligence-core';
import { ALERT_BANNER_TYPE, AlertBannerTarget, ROUTE_NAMES, ServiceAccount } from '@ws1c/intelligence-models';

/**
 * Handles side effects for Service Account actions
 * @export
 * @class ServiceAccountEffects
 */
@Injectable()
export class ServiceAccountEffects {
  /**
   * getServiceAccounts$
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public getServiceAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceAccountsActions.getServiceAccounts),
      switchMap(() => {
        return this.serviceAccountService.getServiceAccounts().pipe(
          map((serviceAccounts: ServiceAccount[]) => {
            return ServiceAccountsActions.getServiceAccountsSuccess({ serviceAccounts });
          }),
          catchError((webError: WebError) => of(ServiceAccountsActions.getServiceAccountsFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * generateClientSecret$
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public generateClientSecret$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceAccountsActions.generateClientSecret),
      switchMap((props: ReturnType<typeof ServiceAccountsActions.generateClientSecret>) => {
        return this.serviceAccountService.generateClientSecret(props.serviceAccount).pipe(
          mergeMap((serviceAccount: ServiceAccount) => [
            ServiceAccountsActions.generateClientSecretSuccess({ serviceAccount }),
            ServiceAccountsActions.goToServiceAccountDetail({ serviceAccount }),
            ServiceAccountsActions.saveClientSecretToFile(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('SERVICE_ACCOUNTS.GENERATE_CLIENT_SECRET_SUCCESS', { name: serviceAccount.name }),
            }),
          ]),
          catchError((webError: WebError) => {
            const reason = getFailureReason(
              webError,
              this.i18nService.translate('SERVICE_ACCOUNTS.GENERATE_CLIENT_SECRET_DUPLICATE_ERROR'),
            );
            return merge(
              of(ServiceAccountsActions.generateClientSecretFailure({ webError })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('SERVICE_ACCOUNTS.GENERATE_CLIENT_SECRET_FAILURE', { reason }),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * regenerateClientSecret$
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public regenerateClientSecret$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceAccountsActions.regenerateClientSecret),
      withLatestFrom(
        this.store.select(ServiceAccountsSelectors.getSelectedServiceAccount),
        (action: Action, serviceAccount: ServiceAccount) => serviceAccount,
      ),
      switchMap((serviceAccount: ServiceAccount) => {
        return this.serviceAccountService.regenerateClientSecret(serviceAccount.clientId).pipe(
          mergeMap((credentials: string) => [
            ServiceAccountsActions.goToServiceAccountDetail({ serviceAccount }),
            ServiceAccountsActions.regenerateClientSecretSuccess({ credentials }),
            ServiceAccountsActions.showRegenerateConfirmModal({
              isOpen: false,
            }),
            ServiceAccountsActions.saveClientSecretToFile(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('SERVICE_ACCOUNTS.GENERATE_CLIENT_SECRET_SUCCESS', { name: serviceAccount.name }),
            }),
          ]),
          catchError((webError: WebError) => of(ServiceAccountsActions.generateClientSecretFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * saveClientSecretToFile$
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public saveClientSecretToFile$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ServiceAccountsActions.saveClientSecretToFile),
        withLatestFrom(
          this.store.select(ServiceAccountsSelectors.getSelectedServiceAccountWithEndpoint),
          (action: Action, serviceAccount: ServiceAccount) => serviceAccount,
        ),
        map((serviceAccount: ServiceAccount) => {
          this.downloadService.downloadJsonAsFile(serviceAccount, `service-account-${serviceAccount.clientId}.json`);
        }),
      ),
    { dispatch: false },
  );

  /**
   * deleteServiceAccount$
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public deleteServiceAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceAccountsActions.deleteServiceAccount),
      withLatestFrom(
        this.store.select(ServiceAccountsSelectors.getSelectedServiceAccount),
        (action: Action, serviceAccount: ServiceAccount) => serviceAccount,
      ),
      switchMap((serviceAccount: ServiceAccount) => {
        return this.serviceAccountService.deleteServiceAccount(serviceAccount.clientId).pipe(
          mergeMap(() => [
            ServiceAccountsActions.getServiceAccounts(),
            ServiceAccountsActions.showDeleteConfirmModal({
              isOpen: false,
            }),
            ServiceAccountsActions.goToListView(),
          ]),
          catchError((webError: WebError) => of(ServiceAccountsActions.deleteClientSecretFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public goToServiceAccountDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ServiceAccountsActions.goToServiceAccountDetail),
        tap((props: ReturnType<typeof ServiceAccountsActions.goToServiceAccountDetail>) =>
          this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.SERVICE_ACCOUNT}/${props.serviceAccount.clientId}`]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public goToAddServiceAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ServiceAccountsActions.goToAddServiceAccount),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.SERVICE_ACCOUNT_ADD}`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof ServiceAccountEffects
   */
  public goToServiceAccountListView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ServiceAccountsActions.goToListView),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.SERVICE_ACCOUNT}`])),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of ServiceAccountEffects.
   *
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {ServiceAccountService} serviceAccountService
   * @param {I18NService} i18nService
   * @param {DownloadService} downloadService
   * @param {RouterExtensions} routerExtensions
   * @memberof ServiceAccountEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private serviceAccountService: ServiceAccountService,
    private i18nService: I18NService,
    private downloadService: DownloadService,
    private routerExtensions: RouterExtensions,
  ) {}
}
