/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { AppsDashboardEffects } from './apps-dashboard.effects';
import { CarbonBlackDashboardEffects } from './carbon-black-dashboard.effects';
import { DevicesDashboardEffects } from './devices-dashboard.effects';
import { HorizonEffects } from './horizon.effects';
import { LoginRiskDashboardEffects } from './login-risk-dashboard.effects';
import { OsUpdatesDashboardEffects } from './os-updates-dashboard.effects';
import { PerformanceIndicatorsDashboardEffects } from './performance-indicators-dashboard.effects';
import { SecurityDashboardEffects } from './security-dashboard.effects';
import { UagTunnelDashboardEffects } from './uag-tunnel-dashboard.effects';
import { UserProfileEffects } from './user-profile.effects';
import { UserRiskDashboardEffects } from './user-risk-dashboard.effects';
import { WorkspaceOneMtdDashboardEffects } from './workspace-one-mtd-dashboard.effects';

export const DASHBOARD_EFFECTS = [
  AppsDashboardEffects,
  CarbonBlackDashboardEffects,
  DevicesDashboardEffects,
  HorizonEffects,
  LoginRiskDashboardEffects,
  OsUpdatesDashboardEffects,
  PerformanceIndicatorsDashboardEffects,
  SecurityDashboardEffects,
  UagTunnelDashboardEffects,
  UserProfileEffects,
  UserRiskDashboardEffects,
  WorkspaceOneMtdDashboardEffects,
];

export * from './apps-dashboard.effects';
export * from './carbon-black-dashboard.effects';
export * from './devices-dashboard.effects';
export * from './horizon.effects';
export * from './login-risk-dashboard.effects';
export * from './os-updates-dashboard.effects';
export * from './performance-indicators-dashboard.effects';
export * from './security-dashboard.effects';
export * from './uag-tunnel-dashboard.effects';
export * from './user-profile.effects';
export * from './user-risk-dashboard.effects';
export * from './workspace-one-mtd-dashboard.effects';
