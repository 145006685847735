/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { SortOn, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EventForwarderTemplate, PartnerConnectionSetupRequest, ServiceConfigTemplate } from '@dpa-shared-merlot/model';
import { ConnectionService } from '@dpa-shared-merlot/services';
import { ConnectionActions, ConnectionSelectors, MerlotState } from '@dpa-shared-merlot/store';
import { FeatureControl, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import {
  AlertBannerActions,
  AutomationCommonSelectors,
  ConnectionCommonActions,
  ConnectionCommonService,
  FeatureSelectors,
  NavigationActions,
  UserPreferenceActions,
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  AvailableServiceSearchRequest,
  AvailableServiceSearchResponse,
  AvailableServiceTenant,
  COLUMN_NAMES,
  Connection,
  ConnectionConfig,
  ConnectionModalMode,
  ConnectionModalSection,
  LOAD_STATE,
  ObjectById,
  ROUTE_NAMES,
  UserPreference,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for Connection UI
 * @export
 * @class ConnectionEffects
 */
@Injectable()
export class ConnectionEffects {
  /**
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public loadServiceConfigTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadServiceConfigTemplates, UserPreferenceActions.loadUserPreferenceSuccess),
      withLatestFrom(
        this.store.select(UserPreferenceCommonSelectors.getUserPreference),
        this.store.select(FeatureSelectors.hasIntegrationReadOnlyPerm),
      ),
      map(([_action, _userPreferences, hasIntegrationPerm]: [Action, UserPreference, boolean]) => {
        return hasIntegrationPerm;
      }),
      filter(Boolean),
      switchMap(() => {
        return this.connectionService.getServiceConfigTemplates().pipe(
          map((templates: ServiceConfigTemplate[]) => ConnectionActions.loadServiceConfigTemplatesSuccess({ templates })),
          catchError((error: WebError) => of(ConnectionActions.loadServiceConfigTemplatesFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadTrustNetworkServices$
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public loadTrustNetworkServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadTrustNetworkServices),
      switchMap((_action: Action) => {
        const request = new AvailableServiceSearchRequest({
          sortOns: [
            new SortOn({
              by: COLUMN_NAMES.byName.service_type,
              reverse: false,
            }),
          ],
        });
        const serviceResponse: Observable<AvailableServiceSearchResponse> =
          this.connectionCommonService.getAvailableServicesInbound(request);
        return serviceResponse.pipe(
          map((response: AvailableServiceSearchResponse) => ConnectionActions.loadTrustNetworkServicesSuccess({ response })),
          catchError((error: WebError) => {
            return [
              ConnectionActions.loadTrustNetworkServicesFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.APP,
                autoDismiss: true,
                message: this.i18nService.translate('AUTOMATION_WIZARD.LOAD_AVAILABLE_SERVICES_ERROR', {
                  error: error.getFullReason(),
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public editAvailableService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionCommonActions.requestEditConnection),
      switchMap(({ availableService, availableServiceTenant }: ReturnType<typeof ConnectionCommonActions.requestEditConnection>) => {
        return this.connectionService.getServiceConfig(availableService, availableServiceTenant).pipe(
          map((config: ConnectionConfig) => ConnectionActions.requestEditConnectionSuccess({ settings: config.settings })),
          catchError((error: WebError) => of(ConnectionActions.requestEditConnectionFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public confirmSetupConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.confirmSetupConnection),
      withLatestFrom(
        this.store.select(ConnectionSelectors.getConnectionModalMode),
        this.store.select(AutomationCommonSelectors.isAutomationWizardCreateMode),
        this.store.select(ConnectionSelectors.getConnectionModalModel),
        this.store.select(ConnectionSelectors.getConnectionModalTenantModel),
        this.store.select(ConnectionSelectors.getConnectionSetupRequest),
      ),
      switchMap(
        ([_action, modalMode, isAutomationWizardInCreateMode, availableService, availableServiceTenant, connectionSetupRequest]: [
          Action,
          ConnectionModalMode,
          boolean,
          AvailableService,
          AvailableServiceTenant,
          any,
        ]) => {
          if (modalMode === ConnectionModalMode.EDIT) {
            return of(ConnectionActions.confirmEditConnection());
          }
          let initialRequest;
          const isEditConfirmModalMode = modalMode === ConnectionModalMode.EDIT_CONFIRM;
          if (isEditConfirmModalMode) {
            initialRequest = this.connectionService.updateConnection(
              availableServiceTenant?.serviceConfigId ?? availableService.serviceConfigId,
              connectionSetupRequest,
            );
          } else {
            initialRequest = this.connectionService.setupConnection(connectionSetupRequest);
          }
          const connectionRequest = initialRequest.pipe(
            switchMap((connection: Connection) => {
              const successActions: Action[] = [
                ConnectionActions.updateConnectionSetupRequest({
                  request: {
                    loadState: LOAD_STATE.SUCCESS,
                  },
                }),
                ConnectionCommonActions.updateServiceWithConfig({ connection }),
                ConnectionActions.getConnectionDetails({
                  id: availableService.id,
                }),
                ConnectionCommonActions.setTestConnectionModalSection({ connectionModalSection: ConnectionModalSection.NONE }),
                ConnectionActions.closeConnectionModal(),
              ];
              if (!isEditConfirmModalMode && isAutomationWizardInCreateMode) {
                successActions.push(
                  NavigationActions.setPendingMessage({
                    pendingMessage: this.i18nService.translate('AUTOMATION_WIZARD.CONNECTIONS_REMAIN'),
                  }),
                );
              }
              return successActions;
            }),
            catchError((error: WebError) => {
              return [
                ConnectionActions.updateConnectionSetupRequest({
                  request: {
                    webError: error,
                    loadState: LOAD_STATE.FAILURE,
                  },
                }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  autoDismiss: false,
                  message: this.i18nService.translate('CONNECTIONS.FAILED_TO_SETUP_CONNECTION', {
                    reason: error.getFullReason(),
                  }),
                }),
              ];
            }),
          );

          return merge(...[of(ConnectionActions.processSetupConnection()), connectionRequest]) as Observable<Action>;
        },
      ),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public confirmDeauthorizeConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.confirmDeauthorizeConnection),
      withLatestFrom(
        this.store.select(ConnectionSelectors.getConnectionModalModel),
        this.store.select(ConnectionSelectors.getConnectionModalTenantModel),
        this.routerExtensions.url$,
      ),
      switchMap(
        ([_action, availableService, availableServiceTenant, navigationUrl]: [
          Action,
          AvailableService,
          AvailableServiceTenant,
          string,
        ]) => {
          const actions: Action[] = [
            ConnectionActions.updateConnectionDeauthorizeRequest({
              connectionDeauthorizeRequest: {
                loadState: LOAD_STATE.SUCCESS,
              },
            }),
            ConnectionCommonActions.updateServiceWithConfig({
              connection: new Connection({
                serviceId: availableService.id,
                statusCheckSuccess: false,
              }),
            }),
            ConnectionActions.getConnectionDetails({
              id: availableService.id,
            }),
            ConnectionCommonActions.setTestConnectionModalSection({ connectionModalSection: ConnectionModalSection.NONE }),
            ConnectionActions.closeConnectionModal(),
          ];
          if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
            actions.push(ConnectionActions.deauthorizeFromIntegrationViewSuccess());
          }
          const deauthorizeConnectionRequest = this.connectionService.deauthorizeConnection(availableService, availableServiceTenant).pipe(
            switchMap(() => actions),
            catchError((error: WebError) => {
              return [
                ConnectionActions.updateConnectionDeauthorizeRequest({
                  connectionDeauthorizeRequest: {
                    webError: error,
                    loadState: LOAD_STATE.FAILURE,
                  },
                }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  autoDismiss: false,
                  message: this.i18nService.translate('CONNECTIONS.FAILED_TO_DEAUTHORIZE_CONNECTION', {
                    error: error.getFullReason(),
                  }),
                }),
              ];
            }),
          );

          return merge(
            of(
              ConnectionActions.updateConnectionDeauthorizeRequest({
                connectionDeauthorizeRequest: {
                  loadState: LOAD_STATE.IN_FLIGHT,
                },
              }),
            ),
            deauthorizeConnectionRequest,
          );
        },
      ),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public goToAutomationDetailsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.goToAutomationDetailPage),
        tap(({ automationId }: ReturnType<typeof ConnectionActions.goToAutomationDetailPage>) =>
          this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}/${automationId}`]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * getConnectionDetails$
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public getConnectionDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.getConnectionDetails),
      switchMap(({ id }: ReturnType<typeof ConnectionActions.getConnectionDetails>) => {
        return this.connectionService.getConnectionDetails(id).pipe(
          map((availableService: AvailableService) => ConnectionActions.getConnectionDetailsSuccess({ availableService })),
          catchError((error: WebError) => of(ConnectionActions.getConnectionDetailsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadPartners$
   * Effect for loading Partners from backend. This includes both Trust Network Partners like Wandera and
   * non Trust Network Partners like Zoom.
   * Only those partners which are already setup will be returned from backend.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public loadPartners$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadPartners),
      switchMap(() => {
        return this.connectionService.getAvailablePartners().pipe(
          map((partners: AvailablePartner[]) => ConnectionActions.loadPartnersSuccess({ partners })),
          catchError((error: WebError) => {
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.APP,
                autoDismiss: true,
                message: this.i18nService.translate('CONNECTIONS.TRUST_NETWORK_PARTNER.LOAD_AVAILABLE_PARTNERS_ERROR', {
                  error: error.getFullReason(),
                }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * setupTrustNetworkPartnerConnection$
   * Effect for setup of Trust Network Partner connection like Wandera.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public setupTrustNetworkPartnerConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.setupTrustNetworkPartnerConnection),
      withLatestFrom(this.store.select(ConnectionSelectors.getSelectedPartner)),
      switchMap(
        ([{ email }, availablePartner]: [ReturnType<typeof ConnectionActions.setupTrustNetworkPartnerConnection>, AvailablePartner]) => {
          return this.connectionService
            .setupPartnerConnection(
              new PartnerConnectionSetupRequest({
                name: availablePartner.name,
                email,
              }),
            )
            .pipe(
              switchMap((partnerResponse: AvailablePartner) => {
                const setupSuccessActions: Action[] = [
                  ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionSuccess({
                    availablePartner: partnerResponse,
                  }),
                ];
                if (availablePartner?.isAcknowledgementRequired) {
                  setupSuccessActions.push(
                    AlertBannerActions.showAlertBanner({
                      alertType: ALERT_BANNER_TYPE.SUCCESS,
                      target: AlertBannerTarget.MODAL,
                      message: this.i18nService.translate('CONNECTIONS.GENERATE_CREDENTIALS_SUCCESS_TEXT'),
                    }),
                  );
                }
                return setupSuccessActions;
              }),
              catchError((error: WebError) => {
                return [
                  ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionFailure({ error }),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    target: AlertBannerTarget.MODAL,
                    message: this.i18nService.translate('CONNECTIONS.FAILED_TO_SETUP_CONNECTION', {
                      reason: error.getFullReason(),
                    }),
                  }),
                ];
              }),
            );
        },
      ),
    ),
  );

  /**
   * updateTrustNetworkPartnerConnection$
   * Effect for update of Trust Network Partner connection like Wandera.
   * As of now, only email can be updated.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public updateTrustNetworkPartnerConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.updateTrustNetworkPartnerConnection),
      withLatestFrom(this.store.select(ConnectionSelectors.getSelectedPartner)),
      switchMap(
        ([{ email }, availablePartner]: [ReturnType<typeof ConnectionActions.updateTrustNetworkPartnerConnection>, AvailablePartner]) => {
          return this.connectionService
            .updatePartnerConnection(
              new PartnerConnectionSetupRequest({
                id: availablePartner.id,
                name: availablePartner.name,
                email,
              }),
            )
            .pipe(
              map((partnerResponse: AvailablePartner) => {
                return ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionSuccess({
                  availablePartner: partnerResponse,
                });
              }),
              catchError((error: WebError) => {
                return [
                  ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionFailure({ error }),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    target: AlertBannerTarget.MODAL,
                    message: this.i18nService.translate('CUSTOM_CONNECTION.FAILED_TO_UPDATE_CONNECTION', {
                      reason: error.getFullReason(),
                    }),
                  }),
                ];
              }),
            );
        },
      ),
    ),
  );

  /**
   * regenerateTrustNetworkPartnerConnectionToken$
   * Effect for regenerate Trust Network Partner connection token.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public regenerateTrustNetworkPartnerConnectionToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.regenerateTrustNetworkPartnerConnectionToken),
      withLatestFrom(this.store.select(ConnectionSelectors.getSelectedPartner)),
      switchMap(([_action, availablePartner]: [Action, AvailablePartner]) => {
        return this.connectionService.regeneratePartnerToken(availablePartner.id).pipe(
          map((partnerResponse: AvailablePartner) => {
            return ConnectionActions.regenerateTrustNetworkPartnerConnectionTokenSuccess({ availablePartner: partnerResponse });
          }),
          catchError((error: WebError) => {
            return [
              ConnectionActions.regenerateTrustNetworkPartnerConnectionTokenFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTIONS.TRUST_NETWORK_PARTNER.FAILED_TO_REGENERATE_TOKEN', {
                  reason: error.getFullReason(),
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * deauthorizeTrustNetworkPartnerConnection$
   * Effect for delete Trust Network Partner connection.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public deauthorizeTrustNetworkPartnerConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.deauthorizeTrustNetworkPartnerConnection),
      withLatestFrom(this.store.select(ConnectionSelectors.getSelectedPartner), this.routerExtensions.url$),
      switchMap(([_action, availablePartner, navigationUrl]: [Action, AvailablePartner, string]) => {
        const actions: Action[] = [
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.SUCCESS,
            message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_SUCCESS_MESSAGE', {
              integrationName: availablePartner.displayName,
            }),
          }),
          ConnectionActions.deauthorizeTrustNetworkPartnerConnectionSuccess(),
          ConnectionActions.closePartnerConnectionModal(),
          ConnectionActions.loadPartners(),
        ];
        if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
          actions.push(ConnectionActions.deauthorizeFromIntegrationViewSuccess());
        }
        return this.connectionService.deauthorizePartnerConnection(availablePartner.id).pipe(
          mergeMap(() => actions),
          catchError((error: WebError) => {
            return [
              ConnectionActions.deauthorizeTrustNetworkPartnerConnectionFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_FAILURE_MESSAGE', {
                  integrationName: availablePartner.displayName,
                  reason: error.getFullReason(),
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * revokeOAuthPartnerConnection
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public revokeOAuthPartnerConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.revokePartner),
      withLatestFrom(this.store.select(ConnectionSelectors.getSelectedPartner), this.routerExtensions.url$),
      switchMap(([_action, availablePartner, navigationUrl]: [Action, AvailablePartner, string]) => {
        const actions: Action[] = [
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.SUCCESS,
            message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_SUCCESS_MESSAGE', {
              integrationName: availablePartner.displayName,
            }),
          }),
          ConnectionActions.revokePartnerSuccess(),
          ConnectionActions.loadPartners(),
        ];
        if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
          actions.push(ConnectionActions.deauthorizeFromIntegrationViewSuccess());
        }
        return this.connectionService.revokePartner(availablePartner.name).pipe(
          mergeMap(() => actions),
          catchError((error: WebError) => {
            return [
              ConnectionActions.revokePartnerFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_FAILURE_MESSAGE', {
                  integrationName: availablePartner.displayName,
                  reason: error.getFullReason(),
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * loadTrustNetworkEventForwardersTemplates$
   * Effect to load trust network event forwarders templates which is used to display information
   * on integration card and modal
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public loadTrustNetworkEventForwardersTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadEventForwardersTemplates),
      withLatestFrom(
        this.store.select(FeatureSelectors.hasIntegrationReadOnlyPerm),
        this.store.select(UserPreferenceFeatureControlsSelectors.isEventForwarderEnabled),
        this.store.select(ConnectionSelectors.getEventForwardersTemplatesByName),
        (
          action: Action,
          hasIntegrationPerm: boolean,
          eventForwarderEnabled: boolean,
          eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>,
        ) => hasIntegrationPerm && eventForwarderEnabled && !eventForwardersTemplatesByName,
      ),
      filter(Boolean),
      switchMap(() => {
        return this.connectionService.getEventForwardersTemplates().pipe(
          map((templates: EventForwarderTemplate[]) => ConnectionActions.loadEventForwardersTemplatesSuccess({ templates })),
          catchError((error: WebError) => {
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.APP,
                autoDismiss: true,
                message: this.i18nService.translate('CONNECTIONS.TRUST_NETWORK_PARTNER.LOAD_AVAILABLE_PARTNERS_ERROR', {
                  error: error.getFullReason(),
                }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * loadTrustNetworkEventForwarders$
   * Effect for loading Trust Network Event Forwarders. As of now Carbon Black is using this method.
   * Only those event forwarders which are already setup will be returned from backend.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public loadTrustNetworkEventForwarders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadTrustNetworkEventForwarders),
      withLatestFrom(
        this.store.select(UserPreferenceFeatureControlsSelectors.isEventForwarderEnabled),
        (action: Action, eventForwarderEnabled: boolean) => eventForwarderEnabled,
      ),
      filter(Boolean),
      switchMap(() => {
        return this.connectionService.getAvailableEventForwarders().pipe(
          map((trustNetworkEventForwarders: AvailableEventForwarder[]) => {
            return ConnectionActions.loadTrustNetworkEventForwardersSuccess({ trustNetworkEventForwarders });
          }),
          catchError((error: WebError) => {
            return of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.APP,
                autoDismiss: true,
                message: this.i18nService.translate('CONNECTIONS.TRUST_NETWORK_PARTNER.LOAD_AVAILABLE_PARTNERS_ERROR', {
                  error: error.getFullReason(),
                }),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * setupOrUpdateEventForwarderConnection$
   * Effect for setup or update of Event Forwarder connection like Carbon Black.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public setupOrUpdateEventForwarderConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.setupOrUpdateEventForwarderConnection),
      withLatestFrom(
        this.store.select(ConnectionSelectors.getSelectedEventForwarder),
        this.store.select(ConnectionSelectors.getSelectedEventForwarderTemplate),
      ),
      switchMap(
        ([{ metaFormFields }, availableEventForwarder, eventForwarderTemplate]: [
          ReturnType<typeof ConnectionActions.setupOrUpdateEventForwarderConnection>,
          AvailableEventForwarder,
          EventForwarderTemplate,
        ]) => {
          const setupEventForwarderConnection: Observable<AvailableEventForwarder> = availableEventForwarder.id
            ? this.connectionService.updateEventForwarderConnection(availableEventForwarder, metaFormFields)
            : this.connectionService.setupEventForwarderConnection(availableEventForwarder, metaFormFields);
          return setupEventForwarderConnection.pipe(
            mergeMap((eventForwarder: AvailableEventForwarder) => {
              return [
                ConnectionActions.setupOrUpdateEventForwarderConnectionSuccess({
                  availableEventForwarder: eventForwarder,
                }),
                UserPreferenceActions.updateFeatureFlag({
                  flagName: FeatureControl[`${availableEventForwarder.name}_UPGRADE_REQUIRED`],
                  value: false,
                }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('CONNECTIONS.TRUST_NETWORK_PARTNER.SETUP_SUCCESS_MESSAGE', {
                    integrationName: eventForwarderTemplate.label,
                  }),
                }),
              ];
            }),
            catchError((error: WebError) => {
              return [
                ConnectionActions.setupOrUpdateEventForwarderConnectionFailure({ error }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('CONNECTIONS.FAILED_TO_SETUP_CONNECTION', {
                    reason: error.getFullReason(),
                  }),
                }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * deauthorizeEventForwarderConnection$
   * Effect to delete Event Forwarder connection.
   *
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public deauthorizeEventForwarderConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.deauthorizeEventForwarderConnection),
      withLatestFrom(
        this.store.select(ConnectionSelectors.getSelectedEventForwarder),
        this.store.select(ConnectionSelectors.getSelectedEventForwarderTemplate),
        this.routerExtensions.url$,
      ),
      switchMap(
        ([_action, availableEventForwarder, eventForwarderTemplate, navigationUrl]: [
          Action,
          AvailableEventForwarder,
          EventForwarderTemplate,
          string,
        ]) => {
          const actions: Action[] = [
            ConnectionActions.deauthorizeEventForwarderConnectionSuccess({ availableEventForwarder }),
            ConnectionActions.closeEventForwarderConnectionModal(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_SUCCESS_MESSAGE', {
                integrationName: eventForwarderTemplate.label,
              }),
            }),
          ];
          if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
            actions.push(ConnectionActions.deauthorizeFromIntegrationViewSuccess());
          }
          return this.connectionService.deauthorizeEventForwarderConnection(availableEventForwarder.id).pipe(
            mergeMap(() => actions),
            catchError((error: WebError) => {
              return [
                ConnectionActions.deauthorizeEventForwarderConnectionFailure(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('CONNECTIONS.DEAUTHORIZE_FAILURE_MESSAGE', {
                    integrationName: eventForwarderTemplate.label,
                    reason: error.getFullReason(),
                  }),
                }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * deauthorizeFromIntegrationViewSuccess
   * @type {Observable<Action>}
   * @memberof ConnectionEffects
   */
  public deauthorizeFromIntegrationViewSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.deauthorizeFromIntegrationViewSuccess),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`])),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of ConnectionEffects
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {ConnectionService} connectionService
   * @param {ConnectionCommonService} connectionCommonService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExtensions
   * @memberof ConnectionEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private connectionService: ConnectionService,
    private connectionCommonService: ConnectionCommonService,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
  ) {}
}
