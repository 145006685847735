/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AccountGroupService } from './account-group.service';
import { AppLoadsService } from './app-loads.service';
import { AuditLogService } from './audit-log.service';
import { ConnectionService } from './connection.service';
import { DataAccessPolicyService } from './data-access-policy.service';
import { IntegrationService } from './integration.service';
import { NonAdminService } from './non-admin.service';
import { RequestAnimationFrameService } from './request-animation-frame.service';
import { ServiceAccountService } from './service-accounts.service';
import { UserService } from './user.service';
import { WS1BrokerService } from './ws1-broker.service';

export const MERLOT_PROVIDERS: any[] = [
  AccountGroupService,
  AppLoadsService,
  AuditLogService,
  ConnectionService,
  DataAccessPolicyService,
  IntegrationService,
  NonAdminService,
  RequestAnimationFrameService,
  ServiceAccountService,
  UserService,
  WS1BrokerService,
];

export * from './account-group.service';
export * from './app-loads.service';
export * from './audit-log.service';
export * from './connection.service';
export * from './data-access-policy.service';
export * from './integration.service';
export * from './non-admin.service';
export * from './request-animation-frame.service';
export * from './service-accounts.service';
export * from './user.service';
export * from './ws1-broker.service';
