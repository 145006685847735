/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DateTimeFormat, FormattedDatePipe, GenericObject, SortOn, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { has } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { getDownloadAppErrorStacktraceRequest } from '@dpa-shared/merlot/store/dashboard/apps/app-deployment-dashboard-selector-helpers';
import { AppCrashThread, MissingSymbolicationFile } from '@dpa-shared-merlot/model';
import {
  AppDeploymentDashboardSelectors,
  AppIdmDashboardSelectors,
  AppLoadsActions,
  AppLoadsSelectors,
  AppsDashboardActions,
  AppsDashboardSelectors,
  MerlotState,
  NetworkInsightsSelectors,
  QuentinDashboardSelectors,
  UserProfileActions,
  UserProfileSelectors,
} from '@dpa-shared-merlot/store';
import { DEEM_ROUTE_NAMES, SubDashboardPlatform } from '@ws1c/deem-solution/const';
import { AppConfig, DownloadService, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, DashboardActions, DashboardService } from '@ws1c/intelligence-core';
import { ReportMetaService } from '@ws1c/intelligence-core/services';
import {
  AggregationWidgetChartType,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  App,
  AppCrashGroupRequest,
  AppCrashGroupResponse,
  AppDetailTabType,
  AppErrorCrashUploadsTableRequest,
  AppErrorCrashUploadsTableResponse,
  AppErrorDetailTabType,
  AppHealthStatus,
  AppHeGroupRequest,
  AppHeGroupResponse,
  AppPlatform,
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DeemAppDetailsPageTab,
  Entity,
  getFQN,
  getFQNFunction,
  Integration,
  NetworkInsightsSearchRequest,
  NetworkInsightsSearchResponse,
  NetworkInsightsUrlResponse,
  PreviewReportContentRequest,
  QueryBuilder,
  ROUTE_NAMES,
  RuleGroup,
  SearchTerm,
  StandardDashboardRequest,
  StandardDashboardType,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendMode,
  UserDetailPageType,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
} from '@ws1c/intelligence-models';

/**
 * AppsDashboardEffects
 * @export
 * @class AppsDashboardEffects
 */
@Injectable()
export class AppsDashboardEffects {
  /**
   * loadApteligentDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadApteligentDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadApteligentDashboard, AppsDashboardActions.setApteligentDashboardFilters),
      withLatestFrom(
        this.store.select(AppsDashboardSelectors.getAppDashboardFilterRuleGroup),
        this.store.select(AppsDashboardSelectors.getAppDashboardTrendDateRange),
      ),
      map(([_action, ruleGroup, trendDateRange]: [Action, RuleGroup, TrendDateRange]) => {
        const request = new StandardDashboardRequest(StandardDashboardType.STANDALONE_APTELIGENT_APP_SUMMARY, ruleGroup, trendDateRange);
        return DashboardActions.loadStandardDashboard({ request });
      }),
    ),
  );

  /**
   * loadAppsDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadAppsDashboard, AppsDashboardActions.setAppsDashboardFilters),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getStandardAppSummaryDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => {
        return DashboardActions.loadStandardDashboard({ request });
      }),
    ),
  );

  /**
   * updateAppDashboardFilters$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public updateAppDashboardFilters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppsDashboardActions.updateAppDashboardFilters,
        DashboardActions.refreshAppDashboardFilters,
        DashboardActions.setUserFlowsSubFilterRuleGroup,
      ),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getAppErrorDetailTabType),
        this.store.select(UserProfileSelectors.getAppDetailUserProfileTab),
        (action: Action, appErrorDetailTabType: AppErrorDetailTabType, appDetailUserProfileTab: string) => {
          if (appErrorDetailTabType) {
            return AppsDashboardActions.loadAppErrorDashboard();
          }
          if (appDetailUserProfileTab) {
            return UserProfileActions.loadAppDetailUserProfileDashboard();
          }
          return DashboardActions.loadAppDashboard();
        },
      ),
    ),
  );

  /**
   * setNetworkInsightsSubFilterRuleGroup$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public setNetworkInsightsSubFilterRuleGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.setNetworkInsightsSubFilterRuleGroup),
      map(() => AppsDashboardActions.loadAppNetworkInsightsDashboard()),
    ),
  );

  /**
   * updateAppDashboardGlobalFilters$
   * This effect refreshes all the non-dashboard components when the global filter is changed
   * Tables and breadcrumb lists don't use the normal load standard dashboard, then load preview logic
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public updateAppDashboardGlobalFilters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.updateAppDashboardFilters, DashboardActions.refreshAppDashboardFilters),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getAppDetailTabType),
        this.store.select(AppLoadsSelectors.getAppErrorDetailTabType),
        (action: Action, appDetailTabType: AppDetailTabType, appErrorDetailTabType: AppErrorDetailTabType) => {
          const refreshActions = [];
          switch (appDetailTabType) {
            case AppDetailTabType.ERRORS:
              refreshActions.push(AppsDashboardActions.loadAppErrorCrashTable({ query: undefined }));
              break;
            case AppDetailTabType.NETWORK_INSIGHTS:
              refreshActions.push(AppsDashboardActions.loadNetworkInsightsTable());
              break;
          }
          switch (appErrorDetailTabType) {
            case AppErrorDetailTabType.SUMMARY:
            case AppErrorDetailTabType.STACKTRACE:
              refreshActions.push(AppsDashboardActions.loadAppErrorStacktraceThreads());
              break;
            case AppErrorDetailTabType.BREADCRUMB:
              refreshActions.push(AppsDashboardActions.loadAppErrorBreadcrumbs());
              break;
          }
          return refreshActions;
        },
      ),
      switchMap((actions: Action[]) => actions),
    ),
  );

  /**
   * updateIdmAppDashboardDateRange$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public updateIdmAppDashboardDateRange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.updateIdmAppDashboardDateRange, AppsDashboardActions.setIdmAppDashboardDateRange),
      map(() => AppsDashboardActions.loadAppIdmDashboard()),
    ),
  );

  /**
   * loadAppIdmDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppIdmDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppIdmDashboard),
      withLatestFrom(
        this.store.select(AppIdmDashboardSelectors.getAppIdmDashboardRequest),
        (action: Action, appIdmDashboardRequest: StandardDashboardRequest) => {
          return DashboardActions.loadStandardDashboard({ request: appIdmDashboardRequest });
        },
      ),
    ),
  );

  /**
   * loadAppErrorDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorDashboard),
      withLatestFrom(
        this.store.select(AppDeploymentDashboardSelectors.getStandardAppErrorDashboardRequest),
        (action: Action, request: StandardDashboardRequest) => DashboardActions.loadStandardDashboard({ request }),
      ),
    ),
  );

  /**
   * loadAppErrorDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppDetailUserProfileDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadAppDetailUserProfileDashboard),
      withLatestFrom(
        this.store.select(UserProfileSelectors.getStandardUserProfileDashboardRequest),
        (action: Action, request: StandardDashboardRequest) => {
          return DashboardActions.loadStandardDashboard({ request });
        },
      ),
    ),
  );

  /**
   * loadAppDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadAppDashboard),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getAppDetailTabType),
        this.store.select(AppDeploymentDashboardSelectors.getStandardAppDashboardRequest),
        this.store.select(AppDeploymentDashboardSelectors.getStandardAppExperienceDashboardRequest),
        this.store.select(AppDeploymentDashboardSelectors.getStandardAppUserFlowsDashboardRequest),
        this.store.select(NetworkInsightsSelectors.getStandardAppNetworkInsightsDashboardRequest),
        this.store.select(QuentinDashboardSelectors.getStandardQuentinErrorsDashboardRequest),
        this.store.select(AppDeploymentDashboardSelectors.getStandardHandledExceptionDashboardRequest),
        this.store.select(AppDeploymentDashboardSelectors.getStandardPluginExceptionDashboardRequest),
        this.store.select(UserProfileSelectors.getStandardAppUsersDashboardRequest),
      ),
      map(
        ([
          _action,
          appDetailTabType,
          appDashboardRequest,
          appDashboardExperienceRequest,
          appDashboardUserFlowsRequest,
          appDashboardNetworkInsightsRequest,
          appDashboardQuentinErrorsRequest,
          appDashboardHeRequest,
          appDashboardPeRequest,
          appDashboardUsersRequest,
        ]: [
          Action,
          AppDetailTabType,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
          StandardDashboardRequest,
        ]) => {
          switch (appDetailTabType) {
            case AppDetailTabType.OVERVIEW:
              return DashboardActions.loadStandardDashboard({ request: appDashboardRequest });
            case AppDetailTabType.EXPERIENCE:
              return DashboardActions.loadStandardDashboard({ request: appDashboardExperienceRequest });
            case AppDetailTabType.USER_FLOWS:
              return DashboardActions.loadStandardDashboard({ request: appDashboardUserFlowsRequest });
            case AppDetailTabType.NETWORK_INSIGHTS:
              return DashboardActions.loadStandardDashboard({ request: appDashboardNetworkInsightsRequest });
            case AppDetailTabType.ERRORS:
              return DashboardActions.loadStandardDashboard({ request: appDashboardQuentinErrorsRequest });
            case AppDetailTabType.HANDLED_EXCEPTION:
              return DashboardActions.loadStandardDashboard({ request: appDashboardHeRequest });
            case AppDetailTabType.PLUGIN_EXCEPTION:
              return DashboardActions.loadStandardDashboard({ request: appDashboardPeRequest });
            case AppDetailTabType.USERS:
              return DashboardActions.loadStandardDashboard({ request: appDashboardUsersRequest });
          }
        },
      ),
      filter((action) => !!action),
    ),
  );

  /**
   * loadAppNetworkInsightsDashboard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppNetworkInsightsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppNetworkInsightsDashboard),
      withLatestFrom(this.store.select(NetworkInsightsSelectors.getStandardAppNetworkInsightsDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * loadAppErrorStacktraceThreads$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorStacktraceThreads$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorStacktraceThreads),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getAppErrorStacktracePreviewRequest)),
      switchMap(([_action, tablePreviewRequest]: [Action, PreviewReportContentRequest]) => {
        return this.reportMetaService.previewReportAny(tablePreviewRequest, true).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return AppsDashboardActions.loadAppErrorStacktraceThreadsSuccess({ response });
          }),
          catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorStacktraceThreadsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * downloadAppErrorStacktraceThreads$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public downloadAppErrorStacktraceThreads$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.downloadAppErrorStacktraceThreads),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getSelectedApp),
        this.store.select(AppLoadsSelectors.getSelectedAppPlatform),
        this.store.select(AppLoadsSelectors.getAppErrorDetailsGroupEntity),
        this.store.select(AppDeploymentDashboardSelectors.getAppErrorStacktracePreviewRequest),
      ),
      switchMap(
        ([action, selectedApp, platform, entity, tablePreviewRequest]: [
          ReturnType<typeof AppsDashboardActions.downloadAppErrorStacktraceThreads>,
          App,
          string,
          string,
          PreviewReportContentRequest,
        ]) => {
          const downloadAppErrorStacktraceRequest = getDownloadAppErrorStacktraceRequest(
            selectedApp,
            action.appCrashGroup,
            tablePreviewRequest,
            platform,
          );
          return this.reportMetaService.previewReportAny(downloadAppErrorStacktraceRequest, true).pipe(
            map((response: CustomReportPreviewSearchResponse) => {
              const fqnFunc = getFQNFunction(Integration.APTELIGENT, entity);
              const formattedTextOutput = this.formatDownloadAppErrorStacktrace(response.results[0], fqnFunc);
              this.downloadService.downloadTextFile(
                formattedTextOutput,
                `${response.results[0]?.[fqnFunc(COLUMN_NAMES.byName._error_name)]}.stacktrace.txt`,
              );
              return AppsDashboardActions.loadAppErrorStacktraceThreadsSuccess({ response });
            }),
            catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorStacktraceThreadsFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * downloadAppErrorStacktraceThreadsFromSearchResponse$
   * @type {Observable<void>}
   * @memberof AppsDashboardEffects
   */
  public downloadAppErrorStacktraceThreadsFromSearchResponse$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppsDashboardActions.downloadAppErrorStacktraceThreadsFromSearchResponse),
        withLatestFrom(
          this.store.select(AppsDashboardSelectors.getAppErrorStackTraceSearchResponse),
          this.store.select(AppLoadsSelectors.getAppErrorDetailsGroupEntity),
        ),
        map(([_props, response, entity]: [Action, CustomReportPreviewSearchResponse, string]) => {
          const fqnFunc = getFQNFunction(Integration.APTELIGENT, entity);
          const formattedTextOutput = this.formatDownloadAppErrorStacktrace(response.results[0], fqnFunc);
          this.downloadService.downloadTextFile(
            formattedTextOutput,
            `${response.results[0]?.[fqnFunc(COLUMN_NAMES.byName._error_name)]}.stacktrace.txt`,
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * setAppErrorBreadcrumbsVisible$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public setAppErrorBreadcrumbsVisible$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.setAppErrorBreadcrumbsVisible),
      filter(Boolean),
      map(() => AppsDashboardActions.loadAppErrorBreadcrumbs()),
    ),
  );

  /**
   * breadCrumbRequestChanged$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public breadCrumbRequestChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.setAppErrorBreadcrumbsPagedRequest, AppsDashboardActions.setAppErrorBreadcrumbsSortOns),
      withLatestFrom(this.store.select(AppsDashboardSelectors.getAppErrorBreadcrumbsVisible)),
      filter(([_action, isVisible]: [Action, boolean]) => isVisible),
      map(() => AppsDashboardActions.loadAppErrorBreadcrumbs()),
    ),
  );

  /**
   * loadAppErrorBreadcrumbs$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorBreadcrumbs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorBreadcrumbs),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getAppErrorBreadcrumbRequest)),
      switchMap(([_action, tablePreviewRequest]: [Action, PreviewReportContentRequest]) => {
        return this.reportMetaService.previewReportAny(tablePreviewRequest, true).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return AppsDashboardActions.loadAppErrorBreadcrumbsSuccess({ response });
          }),
          catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorBreadcrumbsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadAppErrorCrashTable$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorCrashTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorCrashTable),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getAppCrashGroupRequest)),
      switchMap(
        ([{ query }, appCrashGroupRequest]: [ReturnType<typeof AppsDashboardActions.loadAppErrorCrashTable>, AppCrashGroupRequest]) => {
          const appCrashGroupSearchRequest = query
            ? new AppCrashGroupRequest({
                ...appCrashGroupRequest,
                searchTerm: new SearchTerm({
                  value: query,
                  fields: [
                    COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_error_name,
                    COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_error_reason,
                    COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_stack_trace,
                  ],
                }),
              })
            : appCrashGroupRequest;
          return this.dashboardService.getAppErrorCrashTable(appCrashGroupSearchRequest).pipe(
            map((response: AppCrashGroupResponse) => AppsDashboardActions.loadAppErrorCrashTableSuccess({ response })),
            catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorCrashTableFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadAppErrorHeTable$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorHeTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorHeTable),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getAppHeGroupRequest)),
      switchMap(([_action, appHeGroupRequest]: [Action, AppHeGroupRequest]) => {
        return this.dashboardService.getAppErrorHeTable(appHeGroupRequest).pipe(
          map((response: AppHeGroupResponse) => AppsDashboardActions.loadAppErrorHeTableSuccess({ response })),
          catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorHeTableFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * downloadAppErrorCrashUpload$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public downloadAppErrorCrashUpload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.downloadAppErrorCrashUpload),
      switchMap(({ id }: ReturnType<typeof AppsDashboardActions.downloadAppErrorCrashUpload>) => {
        return this.dashboardService.downloadAppErrorCrashUpload(id).pipe(
          map(() => AppsDashboardActions.downloadAppErrorCrashUploadSuccess()),
          // This should never hit error
          // downloading a file doesn't return a response
          catchError((error: WebError) => of(AppsDashboardActions.downloadAppErrorCrashUploadFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * deleteAppErrorCrashUpload$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public deleteAppErrorCrashUpload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.deleteAppErrorCrashUpload),
      switchMap(({ id }: ReturnType<typeof AppsDashboardActions.deleteAppErrorCrashUpload>) => {
        return this.dashboardService.deleteAppErrorCrashUpload(id).pipe(
          map(() => AppsDashboardActions.deleteAppErrorCrashUploadSuccess()),
          catchError((error: WebError) => of(AppsDashboardActions.deleteAppErrorCrashUploadFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * appErrorCrashUploadsTableRequestChanged$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public appErrorCrashUploadsTableRequestChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppsDashboardActions.setAppErrorCrashUploadsTableFilterString,
        AppsDashboardActions.setAppErrorCrashUploadsTablePagedRequest,
        AppsDashboardActions.setAppErrorCrashUploadsTableSortOns,
        AppsDashboardActions.deleteAppErrorCrashUploadSuccess,
        AppsDashboardActions.uploadCrashMappingSuccess,
      ),
      map(AppsDashboardActions.loadAppErrorCrashUploadsTable),
    ),
  );

  /**
   * loadAppErrorCrashUsersTable$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadAppErrorCrashUploadsTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadAppErrorCrashUploadsTable),
      withLatestFrom(this.store.select(AppDeploymentDashboardSelectors.getAppErrorCrashUploadsTableRequest)),
      switchMap(([_action, request]: [Action, AppErrorCrashUploadsTableRequest]) => {
        return this.dashboardService.getAppErrorCrashUploadsTable(request).pipe(
          map((response: AppErrorCrashUploadsTableResponse) => AppsDashboardActions.loadAppErrorCrashUploadsTableSuccess({ response })),
          catchError((error: WebError) => of(AppsDashboardActions.loadAppErrorCrashUploadsTableFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * fetchAppHealthStatus$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public fetchAppHealthStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.fetchAppHealthStatus),
      switchMap(({ appHealthStatus }: ReturnType<typeof AppsDashboardActions.fetchAppHealthStatus>) => {
        const dateRange = appHealthStatus.range.getStartEndMillis();
        const request = this.fetchAppHealthStatusRequest(appHealthStatus, dateRange.startDateMillis, dateRange.endDateMillis);
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const hasResults = has(response, 'results') && response.results.length;
            const newStatus = hasResults
              ? response.results[0][getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, appHealthStatus.column)]
              : this.i18NService.translate('COMMON_MESSAGES.UNKNOWN_VALUE');
            return AppsDashboardActions.fetchAppHealthStatusSuccess({ status: newStatus });
          }),
          catchError(() => {
            return of(
              AppsDashboardActions.fetchAppHealthStatusFailure({
                status: this.i18NService.translate('COMMON_MESSAGES.UNKNOWN_VALUE'),
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * loadPluginExceptionEventCount$ by app
   *
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadPluginExceptionEventCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.loadPluginExceptionEventCount),
      switchMap((props: ReturnType<typeof AppLoadsActions.loadPluginExceptionEventCount>) => {
        const entitiesByIntegration = {
          [Integration.APTELIGENT]: [Entity.PLUGIN_EXCEPTION],
        };
        // eslint-disable-next-line max-len
        const filterPluginException = `${COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_app_id} = '${props.app.apteligentAppId}'`;
        const request = new PreviewReportContentRequest({
          entitiesByIntegration,
          filter: filterPluginException,
          offset: 0,
          pageSize: 10,
          fields: [COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_app_id],
          sortOns: [],
        });
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const hasResults = has(response, 'results') && response.total;
            return AppLoadsActions.loadPluginExceptionEventCountSuccess({
              pluginExceptionEventCountByApp: hasResults,
            });
          }),
          catchError(() => {
            return of(AppLoadsActions.loadPluginExceptionEventCountFailure());
          }),
        );
      }),
    ),
  );

  /**
   * setNetworkInsightsUrlsModalHostUrl$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public setNetworkInsightsUrlsModalHostUrl$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.setNetworkInsightsUrlsModalHostUrl),
      filter(Boolean),
      map(AppsDashboardActions.loadNetworkInsightsUrls),
    ),
  );

  /**
   * loadNetworkInsightsLog$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadNetworkInsightsLog$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadNetworkInsightsLog),
      withLatestFrom(
        this.store.select(NetworkInsightsSelectors.getNetworkInsightsLogSearchRequest),
        this.store.select(NetworkInsightsSelectors.getNetworkInsightsLogModalIsOpen),
        (action: Action, tablePreviewRequest: PreviewReportContentRequest, isOpen: boolean) => (isOpen ? tablePreviewRequest : false),
      ),
      filter(Boolean),
      switchMap((tablePreviewRequest: PreviewReportContentRequest) => {
        return this.reportMetaService.previewReportAny(tablePreviewRequest).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return AppsDashboardActions.loadNetworkInsightsLogSuccess({ response });
          }),
          catchError((error: WebError) => {
            return [
              AppsDashboardActions.loadNetworkInsightsLogFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18NService.translate('APTELIGENT.FAILED_TO_LOAD_NETWORK_INSIGHTS_LOGS'),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * goToDeemAppDetailsPage$
   * @type {Observable<DevicesDesktopErrorDetailsPage>}
   * @memberof AppsDashboardEffects
   */
  public goToDeemAppDetailsPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppsDashboardActions.goToDeemAppDetailsPage),
        tap(
          ({ deemAppDetailsPage, userDetailPageType, subDashboardId }: ReturnType<typeof AppsDashboardActions.goToDeemAppDetailsPage>) => {
            let deemAppDetailsUrl;
            if (userDetailPageType === UserDetailPageType.DEEM_USER) {
              const queryParams: Params = {
                [AppConfig.QUERY_PARAM_APP_NAME]: deemAppDetailsPage.name,
                [AppConfig.QUERY_PARAM_PAGE_TITLE]: deemAppDetailsPage.name,
                [AppConfig.QUERY_PARAM_BREAD_CRUMBS]: JSON.stringify(deemAppDetailsPage.breadCrumbs),
                [AppConfig.QUERY_PARAM_ENTITY]: deemAppDetailsPage.entity,
                [AppConfig.QUERY_PARAM_PLATFORM]: deemAppDetailsPage.platform,
              };
              if (deemAppDetailsPage.id) {
                queryParams[AppConfig.QUERY_PARAM_APP_ID] = deemAppDetailsPage.id;
              }
              deemAppDetailsUrl = this.routerExtensions.getNavigationUrl(
                [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_APP_DETAIL(subDashboardId, SubDashboardPlatform.ALL, deemAppDetailsPage.name)],
                {
                  queryParams,
                },
              );
            } else {
              deemAppDetailsUrl = this.routerExtensions.getNavigationUrl(
                [ROUTE_NAMES.DASHBOARD.APP_DEEM_DETAILS, deemAppDetailsPage.selectedTab || DeemAppDetailsPageTab.OVERVIEW],
                {
                  queryParams: {
                    breadCrumbs: JSON.stringify(deemAppDetailsPage.breadCrumbs || []),
                    name: deemAppDetailsPage.name,
                    platform: deemAppDetailsPage.platform,
                  },
                },
              );
            }
            return this.routerExtensions.navigateByUrl(deemAppDetailsUrl);
          },
        ),
      ),
    { dispatch: false },
  );

  /**
   * goToUserFlowsDetailFromCard$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public goToUserFlowsDetailFromCard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.goToUserFlowsDetailFromCard),
      withLatestFrom(
        this.store.select(AppDeploymentDashboardSelectors.getUserFlowsAllOccurencesByState),
        ({ userFlow, breadCrumbs }: ReturnType<typeof AppsDashboardActions.goToUserFlowsDetailFromCard>, allOccurrencesTrend: Trend) => {
          const userFlowNameFilterString = QueryBuilder.queryStringFromKeyValue({
            [COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_name]: userFlow.name,
          });
          const widgetDetailDefinition = new WidgetDetailDefinition({
            chartTitle: userFlow.name,
            returnCrumbs: breadCrumbs,
            trendDefinition: Object.assign(new TrendDefinition(), allOccurrencesTrend.trendDefinition, {
              filter: `${allOccurrencesTrend.trendDefinition.filter} AND ${userFlowNameFilterString}`,
              bucketingAttributes: [COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_state_name],
            }),
            chartType: AggregationWidgetChartType.VERTICAL,
            showSeriesNames: true,
            showTable: true,
            disableDrilldown: false,
            yAxisLabelOverride: '',
          });
          return {
            widgetDetailDefinition,
            drilldownEvents: [],
            skinType: WidgetDetailPageSkinType.USER_FLOWS,
          } as WidgetDetailPage;
        },
      ),
      map((widgetDetailPage: WidgetDetailPage) => DashboardActions.goToWidgetDetailPage(widgetDetailPage)),
    ),
  );

  /**
   * setNetworkInsightsLogModalIsOpen$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public setNetworkInsightsLogModalIsOpen$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppsDashboardActions.setNetworkInsightsLogModalIsOpen,
        AppsDashboardActions.setNetworkInsightsLogSelectedTabName,
        AppsDashboardActions.setNetworkInsightsLogSelectedSortOns,
        AppsDashboardActions.setNetworkLogsPagedRequest,
        AppsDashboardActions.setNetworkInsightsLogSearchQuery,
      ),
      mergeMap(() => {
        return [AlertBannerActions.dismissAlertBanner({ target: AlertBannerTarget.MODAL }), AppsDashboardActions.loadNetworkInsightsLog()];
      }),
    ),
  );

  /**
   * uploadCrashMapping$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public uploadCrashMapping$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.uploadCrashMapping),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getSelectedAppPlatform),
        this.store.select(AppLoadsSelectors.getSelectedApteligentId),
        this.store.select(AppsDashboardSelectors.getMappingUploadAppVersion),
      ),
      switchMap(
        ([{ files }, appPlatform, appId, appVersion]: [
          ReturnType<typeof AppsDashboardActions.uploadCrashMapping>,
          string,
          string,
          string,
        ]) => {
          if (appPlatform === AppPlatform.ANDROID) {
            return this.dashboardService.uploadAndroidCrashMapping(files, appId, appVersion).pipe(
              map(() => AppsDashboardActions.uploadCrashMappingSuccess()),
              catchError((error: WebError) => of(AppsDashboardActions.uploadCrashMappingFailure({ error }))),
            );
          } else if (appPlatform === AppPlatform.APPLE_IOS) {
            return this.dashboardService.uploadAppleCrashMapping(files, appId, appVersion).pipe(
              map(() => AppsDashboardActions.uploadCrashMappingSuccess()),
              catchError((error: WebError) => of(AppsDashboardActions.uploadCrashMappingFailure({ error }))),
            );
          }
        },
      ),
    ),
  );

  /**
   * loadNetworkInsightsLog$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadNetworkInsightsUrls$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadNetworkInsightsUrls, AppsDashboardActions.openNetworkInsightsHostUrlsModal),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getSelectedApteligentId),
        this.store.select(AppsDashboardSelectors.getNetworkInsightsUrlsModalHostUrl),
      ),
      filter(([_action, apteligentId, host]: [Action, string, string]) => Boolean(apteligentId && host)),
      switchMap(([_action, apteligentId, host]: [Action, string, string]) => {
        return this.dashboardService.getNetworkInsightsUrls(apteligentId, host).pipe(
          map((response: NetworkInsightsUrlResponse) => AppsDashboardActions.loadNetworkInsightsUrlsSuccess({ response })),
          catchError((error: WebError) => {
            return [
              AppsDashboardActions.loadNetworkInsightsUrlsFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18NService.translate('APTELIGENT.FAILED_TO_LOAD_NETWORK_INSIGHTS_URLS'),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * loadNetworkInsightsTable$
   * @type {Observable<Action>}
   * @memberof AppsDashboardEffects
   */
  public loadNetworkInsightsTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsDashboardActions.loadNetworkInsightsTable, AppsDashboardActions.setNetworkInsightsSubFilterRuleGroup),
      withLatestFrom(this.store.select(NetworkInsightsSelectors.getNetworkInsightsTableSearchRequest)),
      switchMap(([_action, request]: [Action, NetworkInsightsSearchRequest]) => {
        return this.dashboardService.getNetworkInsightsData(request).pipe(
          map((response: NetworkInsightsSearchResponse) => AppsDashboardActions.loadNetworkInsightsTableSuccess({ response })),
          catchError((error: WebError) => of(AppsDashboardActions.loadNetworkInsightsTableFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Creates an instance of AppsDashboardEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {ReportMetaService} reportMetaService
   * @param {DashboardService} dashboardService
   * @param {I18NService} i18NService
   * @param {DownloadService} downloadService
   * @param {RouterExtensions} routerExtensions
   * @memberof AppsDashboardEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private reportMetaService: ReportMetaService,
    private dashboardService: DashboardService,
    private i18NService: I18NService,
    private downloadService: DownloadService,
    private routerExtensions: RouterExtensions,
  ) {}

  /**
   * fetchAppHealthStatusRequest
   * @param {AppHealthStatus} appHealthStatus
   * @param {number} start
   * @param {number} end
   * @returns {PreviewReportContentRequest}
   * @memberof AppsDashboardEffects
   */
  public fetchAppHealthStatusRequest(appHealthStatus: AppHealthStatus, start: number, end: number): PreviewReportContentRequest {
    const filterString = QueryBuilder.queryStringFromKeyValue({
      [getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, appHealthStatus.filter)]: appHealthStatus.name,
      [getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, COLUMN_NAMES.byName.app_level_data)]: true,
      [getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, COLUMN_NAMES.byName._device_platform)]: appHealthStatus.platform,
    });
    return new PreviewReportContentRequest({
      filter: filterString,
      fields: [
        getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, COLUMN_NAMES.byName[appHealthStatus.column]),
        getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, COLUMN_NAMES.byName.event_timestamp),
      ],
      entitiesByIntegration: {
        [Integration.INTELLIGENCE]: [appHealthStatus.entity],
      },
      startDateMillis: start,
      endDateMillis: end,
      offset: 0,
      pageSize: 1,
      sortOns: [
        new SortOn({
          by: getFQN(Integration.INTELLIGENCE, appHealthStatus.entity, COLUMN_NAMES.byName.event_timestamp),
          reverse: true,
        }),
      ],
      trendMode: TrendMode.HISTORICAL,
    });
  }

  /**
   * formatDownloadAppErrorStacktrace
   * @param {GenericObject} stacktrace
   * @param {Function} fqnFunc
   * @returns {string}
   * @memberof AppsDashboardEffects
   */
  public formatDownloadAppErrorStacktrace(stacktrace: GenericObject, fqnFunc: Function): string {
    const formattedDatePipe: FormattedDatePipe = new FormattedDatePipe();
    const lineSeparator = '----------------------------------------';
    const newLine = '\n';
    const crashDetails = this.i18NService.translate('APTELIGENT.CRASH_DETAILS');
    const nameTitle = this.i18NService.translate('COMMON_MESSAGES.NAME');
    const reasonTitle = this.i18NService.translate('COMMON_MESSAGES.REASON');
    // INTEL-28940
    const nameValue = stacktrace[fqnFunc(COLUMN_NAMES.byName._error_name)] || stacktrace[fqnFunc(COLUMN_NAMES.byName.error_type)];
    const reasonValue = stacktrace[fqnFunc(COLUMN_NAMES.byName.error_reason)] || stacktrace[fqnFunc(COLUMN_NAMES.byName.error_code)];
    const appVersion = this.i18NService.translate('APTELIGENT.APP_VERSION');
    const lastOccurred = this.i18NService.translate('COMMON_MESSAGES.LAST_OCCURRED');
    const lastOccurredTimeFormatted = formattedDatePipe.transform(
      stacktrace[fqnFunc(COLUMN_NAMES.byName.event_timestamp)],
      DateTimeFormat.MOMENT_LONG_DATETIME_FORMAT,
    );
    const crashedThread = this.i18NService.translate('APTELIGENT.CRASHED_THREAD');
    let missingSymbolication = '';
    if (stacktrace[fqnFunc(COLUMN_NAMES.byName.partially_symbolicated)]) {
      const appCrashThread = new AppCrashThread({
        missingSymbolicationFiles: stacktrace[fqnFunc(COLUMN_NAMES.byName.missing_symbolication_files)],
      });
      const cleaned = appCrashThread.cleanedMissingSymbolicationFiles;
      missingSymbolication += `${newLine}`;
      missingSymbolication += this.i18NService.translate('APTELIGENT.MISSING_SYMBOL_FILES');
      missingSymbolication += `${newLine}${newLine}`;
      cleaned.forEach((lineItem: MissingSymbolicationFile) => {
        missingSymbolication += `${lineItem.fileName} - ${lineItem.uuid}${newLine}`;
      });
      missingSymbolication += `${newLine}${lineSeparator}${newLine}`;
    }
    let lineItems = '';
    const unsplitLines =
      stacktrace[fqnFunc(COLUMN_NAMES.byName.error_stacktrace_txt)] || stacktrace[fqnFunc(COLUMN_NAMES.byName.error_trace)];
    const lines = unsplitLines?.split(newLine) || stacktrace?.[fqnFunc(COLUMN_NAMES.byName.stacktrace_lines)] || [];
    lines.forEach((line: string, index: number) => {
      lineItems += `${index} ${line}${newLine}`;
    });
    return `
${crashDetails}
${lineSeparator}
${nameTitle}:
${nameValue}

${reasonTitle}:
${reasonValue}

${appVersion}:
${stacktrace[fqnFunc(COLUMN_NAMES.byName._app_version)]}

${lastOccurred}:
${lastOccurredTimeFormatted}
${lineSeparator}
${missingSymbolication}
${crashedThread}

${lineItems}`;
  }
}
