/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BreadCrumb, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { MerlotState, SecurityDashboardActions, SecurityDashboardSelectors } from '@dpa-shared-merlot/store';
import { DashboardActions, DashboardService, IntegrationMetaActions } from '@ws1c/intelligence-core';
import {
  AggregationWidgetChartType,
  Category,
  COLUMN_NAMES,
  Cve,
  CveDetailPageType,
  CveSearchRequest,
  CveSearchResponse,
  Entity,
  FilterRule,
  Integration,
  QueryBuilder,
  StandardDashboardRequest,
  Trend,
  TrendDefinition,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
} from '@ws1c/intelligence-models';

/**
 * SecurityDashboardEffects
 * @export
 * @class SecurityDashboardEffects
 */
@Injectable()
export class SecurityDashboardEffects {
  /**
   * loadSecurityDashboard$
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public loadSecurityDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.loadDashboard, SecurityDashboardActions.setDashboardFilters),
      withLatestFrom(this.store.select(SecurityDashboardSelectors.getStandardDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * loadSecurityVulnerabilitiesMacOSDashboard$
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public loadSecurityVulnerabilitiesMacOSDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.loadVulnerabilitiesMacOsDashboard, SecurityDashboardActions.setVulnerabilitiesDashboardFilters),
      withLatestFrom(this.store.select(SecurityDashboardSelectors.getVulnerabilitiesMacOsStandardDashboardRequest)),
      switchMap(([action, request]: [Action, StandardDashboardRequest]) => {
        const category = Category.fromEntityIntegrationNames(Integration.AIRWATCH, Entity.DEVICE);
        return [
          DashboardActions.loadStandardDashboard({
            request,
            isCrossCategory: true,
            preferenceDataNeeded: action.type === SecurityDashboardActions.loadVulnerabilitiesMacOsDashboard.type,
          }),
          IntegrationMetaActions.loadColumns({
            categoryId: category.categoryId,
            isCrossCategory: true,
          }),
        ];
      }),
    ),
  );

  /**
   * loadCveTable$
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public loadCveTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.loadCveTable),
      switchMap(({ request }: ReturnType<typeof SecurityDashboardActions.loadCveTable>) => this.dashboardService.getCveSearchData(request)),
      map((response: CveSearchResponse) => SecurityDashboardActions.loadCveTableSuccess({ response })),
      catchError((error: WebError) => of(SecurityDashboardActions.loadCveTableFailure({ error }))),
    ),
  );

  /**
   * sortCveTable$
   *
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public updateCveTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.sortCveTable, SecurityDashboardActions.searchCveTable),
      withLatestFrom(
        this.store.select(SecurityDashboardSelectors.getCveTableSearchRequest),
        (action: Action, request: CveSearchRequest) => request,
      ),
      map((request: CveSearchRequest) => SecurityDashboardActions.loadCveTable({ request })),
    ),
  );

  /**
   * augmentCveData$
   * High impact Cves come from standard dashboard calls
   * They are missing fields like score and kbIds
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public augmentCveData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadStandardDashboardDataSuccess),
      withLatestFrom(this.store.select(SecurityDashboardSelectors.getCveIdsMissingData), (action: Action, cveIds: string[]) => cveIds),
      filter((cveIds: string[]) => Boolean(cveIds && cveIds.length)),
      map((cveIds: string[]) => {
        const request = Object.assign(new CveSearchRequest(), {
          cveIdFilter: cveIds,
        });
        return SecurityDashboardActions.loadCveSearch({ request });
      }),
    ),
  );

  /**
   * loadCveSearch$
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public loadCveSearch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.loadCveSearch),
      switchMap(({ request }: ReturnType<typeof SecurityDashboardActions.loadCveSearch>) => {
        return this.dashboardService
          .getCveSearchData(request)
          .pipe(map((response: CveSearchResponse) => SecurityDashboardActions.loadCveSearchSuccess({ response })));
      }),
    ),
  );

  /**
   * goToCveDetailPage$
   * This uses the Top Vulnerabilites TrendDefinition for default values
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public goToCveDetailPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityDashboardActions.goToCveDetailPage),
      withLatestFrom(
        this.store.select(SecurityDashboardSelectors.getHistoricalTopVulnerabilites),
        this.store.select(SecurityDashboardSelectors.getHistoricalTopVulnerabilitesMacOs),
        ({ detailPage }: ReturnType<typeof SecurityDashboardActions.goToCveDetailPage>, cveTrendWindows: Trend, cveTrendMacOS: Trend) => {
          const detailsReturnCrumbs: BreadCrumb[] = detailPage.detailsReturnCrumbs;
          const cve: Cve = detailPage.cve;
          let cveIdFilter: string;
          let trendDefinition: TrendDefinition;
          if (detailPage.cveDetailPageType === CveDetailPageType.WINDOWS_CVE) {
            cveIdFilter = QueryBuilder.getRuleString(
              new FilterRule({
                attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_cve_id_list,
                condition: FilterRule.FILTER_CONDITION.contains,
                data: cve.id,
              }),
            );
            trendDefinition = cveTrendWindows.trendDefinition;
          } else if (detailPage.cveDetailPageType === CveDetailPageType.MACOS_CVE) {
            cveIdFilter = QueryBuilder.getRuleString(
              new FilterRule({
                attribute: COLUMN_NAMES.byFullyQualifiedName.internal_vulnerability_summary_cve_id,
                condition: FilterRule.FILTER_CONDITION.equals,
                data: cve.id,
              }),
            );
            trendDefinition = cveTrendMacOS.trendDefinition;
          }
          const alteredTrendDefinition = Object.assign(new TrendDefinition(), trendDefinition);
          alteredTrendDefinition.filter = [alteredTrendDefinition.filter, cveIdFilter]
            .filter(Boolean)
            .join(FilterRule.FILTER_CONDITION.and);
          const firstCrumb = detailsReturnCrumbs[0];
          const dashboardName = firstCrumb && firstCrumb.pathLabelKey;
          const widgetDetailDefinition = Object.assign(new WidgetDetailDefinition(), {
            chartTitle: cve.id,
            dashboardName,
            trendDefinition: alteredTrendDefinition,
            returnCrumbs: detailsReturnCrumbs,
            chartType: AggregationWidgetChartType.VERTICAL,
            showSeriesNames: true,
            showTable: true,
          });

          return {
            widgetDetailDefinition,
            drilldownEvents: [],
            skinType: WidgetDetailPageSkinType.CVE,
          } as WidgetDetailPage;
        },
      ),
      map((cveDetailPage: WidgetDetailPage) => DashboardActions.goToWidgetDetailPage(cveDetailPage)),
    ),
  );

  /**
   * loadWidgetDetailCve$
   * Loads CVE data for widget detail page
   * Needs to wait for ruleGroup returned from preview call
   * @type {Observable<Action>}
   * @memberof SecurityDashboardEffects
   */
  public loadWidgetDetailCve$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDetailSuccess),
      withLatestFrom(
        this.store.select(SecurityDashboardSelectors.getWidgetDetailCveId),
        this.store.select(SecurityDashboardSelectors.getCvesById),
        (action: Action, cveId: string, cveById) => (cveById[cveId] ? null : cveId),
      ),
      filter(Boolean),
      map((cveId: string) => {
        const request = Object.assign(new CveSearchRequest(), {
          cveIdFilter: [cveId],
        });
        return SecurityDashboardActions.loadCveSearch({ request });
      }),
    ),
  );

  /**
   * Creates an instance of SecurityDashboardEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {DashboardService} dashboardService
   * @memberof SecurityDashboardEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
