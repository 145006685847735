/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { Ws1BrokerAllDirectories } from '@ws1c/intelligence-models';

/**
 * Provide access to WS1 Broker API data
 * @export
 * @class WS1BrokerService
 */
@Injectable()
export class WS1BrokerService {
  /**
   * Creates an instance of WS1BrokerService.
   * @param {HttpService} http
   * @memberof WS1BrokerService
   */
  constructor(private http: HttpService) {}

  /**
   * Get broker directories
   * @param {string} integrationId
   * @returns {Observable<Ws1BrokerAllDirectories>}
   * @memberof WS1BrokerService
   */
  public getBrokerDirectories(integrationId: string): Observable<Ws1BrokerAllDirectories> {
    return this.http.get(Endpoint.WS1_BROKER_DIRECTORIES(integrationId)).pipe(
      map((response: GenericObject) => deserialize(Ws1BrokerAllDirectories, response)),
      catchError(requestErrorHandler),
    );
  }
}
