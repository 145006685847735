/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AirwatchAppSearchResponse, EmailRequest, SuggestedAppsList } from '@dpa-shared-merlot/model';
import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { AppRegistration, AppSearchRequest, RegisteredAppSearchResponse, RegisteredAppsList } from '@ws1c/intelligence-models';

/**
 * Provide access to App Loads API data
 * @export
 * @class AppLoadsService
 */
@Injectable()
export class AppLoadsService {
  /**
   * Creates an instance of AppLoadsService.
   * @param {HttpService} http
   * @memberof AppLoadsService
   */
  constructor(private http: HttpService) {}

  /**
   * searchApps
   * @returns {Observable<AppSearchResponse>}
   * @param {string} appSearchQuery
   */
  public searchApps(appSearchQuery: string) {
    const options = {
      params: {
        search_term: appSearchQuery,
      },
    };
    return this.http.get(Endpoint.APPS_SEARCH, options).pipe(
      map((response: any) => deserialize(AirwatchAppSearchResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * get suggested apps for AppLoads from /suggest endpoint
   * @param {string} searchTerm
   * @returns {Observable<AppSearchResponse>}
   * @memberof AppLoadsService
   */
  public getSuggestedApps(searchTerm: string): Observable<SuggestedAppsList | any> {
    return this.http.get(Endpoint.APP_LOADS_APP_SUGGESTIONS(searchTerm)).pipe(
      map((response: any) => deserialize(SuggestedAppsList, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * save optout data
   * @param {any[]}selectedApps
   * @returns {Observable<App>}
   * @memberof AppLoadsService
   */
  public saveAppRegistrations(selectedApps: any[]): Observable<RegisteredAppsList | any> {
    return this.http.post(Endpoint.APP_REGISTRATIONS, selectedApps).pipe(
      map((response: any) => {
        return deserialize(RegisteredAppsList, response);
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Send email to the recipients.
   * @param {string} appId
   * @param {EmailRequest} emailRequest
   * @returns {Observable<App>}
   * @memberof AppLoadsService
   */
  public sendEmail(appId: string, emailRequest: EmailRequest): Observable<boolean | any> {
    return this.http.post(Endpoint.APP_LOADS_SEND_EMAIL(appId), { details: emailRequest }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * get all the registered Apps from Apteligent
   * @param {AppSearchRequest} searchRequest
   * @returns {Observable<RegisteredAppSearchResponse>}
   * @memberof AppLoadsService
   */
  public getAllRegisteredApps(searchRequest: AppSearchRequest): Observable<RegisteredAppSearchResponse | any> {
    return this.http.post(Endpoint.REGISTERED_APPS, searchRequest).pipe(
      map((response: any) => deserialize(RegisteredAppSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * get all the productivity Apps
   * @param {AppSearchRequest} searchRequest
   * @returns {Observable<RegisteredAppsList>}
   * @memberof AppLoadsService
   */
  public getAllProductivityApps(searchRequest: AppSearchRequest): Observable<RegisteredAppsList> {
    return this.http.get(Endpoint.PRODUCTIVITY_APPS, searchRequest).pipe(
      map((response: any) => deserialize(RegisteredAppsList, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * get all the non-deployed apps
   * @returns {Observable<AppRegistration[]>}
   * @memberof AppLoadsService
   */
  public getNonDeployedApps(): Observable<AppRegistration[]> {
    return this.http.get(Endpoint.NON_DEPLOYED_APPS).pipe(
      map((response: any) => response.data.map((item) => deserialize(AppRegistration, item))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Delete registered apps
   * @param {string[]} appIds
   * @returns {Observable<boolean>}
   * @memberof AppLoadsService
   */
  public deleteRegisteredApps(appIds: string[]): Observable<boolean> {
    return this.http.put(Endpoint.APP_REGISTRATIONS_DEACTIVATE, appIds).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
