/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { UagTunnelDashboardActions, UagTunnelDashboardSelectors } from '@dpa-shared-merlot/store';
import { DashboardActions } from '@ws1c/intelligence-core';
import { StandardDashboardRequest } from '@ws1c/intelligence-models';

/**
 * UagTunnelDashboardEffects
 * @export
 * @class UagTunnelDashboardEffects
 */
@Injectable()
export class UagTunnelDashboardEffects {
  /**
   * loadDashboard$
   * @type {Observable<Action>}
   * @memberof UagTunnelDashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UagTunnelDashboardActions.loadDashboard, UagTunnelDashboardActions.setTrendDateRange),
      withLatestFrom(this.store.select(UagTunnelDashboardSelectors.getDashboardRequest)),
      switchMap(([_action, request]: [Action, StandardDashboardRequest]) => {
        return [DashboardActions.loadStandardDashboard({ request })];
      }),
    ),
  );

  /**
   * Creates an instance of UagTunnelDashboardEffects.
   * @param {Actions} actions$
   * @param {Store} store
   * @memberof UagTunnelDashboardEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}
}
