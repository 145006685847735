/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable, NgZone } from '@angular/core';
import { each, pull } from 'lodash-es';

/**
 * Creates a single requestAnimationFrame loop for entire app
 * @export
 * @class RequestAnimationFrameService
 */
@Injectable()
export class RequestAnimationFrameService {
  public lambdas: Array<() => void> = [];
  public interval: number;
  public requestAnimationFrame = window.requestAnimationFrame;
  public cancelAnimationFrame = window.cancelAnimationFrame;

  /**
   * @param {NgZone} zone
   * @memberof RequestAnimationFrameService
   */
  constructor(private zone: NgZone) {}

  /**
   * Adds a lambda that will run on each step
   * @param {() => void} lambda
   * @memberof RequestAnimationFrameService
   */
  public addLambda(lambda: () => void) {
    this.lambdas.push(lambda);
    if (this.lambdas.length === 1) {
      this.zone.runOutsideAngular(() => {
        this.step();
      });
    }
  }

  /**
   * Remove a lambda that runs on each step
   * @param {() => void} lambda
   * @memberof RequestAnimationFrameService
   */
  public removeLambda(lambda: () => void) {
    pull(this.lambdas, lambda);
    if (this.lambdas.length === 0) {
      this.cancelAnimationFrame(this.interval);
    }
  }

  /**
   * Runs lambdas
   * @memberof RequestAnimationFrameService
   */
  public step() {
    each(this.lambdas, (lambda) => lambda());
    this.interval = this.requestAnimationFrame(() => this.step());
  }
}
