/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { CustomReport, GenericSearchRequest, ReportTrackingSearchResponse } from '@ws1c/intelligence-models';

/**
 * Provides REST Interface for All Non Admin API's
 *
 * @export
 * @class NonAdminService
 */
@Injectable()
export class NonAdminService {
  /**
   * Creates an instance of NonAdminService.
   * @param {HttpService} httpService
   * @memberof NonAdminService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Get list of report share data
   * @param {string} shareId
   * @param {string} reportId
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<ReportTrackingSearchResponse>}
   * @memberof NonAdminService
   */
  public getReportShareList(
    shareId: string,
    reportId: string,
    searchRequest: GenericSearchRequest,
  ): Observable<ReportTrackingSearchResponse> {
    return this.httpService.post(Endpoint.REPORT_SHARE_LIST(shareId, reportId), searchRequest).pipe(
      map((response: any) => deserialize(ReportTrackingSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get report details
   * @param {string} shareId
   * @param {string} reportId
   * @returns {Observable<CustomReport>}
   * @memberof NonAdminService
   */
  public getReportShareDetails(shareId: string, reportId: string): Observable<CustomReport> {
    return this.httpService.get(Endpoint.REPORT_SHARE_ID(shareId, reportId)).pipe(
      map((response: any) => deserialize(CustomReport, response.data)),
      catchError(requestErrorHandler),
    );
  }
}
