/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { MerlotState, SystemLimitsActions, SystemLimitsSelectors } from '@dpa-shared-merlot/store';
import { I18NService } from '@ws1c/intelligence-common';
import {
  AccountService,
  AlertBannerActions,
  AutomationService,
  CustomReportService,
  DashboardService,
  SystemLimitsService,
  UserPreferenceCommonSelectors,
} from '@ws1c/intelligence-core';
import {
  Account,
  AccountUsageDetails,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AutomationSearchRequest,
  AutomationSearchResponse,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  DashboardSearchRequest,
  DashboardSearchResponse,
  GenericSearchRequest,
  IdentifiersRequest,
  Org,
  ServiceLimits,
  SystemLimitsFeatureTag,
  SystemLimitsSearchResponse,
  SystemLimitsUsageDetails,
} from '@ws1c/intelligence-models';
import { helper } from './system-limits.effects.helpers';

/**
 * Handles side effects for System Limits actions
 * @export
 * @class SystemLimitsEffects
 */
@Injectable()
export class SystemLimitsEffects {
  /**
   * loadUsageSummary$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUsageSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadUsageSummary),
      withLatestFrom(this.store.select(UserPreferenceCommonSelectors.getOrgInfo)),
      switchMap(([_action, org]: [Action, Org]) => {
        return this.systemLimitsService.getUsageSummary(org.orgId).pipe(
          switchMap((usageDetails: SystemLimitsUsageDetails[]) => {
            return [SystemLimitsActions.loadUsageSummarySuccess({ usageDetails })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUsageSummaryFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.LOAD_USAGE_SUMMARY_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadSystemLimits$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadSystemLimits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemLimitsActions.loadSystemLimits,
        SystemLimitsActions.refreshSystemLimits,
        SystemLimitsActions.changeSystemLimitsPagination,
        SystemLimitsActions.sortSystemLimits,
        SystemLimitsActions.filterSystemLimits,
      ),
      withLatestFrom(
        this.store.select(SystemLimitsSelectors.getActiveTab),
        this.store.select(SystemLimitsSelectors.getSystemLimitsSearchRequest),
      ),
      switchMap(([_action, systemLimitsFeatureTag, systemLimitsSearchRequest]: [Action, SystemLimitsFeatureTag, GenericSearchRequest]) => {
        return this.systemLimitsService.getSystemLimits(systemLimitsFeatureTag, systemLimitsSearchRequest).pipe(
          switchMap((systemLimitsSearchResponse: SystemLimitsSearchResponse) => {
            return [SystemLimitsActions.loadSystemLimitsSuccess({ systemLimitsSearchResponse })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadSystemLimitsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.LOAD_SYSTEM_LIMITS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadServiceLimits$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadServiceLimits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadServiceLimits),
      switchMap(() => {
        return this.systemLimitsService.getServiceLimits().pipe(
          switchMap((serviceLimits: ServiceLimits[]) => {
            return [SystemLimitsActions.loadServiceLimitsSuccess({ serviceLimits })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadServiceLimitsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.SYSTEM_LIMITS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * saveServiceLimits$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public saveServiceLimits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.saveServiceLimits),
      switchMap(({ serviceLimitsForUpdate, serviceLimitsForDelete }: ReturnType<typeof SystemLimitsActions.saveServiceLimits>) => {
        const serviceCalls: Array<Observable<boolean>> = [];
        if (Object.keys(serviceLimitsForUpdate).length !== 0) {
          serviceCalls.push(this.systemLimitsService.updateServiceLimits(serviceLimitsForUpdate));
        }
        if (serviceLimitsForDelete.length !== 0) {
          serviceCalls.push(this.systemLimitsService.deleteServiceLimits(serviceLimitsForDelete));
        }
        return forkJoin(serviceCalls).pipe(
          switchMap(() => {
            return [
              SystemLimitsActions.closeSystemLimitsModal(),
              SystemLimitsActions.refreshSystemLimits(),
              SystemLimitsActions.loadServiceLimits(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                target: AlertBannerTarget.SECTION,
                message: this.i18nService.translate('SYSTEM_LIMITS.DEFAULT_USER_LIMITS_SAVE_SUCCESS'),
              }),
            ];
          }),
          catchError(() => {
            return [
              SystemLimitsActions.closeSystemLimitsModal(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('SYSTEM_LIMITS.DEFAULT_USER_LIMITS_SAVE_FAILURE'),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * getAccountDetails$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public getAccountDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.getSelectedAccountDetails),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(([_action, selectedAccountId]: [Action, string]) => {
        return this.accountService
          .getAccountsById(
            new IdentifiersRequest({
              identifiers: [selectedAccountId],
            }),
          )
          .pipe(
            switchMap((accounts: Account[]) => {
              return [SystemLimitsActions.getSelectedAccountDetailsSuccess({ selectedAccount: accounts[0] })];
            }),
            catchError((error: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('SYSTEM_LIMITS.ACCOUNT_DETAILS_FAILRE', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          );
      }),
    ),
  );

  /**
   * loadUserAutomationsUsageSummary$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserAutomationsUsageSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadUserAutomationsUsageSummary),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(([_action, accountId]: [Action, string]) => {
        return this.systemLimitsService.getUserAutomationsSummary(accountId).pipe(
          switchMap((automationsUsageSummary: AccountUsageDetails) => {
            return [SystemLimitsActions.loadUserAutomationsUsageSummarySuccess({ automationsUsageSummary })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserAutomationsUsageSummaryFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.SYSTEM_LIMITS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUserCustomReportsUsageSummary$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserCustomReportsUsageSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadUserCustomReportsUsageSummary),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(([_action, accountId]: [Action, string]) => {
        return this.systemLimitsService.getUserCustomReportsSummary(accountId).pipe(
          switchMap((customReportsUsageSummary: AccountUsageDetails) => {
            return [SystemLimitsActions.loadUserCustomReportsUsageSummarySuccess({ customReportsUsageSummary })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserCustomReportsUsageSummaryFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.SYSTEM_LIMITS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUserDashboardsUsageSummary$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserDashboardsUsageSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadUserDashboardsUsageSummary),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(([_action, accountId]: [Action, string]) => {
        return this.systemLimitsService.getUserDashboardsSummary(accountId).pipe(
          switchMap((dashboardsUsageSummary: AccountUsageDetails) => {
            return [SystemLimitsActions.loadUserDashboardsUsageSummarySuccess({ dashboardsUsageSummary })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserDashboardsUsageSummaryFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.SYSTEM_LIMITS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUserServiceLimits$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserServiceLimits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.loadUserServiceLimits),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(([_action, selectedAccountId]: [Action, string]) => {
        return this.systemLimitsService
          .getUserServiceLimits(
            new IdentifiersRequest({
              identifiers: [selectedAccountId],
            }),
          )
          .pipe(
            switchMap((serviceLimits: ServiceLimits[]) => {
              return [SystemLimitsActions.loadUserServiceLimitsSuccess({ serviceLimits })];
            }),
            catchError((error: WebError) => [
              SystemLimitsActions.loadUserServiceLimitsFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('SYSTEM_LIMITS.SYSTEM_LIMITS_ERROR', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          );
      }),
    ),
  );

  /**
   * loadUserAutomations$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserAutomations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemLimitsActions.loadUserAutomations,
        SystemLimitsActions.refreshUserAutomations,
        SystemLimitsActions.changeUserAutomationsPagination,
        SystemLimitsActions.sortUserAutomations,
      ),
      withLatestFrom(
        this.store.select(SystemLimitsSelectors.getSelectedAccountId),
        this.store.select(SystemLimitsSelectors.getUserAutomationSearchRequest),
      ),
      switchMap(([_action, selectedAccountId, automationSearchRequest]: [Action, string, AutomationSearchRequest]) => {
        const searchRequest: AutomationSearchRequest = helper.getUserAutomationsSearchRequest(selectedAccountId, automationSearchRequest);
        return this.automationService.getAutomations(searchRequest).pipe(
          switchMap((automationSearchResponse: AutomationSearchResponse) => {
            return [SystemLimitsActions.loadUserAutomationsSuccess({ automationSearchResponse })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserAutomationsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.LOAD_AUTOMATIONS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUserCustomReports$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserCustomReports$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemLimitsActions.loadUserCustomReports,
        SystemLimitsActions.refreshUserCustomReports,
        SystemLimitsActions.changeUserCustomReportsPagination,
        SystemLimitsActions.sortUserCustomReports,
      ),
      withLatestFrom(
        this.store.select(SystemLimitsSelectors.getSelectedAccountId),
        this.store.select(SystemLimitsSelectors.getUserCustomReportSearchRequest),
      ),
      switchMap(([_action, selectedAccountId, customReportSearchRequest]: [Action, string, CustomReportSearchRequest]) => {
        const searchRequest: CustomReportSearchRequest = helper.getUserCustomReportsSearchRequest(
          selectedAccountId,
          customReportSearchRequest,
        );
        return this.customReportService.getCustomReports(searchRequest).pipe(
          switchMap((customReportSearchResponse: CustomReportSearchResponse) => {
            return [SystemLimitsActions.loadUserCustomReportsSuccess({ customReportSearchResponse })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserCustomReportsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.LOAD_CUSTOM_REPORTS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUserDashboards$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public loadUserDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemLimitsActions.loadUserDashboards,
        SystemLimitsActions.refreshUserDashboards,
        SystemLimitsActions.changeUserDashboardsPagination,
        SystemLimitsActions.sortUserDashboards,
      ),
      withLatestFrom(
        this.store.select(SystemLimitsSelectors.getSelectedAccountId),
        this.store.select(SystemLimitsSelectors.getUserDashboardSearchRequest),
      ),
      switchMap(([_action, selectedAccountId, dashboardSearchRequest]: [Action, string, DashboardSearchRequest]) => {
        const searchRequest: DashboardSearchRequest = helper.getUserDashboardsSearchRequest(selectedAccountId, dashboardSearchRequest);
        return this.dashboardService.searchDashboards(searchRequest).pipe(
          switchMap((dashboardSearchResponse: DashboardSearchResponse) => {
            return [SystemLimitsActions.loadUserDashboardsSuccess({ dashboardSearchResponse })];
          }),
          catchError((error: WebError) => [
            SystemLimitsActions.loadUserDashboardsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('SYSTEM_LIMITS.LOAD_DASHBOARDS_ERROR', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * saveUserServiceLimits$
   * @type {Observable<Action>}
   * @memberof SystemLimitsEffects
   */
  public saveUserServiceLimits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemLimitsActions.saveUserServiceLimits),
      withLatestFrom(this.store.select(SystemLimitsSelectors.getSelectedAccountId)),
      switchMap(
        ([{ userServiceLimitsForUpdate, userServiceLimitsForDelete }, accountId]: [
          ReturnType<typeof SystemLimitsActions.saveUserServiceLimits>,
          string,
        ]) => {
          const serviceCalls: Array<Observable<boolean>> = [];
          if (Object.keys(userServiceLimitsForUpdate).length !== 0) {
            serviceCalls.push(this.systemLimitsService.updateUserServiceLimits(accountId, userServiceLimitsForUpdate));
          }
          if (userServiceLimitsForDelete.length !== 0) {
            serviceCalls.push(this.systemLimitsService.deleteUserServiceLimits(accountId, userServiceLimitsForDelete));
          }
          return forkJoin(serviceCalls).pipe(
            switchMap(() => {
              return [
                SystemLimitsActions.closeUserSystemLimitsModal(),
                SystemLimitsActions.loadUserServiceLimits(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('SYSTEM_LIMITS.USER_SERVICE_LIMITS_SUCCESS'),
                }),
              ];
            }),
            catchError(() => {
              return [
                SystemLimitsActions.closeUserSystemLimitsModal(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('SYSTEM_LIMITS.USER_SERVICE_LIMITS_FAILURE'),
                }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * Creates an instance of SystemLimitsEffects.
   *
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {AccountService} accountService
   * @param {DashboardService} dashboardService
   * @param {AutomationService} automationService
   * @param {CustomReportService} customReportService
   * @param {SystemLimitsService} systemLimitsService
   * @memberof SystemLimitsEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private i18nService: I18NService,
    private accountService: AccountService,
    private dashboardService: DashboardService,
    private automationService: AutomationService,
    private customReportService: CustomReportService,
    private systemLimitsService: SystemLimitsService,
  ) {}
}
