/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import {
  AccountGroup,
  AccountGroupRolesRequest,
  AccountGroupSearchResponse,
  GenericSearchRequest,
  IdentifiersRequest,
  IntegrationGroup,
  IntegrationGroupSearchRequest,
} from '@ws1c/intelligence-models';

/**
 * Provides REST Interface for Accounts Group API's
 *
 * @export
 * @class AccountGroupService
 */
@Injectable()
export class AccountGroupService {
  /**
   * Creates an instance of AccountGroupService.
   * @param {HttpService} httpService
   * @memberof AccountGroupService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Get list of accountGroup groups
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<AccountGroupSearchResponse>}
   * @memberof AccountGroupService
   */
  public getAccountGroups(searchRequest: GenericSearchRequest): Observable<AccountGroupSearchResponse> {
    return this.httpService.post(Endpoint.DIRECTORY_GROUP_SEARCH, searchRequest).pipe(
      map((response: any) => deserialize(AccountGroupSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountsGroupsWithAssignment
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<AccountGroupSearchResponse>}
   * @memberof AccountGroupService
   */
  public getAccountsGroupsWithAssignment(searchRequest: GenericSearchRequest): Observable<AccountGroupSearchResponse> {
    return this.getAccountGroups(searchRequest).pipe(
      mergeMap((accountGroupSearchResponse: AccountGroupSearchResponse) => {
        if (isEmpty(accountGroupSearchResponse?.results)) {
          return of(accountGroupSearchResponse);
        }
        return this.getAccountGroupAssignmentsByIds(
          new IdentifiersRequest({
            identifiers: accountGroupSearchResponse.results.map((accountGroup: AccountGroup) => accountGroup.id),
          }),
        ).pipe(
          map((accountGroupsWithAssignment: AccountGroup[]) => {
            accountGroupSearchResponse.results.forEach((accountGroup: AccountGroup) => {
              const accountGroupWithAssignment = accountGroupsWithAssignment.find((ag: AccountGroup) => ag.id === accountGroup.id);
              accountGroup.roles = accountGroupWithAssignment.roles;
            });
            return accountGroupSearchResponse;
          }),
          catchError(requestErrorHandler),
        );
      }),
    );
  }

  /**
   * Delete accountGroup group
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof AccountGroupService
   */
  public deleteAccountGroup(id: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.DIRECTORY_GROUP_ID(id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * delete accountGroup Groups
   * @param {string[]} identifiers
   * @returns {Observable<boolean>}
   * @memberof AccountGroupService
   */
  public deleteAccountGroups(identifiers: string[]): Observable<boolean> {
    return this.httpService.delete(Endpoint.DIRECTORY_GROUP, { body: { identifiers } }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getIntegrationGroups
   * @param {IntegrationGroupSearchRequest} request
   * @returns {Observable<IntegrationGroup[]>}
   * @memberof AccountGroupService
   */
  public getIntegrationGroups(request: IntegrationGroupSearchRequest): Observable<IntegrationGroup[]> {
    return this.httpService.post(Endpoint.GROUP_SEARCH(request.searchTerm), {}).pipe(
      map((response) => {
        return response.data.map((accountGroup) => deserialize(IntegrationGroup, accountGroup));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * addGroup
   * @param {AccountGroupRolesRequest} request
   * @returns {Observable<boolean>}
   * @memberof AccountGroupService
   */
  public addGroup(request: AccountGroupRolesRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.ACCOUNT_GROUP, request).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateGroup
   * @param {AccountGroup} accountGroup
   * @returns {Observable<boolean>}
   * @memberof AccountGroupService
   */
  public updateGroup(accountGroup: AccountGroup): Observable<boolean> {
    return this.httpService.put(Endpoint.ACCOUNT_GROUP_ID_ROLES(accountGroup.id), accountGroup.roleIds).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateGroupStatus
   * @param {AccountGroup} accountGroup
   * @returns {Observable<boolean>}
   * @memberof AccountGroupService
   */
  public updateGroupStatus(accountGroup: AccountGroup): Observable<boolean> {
    return this.httpService.put(Endpoint.DIRECTORY_GROUP_ID_STATUS(accountGroup.id, accountGroup.active), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountGroupByDirectoryGroupId
   *
   * @param {string} directoryGroupId
   * @param {string} directoryType
   * @returns {Observable<AccountGroup>}
   * @memberof AccountGroupService
   */
  public getAccountGroupByDirectoryGroupId(directoryGroupId: string, directoryType: string): Observable<AccountGroup> {
    return this.httpService.get(Endpoint.DIRECTORY_GROUP_BY_GROUP_ID(directoryGroupId, directoryType)).pipe(
      map((response) => deserialize(AccountGroup, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountGroupAssignmentsByIds
   * @param {IdentifiersRequest} accountGroupIdentifiersRequest
   * @returns {Observable<AccountGroup[]>}
   * @memberof AccountGroupService
   */
  private getAccountGroupAssignmentsByIds(accountGroupIdentifiersRequest: IdentifiersRequest): Observable<AccountGroup[]> {
    return this.httpService.post(Endpoint.ACCOUNT_GROUP_ROLES, accountGroupIdentifiersRequest).pipe(
      map((response: GenericObject) => response.data.map((accountGroup: GenericObject) => deserialize(AccountGroup, accountGroup))),
      catchError(requestErrorHandler),
    );
  }
}
