/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { omit, pick } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ConnectionSetupRequest,
  EventForwardersTemplatesResponse,
  EventForwarderTemplate,
  PartnerConnectionSetupRequest,
  ServiceConfigTemplate,
  ServiceConfigTemplateResponse,
} from '@dpa-shared-merlot/model';
import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';
import {
  AvailableEventForwarder,
  AvailableEventForwarderWrapper,
  AvailablePartner,
  AvailableService,
  AvailableServiceTenant,
  Connection,
  ConnectionConfig,
} from '@ws1c/intelligence-models';

/**
 * ConnectionService
 * @export
 * @class ConnectionService
 */
@Injectable()
export class ConnectionService {
  /**
   * @param {HttpService} httpService
   * @memberof ConnectionService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getServiceConfigTemplates
   * @returns {Observable<ServiceConfigTemplate[]>}
   * @memberof ConnectionService
   */
  public getServiceConfigTemplates(): Observable<ServiceConfigTemplate[]> {
    return this.httpService.get(Endpoint.AUTOMATION_SERVICE_TEMPLATES).pipe(
      map((response: GenericResponse) => deserialize(ServiceConfigTemplateResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * @param {ConnectionSetupRequest} connectionSetupRequest
   * @returns {Observable<Connection>}
   */
  public setupConnection(connectionSetupRequest: ConnectionSetupRequest): Observable<Connection> {
    return this.httpService
      .post(Endpoint.AUTOMATION_CONNECTION_CONFIGURATIONS(connectionSetupRequest.serviceId), this.getConnectionData(connectionSetupRequest))
      .pipe(
        map((response: GenericResponse) => deserialize(Connection, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getServiceConfig
   * @param {AvailableService} availableService
   * @param {AvailableServiceTenant} availableServiceTenant
   * @returns {Observable<ConnectionConfig>}
   */
  public getServiceConfig(
    availableService: AvailableService,
    availableServiceTenant?: AvailableServiceTenant,
  ): Observable<ConnectionConfig> {
    return this.httpService
      .get(
        Endpoint.AUTOMATION_CONNECTION_CONFIGURATIONS_ID(
          availableService.id,
          availableServiceTenant?.serviceConfigId ?? availableService.serviceConfigId,
        ),
      )
      .pipe(
        map((response: GenericResponse) => {
          return deserialize(ConnectionConfig, this.setConnectionData(response));
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * updateConnection
   * @param {string} serviceConfigId
   * @param {ConnectionSetupRequest} connectionSetupRequest
   * @returns {Observable<Connection>}
   */
  public updateConnection(serviceConfigId: string, connectionSetupRequest: ConnectionSetupRequest): Observable<Connection> {
    return this.httpService
      .put(
        Endpoint.AUTOMATION_CONNECTION_CONFIGURATIONS_ID(connectionSetupRequest.serviceId, serviceConfigId),
        this.getConnectionData(connectionSetupRequest, serviceConfigId),
      )
      .pipe(
        map((response: GenericResponse) => deserialize(Connection, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * deauthorizeConnection
   * @param {AvailableService} availableService
   * @param {AvailableServiceTenant} availableServiceTenant
   * @returns {Observable<any>}
   */
  public deauthorizeConnection(availableService: AvailableService, availableServiceTenant?: AvailableServiceTenant): Observable<any> {
    return this.httpService
      .delete(
        Endpoint.AUTOMATION_CONNECTION_CONFIGURATIONS_ID(
          availableService.id,
          availableServiceTenant?.serviceConfigId ?? availableService.serviceConfigId,
        ),
      )
      .pipe(catchError(requestErrorHandler));
  }

  /**
   * getConnectionDetails
   * @param {string} serviceId
   * @returns {Observable<AvailableService>}
   */
  public getConnectionDetails(serviceId: string): Observable<AvailableService> {
    return this.httpService.get(Endpoint.AUTOMATION_CONNECTION_ID(serviceId)).pipe(
      map((response: GenericResponse) => deserialize(AvailableService, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAvailablePartners
   * This service call will return all trust network partners
   * like Wandera which are already setup/integrated/connected with the org.
   * Note: Service will not return the partners which are feature enabled but not connected.
   *
   * @returns {Observable<AvailablePartner[]>}
   * @memberof ConnectionService
   */
  public getAvailablePartners(): Observable<AvailablePartner[]> {
    return this.httpService.get(Endpoint.PARTNERS).pipe(
      map((response: GenericResponse) => {
        return response.data.map((availablePartner: GenericResponse) => deserialize(AvailablePartner, availablePartner));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * setupPartnerConnection
   * Service Call to create or setup new Trust Network Partner Connection. Requires Trust Network Partner Name
   * and Email for new connection. Returns full fleged Trust Network Partner containing token as well.
   *
   * @param {PartnerConnectionSetupRequest} partnerConnectionSetupRequest
   * @returns {Observable<AvailablePartner>}
   * @memberof ConnectionService
   */
  public setupPartnerConnection(partnerConnectionSetupRequest: PartnerConnectionSetupRequest): Observable<AvailablePartner> {
    return this.httpService.post(Endpoint.PARTNERS, partnerConnectionSetupRequest).pipe(
      map((response: GenericResponse) => deserialize(AvailablePartner, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updatePartnerConnection
   * Service Call to update an existing Trust Network Partner Connection. Only Email can be updated using this call.
   * The call will return the updated Trust Network Partner details but without token.
   *
   * @param {PartnerConnectionSetupRequest} partnerConnectionSetupRequest
   * @returns {Observable<AvailablePartner>}
   * @memberof ConnectionService
   */
  public updatePartnerConnection(partnerConnectionSetupRequest: PartnerConnectionSetupRequest): Observable<AvailablePartner> {
    return this.httpService.put(Endpoint.PARTNERS_ID(partnerConnectionSetupRequest.id), partnerConnectionSetupRequest).pipe(
      map((response: GenericResponse) => deserialize(AvailablePartner, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * regeneratePartnerToken
   * Service call to regenerate the token for Trust Network Partner Connection. Returns full fleged Trust Network Partner
   * containing token as well.
   *
   * @param {string} partnerId
   * @returns {Observable<AvailablePartner>}
   * @memberof ConnectionService
   */
  public regeneratePartnerToken(partnerId: string): Observable<AvailablePartner> {
    return this.httpService.put(Endpoint.PARTNERS_ID_REGENERATE_TOKEN(partnerId), {}).pipe(
      map((response: GenericResponse) => deserialize(AvailablePartner, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deauthorizePartnerConnection
   * Service call to delete Trust Network Partner Connection.
   *
   * @param {string} partnerId
   * @returns {Observable<void>}
   * @memberof ConnectionService
   */
  public deauthorizePartnerConnection(partnerId: string): Observable<void> {
    return this.httpService.delete(Endpoint.PARTNERS_ID(partnerId)).pipe(catchError(requestErrorHandler));
  }

  /**
   * getEventForwardersTemplates
   * @returns {Observable<EventForwarderTemplate[]>}
   * @memberof ConnectionService
   */
  public getEventForwardersTemplates(): Observable<EventForwarderTemplate[]> {
    return this.httpService.get(Endpoint.PARTNERS_EVENT_FORWARDERS_TEMPLATES).pipe(
      map((response: GenericResponse) => deserialize(EventForwardersTemplatesResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAvailableEventForwarders
   * This service call will return all event forwarders which are already setup/integrated/connected
   * with the org.
   * Note: Service will not return the partners which are feature enabled but not connected.
   *
   * @returns {Observable<AvailableEventForwarder[]>}
   * @memberof ConnectionService
   */
  public getAvailableEventForwarders(): Observable<AvailableEventForwarder[]> {
    return this.httpService.get(Endpoint.PARTNERS_EVENT_FORWARDERS).pipe(
      map((response: GenericResponse) => {
        return response.data.map((availableEventForwarder: any) => {
          const availableEventForwarderWrapper: AvailableEventForwarderWrapper = deserialize(AvailableEventForwarderWrapper, {
            availableEventForwarder,
          });
          return availableEventForwarderWrapper.availableEventForwarder;
        });
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * setupEventForwarderConnection
   * Service Call to setup new Event Forwarder Connection. Requires Event Forwarder Name
   * and meta form fields for new connection. Returns newly created Event Forwarder Connection.
   *
   * @param {AvailableEventForwarder} availableEventForwarder
   * @param {GenericObject} metaFormFields
   * @returns {Observable<AvailableEventForwarder>}
   * @memberof ConnectionService
   */
  public setupEventForwarderConnection(
    availableEventForwarder: AvailableEventForwarder,
    metaFormFields: GenericObject,
  ): Observable<AvailableEventForwarder> {
    const postData = {
      ...metaFormFields,
      name: availableEventForwarder.name,
    };
    return this.httpService.post(Endpoint.PARTNERS_EVENT_FORWARDERS, postData).pipe(
      map((response: GenericResponse) => {
        const availableEventForwarderWrapper: AvailableEventForwarderWrapper = deserialize(AvailableEventForwarderWrapper, {
          availableEventForwarder: response.data,
        });
        return availableEventForwarderWrapper.availableEventForwarder;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateEventForwarderConnection
   * Service Call to cupdate existing Event Forwarder Connection. Requires Event Forwarder Id, Name
   * and mets form fields. Returns updated Event Forwarder Connection.
   *
   * @param {AvailableEventForwarder} availableEventForwarder
   * @param {GenericObject} metaFormFields
   * @returns {Observable<AvailableEventForwarder>}
   * @memberof ConnectionService
   */
  public updateEventForwarderConnection(
    availableEventForwarder: AvailableEventForwarder,
    metaFormFields: GenericObject,
  ): Observable<AvailableEventForwarder> {
    const postData = {
      ...metaFormFields,
      id: availableEventForwarder.id,
      name: availableEventForwarder.name,
    };
    return this.httpService.put(Endpoint.PARTNERS_EVENT_FORWARDERS_ID(availableEventForwarder.id), postData).pipe(
      map((response: GenericResponse) => {
        const availableEventForwarderWrapper: AvailableEventForwarderWrapper = deserialize(AvailableEventForwarderWrapper, {
          availableEventForwarder: response.data,
        });
        return availableEventForwarderWrapper.availableEventForwarder;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deauthorizeEventForwarderConnection
   * Service call to delete Event Forwarder Connection.
   *
   * @param {string} id
   * @returns {Observable<void>}
   * @memberof ConnectionService
   */
  public deauthorizeEventForwarderConnection(id: string): Observable<void> {
    return this.httpService.delete(Endpoint.PARTNERS_EVENT_FORWARDERS_ID(id)).pipe(catchError(requestErrorHandler));
  }

  /**
   * revokePartner
   * @param {string} partnerName
   * @returns {Observable<void>}
   * @memberof ConnectionService
   */
  public revokePartner(partnerName: string): Observable<void> {
    return this.httpService.delete(Endpoint.PARTNERS_NAME_OAUTH2_DEAUTHORIZE(partnerName)).pipe(catchError(requestErrorHandler));
  }

  /**
   * getConnectionData
   * For Multi tenant flow, the name and description is not tied to dynamic config-data and need to travel parallel to config
   *
   * @private
   * @param {ConnectionSetupRequest} connectionSetupRequest
   * @param {string} serviceConfigId
   * @returns {GenericObject}
   * @memberof ConnectionService
   */
  private getConnectionData(connectionSetupRequest: ConnectionSetupRequest, serviceConfigId?: string): GenericObject {
    return {
      name: connectionSetupRequest.settings?.name,
      description: connectionSetupRequest.settings?.description,
      config: new ConnectionSetupRequest({
        ...connectionSetupRequest,
        settings: {
          ...omit(connectionSetupRequest.settings, ['name', 'description']),
        },
      }),
      id: serviceConfigId,
    };
  }

  /**
   * setConnectionData
   * For Multi tenant flow, moving name and description `which are coming parallel to config` to dynamic config-data
   *
   * @private
   * @param {GenericResponse} response
   * @returns {GenericResponse}
   * @memberof ConnectionService
   */
  private setConnectionData(response: GenericResponse): GenericResponse {
    return {
      ...response.data.config,
      config_data: {
        ...response.data.config.config_data,
        ...pick(response.data, ['name', 'description']),
      },
    };
  }
}
