/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebError, WindowService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { find, isEmpty, isUndefined } from 'lodash-es';
import { merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EmailRequest, SuggestedAppsList } from '@dpa-shared-merlot/model';
import { AppLoadsService } from '@dpa-shared-merlot/services';
import { AppLoadsActions, AppLoadsSelectors, AppLoadsState, AppsDashboardActions, MerlotState } from '@dpa-shared-merlot/store';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, DashboardActions, UserPreferenceService } from '@ws1c/intelligence-core';
import {
  AirwatchAppSearchItem,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  App,
  AppDetailTabType,
  AppPlatform,
  AppRegistration,
  AppSearchRequest,
  AppType,
  ApteligentCrumbType,
  CrashAndroidCrumb,
  CrashIosCrumb,
  HandledExceptionAndroidCrumb,
  HandledExceptionIosCrumb,
  Integration,
  NetErrorCrumb,
  NetEventCrumb,
  PluginExceptionCrumb,
  RegisteredAppSearchResponse,
  RegisteredAppsList,
  ROUTE_NAMES,
  WizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * Effects for App Loads and Apteligent.
 * @export
 * @class AppLoadsEffects
 */
@Injectable()
export class AppLoadsEffects {
  /**
   * Get the list of apps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public searchApps: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.searchApps),
      switchMap(({ query }: ReturnType<typeof AppLoadsActions.searchApps>) => {
        return this.appLoadsService.searchApps(query).pipe(
          map((apps: AirwatchAppSearchItem[]) => AppLoadsActions.searchAppsSuccess({ apps })),
          catchError((error: WebError) => of(AppLoadsActions.searchAppsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Get appType & integration by app name
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public getAppTypeIntegration: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.getAppTypeIntegration),
      switchMap(({ query }: ReturnType<typeof AppLoadsActions.getAppTypeIntegration>) => {
        return this.appLoadsService.searchApps(query).pipe(
          map((response: AirwatchAppSearchItem[]) => AppLoadsActions.getAppTypeIntegrationSuccess({ apps: response })),
          catchError((error: WebError) => of(AppLoadsActions.getAppTypeIntegrationFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Get the list of suggested apps.
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public getSuggestedApps: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.getSuggestedApps),
      switchMap(({ query }: ReturnType<typeof AppLoadsActions.getSuggestedApps>) => {
        if (query) {
          return this.appLoadsService.getSuggestedApps(query).pipe(
            map((response: SuggestedAppsList) => AppLoadsActions.getSuggestedAppsSuccess({ apps: response })),
            catchError((error: WebError) => of(AppLoadsActions.getSuggestedAppsFailure({ error }))),
          );
        } else {
          return of(AppLoadsActions.emptySuggestedApps());
        }
      }),
    ),
  );

  /**
   * Get the list of non-deployed apps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public getNonDeployedApps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.getNonDeployedApps),
      switchMap(() => {
        return this.appLoadsService.getNonDeployedApps().pipe(
          map((response: AppRegistration[]) => AppLoadsActions.getNonDeployedAppsSuccess({ response })),
          catchError((error: WebError) => of(AppLoadsActions.getNonDeployedAppsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Navigate to the app details page, using the selected app ID
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public goToAppDetailPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.goToAppDetailPage),
      tap(({ app }: ReturnType<typeof AppLoadsActions.goToAppDetailPage>) => {
        if (app.integration === Integration.IDM) {
          this.routerExtensions.navigate([`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS_IDM}/${app.name}`], {
            queryParams: {
              appType: app.appType,
              integration: app.integration,
            },
          });
        } else {
          // I hope there will be an API like `(id, platform) => App`
          // Then I don't have to rely on passing app data in queryParams
          this.routerExtensions.navigate([`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS}/${app.id}/platform/${app.platform}`], {
            queryParams: {
              name: app.name,
              apteligentAppId: app.apteligentAppId,
              appType: app.appType,
              integration: app.integration,
              isProductivityApp: !!app.isProductivityApp,
            },
          });
        }
      }),
      map(() => AppLoadsActions.goToAppDetailPageSuccess()),
    ),
  );

  /**
   * goToAppDetailCrumbPage$
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public goToAppDetailCrumbPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.goToAppDetailCrumbPage),
      withLatestFrom(this.store.select(AppLoadsSelectors.getSelectedApp)),
      filter(([_, app]: [ReturnType<typeof AppLoadsActions.goToAppDetailCrumbPage>, App]) => !isEmpty(app)),
      map(([{ crumb }, app]: [ReturnType<typeof AppLoadsActions.goToAppDetailCrumbPage>, App]) => {
        const appQueryParams = {
          name: app.name,
          apteligentAppId: app.apteligentAppId,
          appType: app.appType,
          integration: app.integration,
          isProductivityApp: !!app.isProductivityApp,
        };

        if (ApteligentCrumbType.CRASH === crumb.crumbType) {
          const crashCrumb =
            app.platform.toLowerCase() === AppPlatform.ANDROID.toLowerCase()
              ? new CrashAndroidCrumb({ ...crumb.crumb })
              : new CrashIosCrumb({ ...crumb.crumb });
          const url = this.router.serializeUrl(
            this.router.createUrlTree(
              [`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS}/${app.id}/platform/${app.platform}/error/${crumb.crumbType}/SUMMARY`],
              {
                queryParams: {
                  ...appQueryParams,
                  crashId: crashCrumb.crashGroup,
                  errorLabel: crashCrumb.name || crashCrumb.errorType,
                },
              },
            ),
          );
          this.windowService.open('#'.concat(url), '_blank');
          return;
        } else if ([ApteligentCrumbType.NET_ERROR, ApteligentCrumbType.NET_EVENT].includes(crumb.crumbType)) {
          const netCrumb =
            ApteligentCrumbType.NET_EVENT === crumb.crumbType
              ? new NetEventCrumb({ ...crumb.crumb })
              : new NetErrorCrumb({ ...crumb.crumb });
          const url = this.router.serializeUrl(
            this.router.createUrlTree(
              [`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS}/${app.id}/platform/${app.platform}/NETWORK_INSIGHTS_DETAILS/${netCrumb.urlHost}`],
              {
                queryParams: {
                  ...appQueryParams,
                },
              },
            ),
          );
          this.windowService.open('#'.concat(url), '_blank');
          return;
        } else if (ApteligentCrumbType.HANDLED_EXCEPTION === crumb.crumbType) {
          const handledExceptionCrumb =
            app.platform.toLowerCase() === AppPlatform.ANDROID.toLowerCase()
              ? new HandledExceptionAndroidCrumb({ ...crumb.crumb })
              : new HandledExceptionIosCrumb({ ...crumb.crumb });
          const url = this.router.serializeUrl(
            this.router.createUrlTree(
              [`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS}/${app.id}/platform/${app.platform}/error/${crumb.crumbType}/SUMMARY`],
              {
                queryParams: {
                  ...appQueryParams,
                  errorName: handledExceptionCrumb.errorName,
                  errorReason: handledExceptionCrumb.errorReason,
                  errorLabel: handledExceptionCrumb.errorName,
                },
              },
            ),
          );
          this.windowService.open('#'.concat(url), '_blank');
          return;
        } else if (ApteligentCrumbType.PLUGIN_EXCEPTION === crumb.crumbType) {
          const pluginExceptionCrumb = new PluginExceptionCrumb({ ...crumb.crumb });
          const url = this.router.serializeUrl(
            this.router.createUrlTree(
              [`/${ROUTE_NAMES.DASHBOARD.APP_DETAILS}/${app.id}/platform/${app.platform}/error/${crumb.crumbType}/SUMMARY`],
              {
                queryParams: {
                  ...appQueryParams,
                  errorName: pluginExceptionCrumb.errorName,
                  errorReason: pluginExceptionCrumb.errorReason,
                  errorLabel: pluginExceptionCrumb.errorName,
                },
              },
            ),
          );
          this.windowService.open('#'.concat(url), '_blank');
          return;
        }
        return app;
      }),
      map(() => AppLoadsActions.goToAppDetailPageSuccess()),
    ),
  );

  /**
   * appFromParamsChanged$
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public appFromParamsChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.selectApp),
      filter(Boolean),
      withLatestFrom(this.store.select(AppLoadsSelectors.isSelectedAppIdmOnly)),
      switchMap(([_action, isIdmOnly]: [Action, boolean]) => {
        if (isIdmOnly) {
          return of(AppsDashboardActions.loadAppIdmDashboard());
        }
        return this.store.select(AppLoadsSelectors.getAppDetailTabType).pipe(
          filter((tabName: AppDetailTabType) => !isUndefined(tabName)),
          map(() => DashboardActions.loadAppDashboard()),
        );
      }),
    ),
  );

  /**
   * Get the list of apps.
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public saveAppRegistrations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.saveAppRegistrations),
      withLatestFrom(this.store.select(AppLoadsSelectors.appLoadsState)),
      switchMap(([_action, appLoadsState]: [Action, AppLoadsState]) => {
        const apps: AppRegistration[] = appLoadsState.selectedApps.map((app: any) => {
          return new AppRegistration({
            name: app.name,
            packageId: app.packageId,
            platform: app.platform,
          });
        });
        return this.appLoadsService.saveAppRegistrations(apps).pipe(
          map((response: RegisteredAppsList) => AppLoadsActions.saveAppRegistrationsSuccess({ apps: response.data })),
          catchError((error: WebError) => {
            const reason = error.getFullReason();
            return merge(
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  autoDismiss: true,
                  message: this.i18nService.translate('APTELIGENT.APP_REGISTRATION_FAILURE', { reason }),
                }),
              ),
              of(AppLoadsActions.saveAppRegistrationsFailure({ error })),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Show the alert success/ failure modal when app registration is done.
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public finishAppRegistration: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.finishAppRegistration),
      switchMap(() => {
        return merge(
          of(
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('APTELIGENT.APP_REGISTERED_SUCCESSFULLY'),
            }),
          ),
          of(AppLoadsActions.refreshAllRegisteredApps()),
        );
      }),
    ),
  );

  /**
   * Effect to send email to the users after app registration is done.
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public sendEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.sendEmail),
      withLatestFrom(this.store.select(AppLoadsSelectors.appLoadsState)),
      switchMap(([{ email }, appLoadsState]: [ReturnType<typeof AppLoadsActions.sendEmail>, any]) => {
        const emailRequest = new EmailRequest({
          properties: email,
          locale: this.userPreferenceService.browserLocale,
        });
        const alertBannerTarget =
          appLoadsState.wizardDialogMode === WizardDialogMode.ADD ? AlertBannerTarget.MODAL : AlertBannerTarget.PAGE;
        return this.appLoadsService.sendEmail(appLoadsState.sendEmailAppId, emailRequest).pipe(
          mergeMap(() => {
            return merge(
              of(AppLoadsActions.sendEmailSuccess()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  target: alertBannerTarget,
                  message: this.i18nService.translate('APTELIGENT.SEND_EMAIL_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  autoDismiss: true,
                  message: this.i18nService.translate('APTELIGENT.SEND_EMAIL_FAILURE', { error }),
                }),
              ),
              of(AppLoadsActions.sendEmailFailure()),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Get the list of all the apps registered with Apteligent
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public getAllRegisteredApps: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.getAllRegisteredApps),
      switchMap((props: ReturnType<typeof AppLoadsActions.getAllRegisteredApps>) => {
        return this.appLoadsService.getAllRegisteredApps(props.appSearchRequest).pipe(
          map((response: RegisteredAppSearchResponse) => AppLoadsActions.getAllRegisteredAppsSuccess({ response })),
          catchError((error: WebError) => of(AppLoadsActions.getAllRegisteredAppsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Register the Apteligent Admin User
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public registerApps: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.registerApps),
      withLatestFrom(this.store.select(AppLoadsSelectors.appLoadsState)),
      switchMap(() => {
        return of(AppLoadsActions.saveAppRegistrations());
      }),
    ),
  );

  /**
   * update Results of getAllRegisteredApps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public updateAllRegisteredAppsList: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppLoadsActions.sortAllRegisteredApps,
        AppLoadsActions.changePaginationAllRegisteredApps,
        AppLoadsActions.refreshAllRegisteredApps,
        AppLoadsActions.searchAllRegisteredApps,
      ),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.appLoadsState),
        (action: Action, appLoadsState: AppLoadsState) => appLoadsState.registeredAppRequest,
      ),
      map((appSearchRequest: AppSearchRequest) => {
        return AppLoadsActions.getAllRegisteredApps({ appSearchRequest });
      }),
    ),
  );

  /**
   * Get the list of all the productivity apps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public getAllProductivityApps: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.getAllProductivityApps),
      switchMap((props: ReturnType<typeof AppLoadsActions.getAllProductivityApps>) => {
        return this.appLoadsService.getAllProductivityApps(props.appSearchRequest).pipe(
          map((response: RegisteredAppsList) => {
            const formattedResponse: RegisteredAppSearchResponse = new RegisteredAppSearchResponse({
              total: response.data.length,
              results: response.data,
            });
            return AppLoadsActions.getAllProductivityAppsSuccess({ response: formattedResponse });
          }),
          catchError((error: WebError) => of(AppLoadsActions.getAllProductivityAppsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * update Results of getAllProductivityApps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public updateAllProductivityAppsList: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppLoadsActions.sortAllProductivityApps,
        AppLoadsActions.changePaginationAllProductivityApps,
        AppLoadsActions.refreshAllProductivityApps,
        AppLoadsActions.searchAllProductivityApps,
      ),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.appLoadsState),
        (action: Action, appLoadsState: AppLoadsState) => appLoadsState.productivityAppRequest,
      ),
      map((appSearchRequest: AppSearchRequest) => {
        return AppLoadsActions.getAllProductivityApps({ appSearchRequest });
      }),
    ),
  );

  /**
   * Show the copy success message when apteligent instructions are copied
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public copySuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.copySuccess),
      withLatestFrom(this.store.select(AppLoadsSelectors.getAppLoadsWizardMode)),
      switchMap(([_action, appLoadsWizardMode]) => {
        const target = appLoadsWizardMode === WizardDialogMode.ADD ? AlertBannerTarget.MODAL : AlertBannerTarget.PAGE;
        return of(
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.SUCCESS,
            target,
            message: this.i18nService.translate('COMMON_MESSAGES.COPIED_TO_CLIPBOARD'),
          }),
        );
      }),
    ),
  );

  /**
   * Show the copy failure message when apteligent instructions are not copied
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public copyFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.copyFailure),
      withLatestFrom(this.store.select(AppLoadsSelectors.getAppLoadsWizardMode)),
      switchMap(([_action, appLoadsWizardMode]) => {
        const target = appLoadsWizardMode === WizardDialogMode.ADD ? AlertBannerTarget.MODAL : AlertBannerTarget.PAGE;
        return of(
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.DANGER,
            target,
            message: this.i18nService.translate('COMMON_MESSAGES.FAILED_TO_COPY_TO_CLIPBOARD'),
          }),
        );
      }),
    ),
  );

  /**
   * Delete the registered app
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public deleteRegisteredApp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.deleteRegisteredApp),
      withLatestFrom(
        this.store.select(AppLoadsSelectors.getSelectedApteligentApp),
        (action: Action, app: AppRegistration) => app.apteligentAppId,
      ),
      switchMap((id: string) => {
        return this.appLoadsService.deleteRegisteredApps([id]).pipe(
          mergeMap(() => {
            return [
              AppLoadsActions.deleteRegisteredAppSuccess(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('APTELIGENT.DELETE_REGISTERED_APP_SUCCESS'),
              }),
              AppLoadsActions.refreshAllRegisteredApps(),
            ];
          }),
          catchError((error: WebError) => {
            const reason = error.getFullReason();
            return [
              AppLoadsActions.deleteRegisteredAppFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('APTELIGENT.DELETE_REGISTERED_APP_FAILURE', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * Delete the registered apps
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public deleteRegisteredApps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.deleteRegisteredApps),
      withLatestFrom(this.store.select(AppLoadsSelectors.getSelectedApteligentApps), (action: Action, apps: AppRegistration[]) =>
        apps.map((app: AppRegistration) => app.apteligentAppId),
      ),
      switchMap((ids: string[]) => {
        return this.appLoadsService.deleteRegisteredApps(ids).pipe(
          mergeMap(() => {
            return [
              AppLoadsActions.deleteRegisteredAppsSuccess(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('APTELIGENT.DELETE_REGISTERED_APPS_SUCCESS'),
              }),
              AppLoadsActions.refreshAllRegisteredApps(),
            ];
          }),
          catchError((error: WebError) => {
            const reason = error.getFullReason();
            return [
              AppLoadsActions.deleteRegisteredAppsFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('APTELIGENT.DELETE_REGISTERED_APPS_FAILURE', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * Search & pull App Detail from airwatch and then, go to detail page
   * @type {Observable<Action>}
   * @memberof AppLoadsEffects
   */
  public searchAppAndGoToDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppLoadsActions.searchAppAndGoToDetails),
      switchMap(({ app, urlSegment }: ReturnType<typeof AppLoadsActions.searchAppAndGoToDetails>) => {
        return this.appLoadsService.searchApps(app.id).pipe(
          map((apps: AirwatchAppSearchItem[]) => {
            const searchItem: AirwatchAppSearchItem = find(
              apps,
              (item: AirwatchAppSearchItem) =>
                item.packageId === app.id && item.name === app.name && item.apteligentAppId === app.apteligentAppId,
            );
            // override app with data from searchItem
            const targetApp: App = new App({
              ...app,
              ...searchItem,
              appType: searchItem ? searchItem.appType : AppType.PUBLIC,
              integration: searchItem ? searchItem.integration : Integration.APTELIGENT,
              isProductivityApp: !!app.isProductivityApp,
            });
            const url = urlSegment ? `/${urlSegment}` : '';
            this.routerExtensions.navigate([`/${ROUTE_NAMES.DASHBOARD.APP}${url}/details/${targetApp.id}/platform/${targetApp.platform}`], {
              queryParams: {
                ...targetApp,
              },
            });
            return AppLoadsActions.goToAppDetailPageSuccess();
          }),
        );
      }),
    ),
  );

  /**
   * Creates an instance of AppLoadsEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {AppLoadsService} appLoadsService
   * @param {I18NService} i18nService
   * @param {Router} router
   * @param {RouterExtensions} routerExtensions
   * @param {UserPreferenceService} userPreferenceService
   * @param {WindowService} windowService
   * @memberof AppLoadsEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private appLoadsService: AppLoadsService,
    private i18nService: I18NService,
    private router: Router,
    private routerExtensions: RouterExtensions,
    private userPreferenceService: UserPreferenceService,
    private windowService: WindowService,
  ) {}
}
