/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map as _map, isEmpty, size } from 'lodash-es';
import { forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { AccountGroupService, DataAccessPolicyService } from '@dpa-shared-merlot/services';
import { AccountSelectors, DataAccessPolicyActions, DataAccessPolicySelectors, MerlotState } from '@dpa-shared-merlot/store';
import { AccountActions } from '@dpa-shared-merlot/store/account/account.actions';
import {
  IntegrationOrgGroup,
  PlatformRole,
  ServiceRole,
  UserExistenceStatus,
  UserExistenceStatusType,
} from '@ws1c/admin-management/models';
import { AdminsInvitationActions, RolesActions } from '@ws1c/admin-management/store';
import { getServiceRolesForAssignment } from '@ws1c/admin-management/utils';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import {
  AccountService,
  AlertBannerActions,
  UserPreferenceActions,
  UserPreferenceCommonSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core';
import {
  Account,
  AccountGroup,
  AccountGroupSearchResponse,
  AccountRole,
  AccountSearchResponse,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AppConstants,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicyBulkAssignRequest,
  GenericSearchRequest,
  IntegratedServiceType,
  IntegrationDetails,
  IntegrationGroup,
  LOAD_STATE,
  RolesSearchResponse,
  ROUTE_NAMES,
  UserAdminAccount,
  UserDescriptor,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for Account actions
 * @export
 * @class AccountEffects
 */
@Injectable()
export class AccountEffects {
  /**
   * getAccounts$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAccounts),
      switchMap(({ searchRequest }: ReturnType<typeof AccountActions.getAccounts>) => {
        return this.accountService.getAccounts(searchRequest).pipe(
          map((accountSearchResponse: AccountSearchResponse) =>
            AccountActions.getAccountsSuccess({
              accountSearchResponse,
            }),
          ),
          catchError((error: WebError) =>
            of(
              AccountActions.getAccountsFailure({
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * refreshAccounts$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public refreshAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.refreshAccounts),
      withLatestFrom(
        this.store.select(AccountSelectors.getAccountSearchRequest),
        (action: Action, accountSearchRequest: GenericSearchRequest) => accountSearchRequest,
      ),
      map((searchRequest: GenericSearchRequest) => {
        return AccountActions.getAccounts({ searchRequest });
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public goToAssignAccountRoles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.goToAssignAccountRoles),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT_ADD}`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public goToAssignGroupRoles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.goToAssignGroupRoles),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT_GROUP_ADD}`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public goToAdministratorHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.goToAdministratorHome),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT}`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public goToAdministratorGroupHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.goToAdministratorGroupHome),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT_GROUP}`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public goToAdministratorInviteHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.goToAdministratorInviteHome),
      mergeMap((action: ReturnType<typeof AccountActions.goToAdministratorInviteHome>) => {
        const usersExistenceStatus: UserExistenceStatus[] = [
          new UserExistenceStatus({
            userName: action.selectedEmail,
            userExistenceStatus: UserExistenceStatusType.NON_EXIST,
          }),
        ];
        const platformRoles: PlatformRole[] = [];
        action.selectedAccountRoles.forEach((role: AccountRole) => {
          role.serviceType = IntegratedServiceType.INTELLIGENCE;
        });
        const serviceRoles: ServiceRole[] = getServiceRolesForAssignment(
          action.selectedAccountRoles,
          new IntegrationOrgGroup().tenantUuid,
          new IntegrationDetails(),
        );
        return [
          AdminsInvitationActions.setUsersExistenceStatus({
            status: LOAD_STATE.SUCCESS,
            usersExistenceStatus,
          }),
          AdminsInvitationActions.setSelectedAccountId({ selectedAccountId: action.selectedAccountId }),
          RolesActions.resetSelectedRoles(),
          RolesActions.setSelectedRoles({ platformRoles, serviceRoles }),
        ];
      }),
      tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.SETTINGS.ADMIN_INVITE}`])),
    ),
  );

  /**
   * deleteAccount$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public deleteAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteAccount),
      switchMap(({ id }: ReturnType<typeof AccountActions.deleteAccount>) => {
        return this.accountService.deleteAccount(id).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.refreshAccounts()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.DEACTIVATE_ADMINISTRATOR_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.deleteAccountFailure({ error })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.DEACTIVATE_ADMINISTRATOR_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * deleteAccounts$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public deleteAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteAccounts),
      withLatestFrom(this.store.select(AccountSelectors.getSelectedActiveAccounts), (action: Action, accounts: Account[]) =>
        accounts.map((account: Account) => account.id),
      ),
      switchMap((ids: string[]) => {
        return this.accountService.deleteAccounts(ids).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.refreshAccounts()),
              of(AccountActions.goToAdministratorHome()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.DEACTIVATE_ADMINISTRATORS_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.deleteAccountsFailure({ error })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.DEACTIVATE_ADMINISTRATORS_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * getRoles$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getRoles),
      switchMap(() => {
        return this.accountService.getIamRoles().pipe(
          map((roles: AccountRole[]) => AccountActions.getRolesSuccess({ roles })),
          catchError((error: WebError) => of(AccountActions.getRolesFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * getUsersForAddAccount$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getUsersForAddAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getUsersForAddAccount),
      // Limiting the rate at which this call is fired
      debounceTime(400),
      switchMap(({ searchTerm }: ReturnType<typeof AccountActions.getUsersForAddAccount>) => {
        return this.accountService.getUsersForAddAccount(searchTerm).pipe(
          map((userAdminAccounts: UserAdminAccount[]) => AccountActions.getUsersForAddAccountSuccess({ userAdminAccounts })),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.getUsersForAddAccountFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.USERS_SEARCH_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * addUser$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public addUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.addAccount),
      withLatestFrom(
        this.store.select(AccountSelectors.getSelectedDataAccessPolicyForAccount),
        this.store.select(DataAccessPolicySelectors.getAssignedAccounts),
      ),
      switchMap(
        ([{ userRoleAssignmentRequest }, dataAccessPolicy, accountsForPolicy]: [
          ReturnType<typeof AccountActions.addAccount>,
          DataAccessPolicy,
          Account[],
        ]) => {
          return this.accountService.addUserAndUpdateStatus(userRoleAssignmentRequest).pipe(
            mergeMap((response: Account) => {
              let actions = [];
              actions.push(AccountActions.addAccountSuccess());
              const accountIds = _map(
                accountsForPolicy,
                (accountForPolicy: Account) =>
                  new UserDescriptor({
                    id: accountForPolicy.id,
                  }),
              );
              if (dataAccessPolicy) {
                accountIds.push(
                  new UserDescriptor({
                    id: response.id,
                  }),
                );
                const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
                  policyIds: [dataAccessPolicy.id],
                  accountIds,
                  redirectToPolicyHome: false,
                  successMessageKey: 'ADMINISTRATOR.ADD_ADMINISTRATOR_SUCCESS',
                });
                actions.push(DataAccessPolicyActions.assignUsers({ bulkAssignRequest }));
              } else {
                actions = actions.concat([
                  AccountActions.goToAdministratorHome(),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('ADMINISTRATOR.ADD_ADMINISTRATOR_SUCCESS'),
                  }),
                ]);
              }
              return actions;
            }),
            catchError((error: WebError) => {
              return merge(
                of(AccountActions.addAccountFailure({ error })),
                of(
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    target: AlertBannerTarget.MODAL,
                    message: this.i18nService.getLocalizedError('ADMINISTRATOR.ADD_ADMINISTRATOR_FAILURE', error),
                  }),
                ),
              );
            }),
          );
        },
      ),
    ),
  );

  /**
   * updateAccount$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public updateAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateAccount),
      withLatestFrom(
        this.store.select(AccountSelectors.getSelectedDataAccessPolicyForAccount),
        this.store.select(DataAccessPolicySelectors.getAssignedAccounts),
        this.store.select(UserPreferenceCommonSelectors.getSystemObjectId(AppConstants.DEFAULT_POLICY_SYSTEM_ID_KEY)),
      ),
      switchMap(
        ([{ account }, dataAccessPolicy, accountsForPolicy, defaultPermitAllId]: [
          ReturnType<typeof AccountActions.updateAccount>,
          DataAccessPolicy,
          Account[],
          string,
        ]) => {
          return this.accountService.updateUser(account).pipe(
            mergeMap(() => {
              let actions = [];
              actions.push(UserPreferenceActions.refreshUserAccount());
              const accountIds = _map(
                accountsForPolicy,
                (accountForPolicy: Account) =>
                  new UserDescriptor({
                    id: accountForPolicy.id,
                  }),
              );
              if (dataAccessPolicy) {
                // New Policy is assigned
                // Assign to new policy. Users will be automatically unassigned from existing policies
                accountIds.push(
                  new UserDescriptor({
                    id: account.id,
                  }),
                );
                const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
                  policyIds: [dataAccessPolicy.id],
                  accountIds,
                  redirectToPolicyHome: false,
                  successMessageKey: 'ADMINISTRATOR.EDIT_ADMINISTRATOR_SUCCESS',
                });
                actions.push(DataAccessPolicyActions.assignUsers({ bulkAssignRequest }));
              } else if (
                !isEmpty(account.dataAccessPolicies) &&
                !account.dataAccessPolicies[0]?.isDefaultPolicyAssigned(defaultPermitAllId)
              ) {
                // No Policy is assigned but user has data access policy
                // Unassign from existing policy
                const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
                  policyIds: [account.dataAccessPolicies[0].id],
                  accountIds: [
                    new UserDescriptor({
                      id: account.id,
                    }),
                  ],
                });
                actions.push(DataAccessPolicyActions.unAssignUsers({ bulkAssignRequest }));
              } else {
                // No Policy is assigned
                // Show the success message
                actions = actions.concat([
                  AccountActions.goToAdministratorHome(),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('ADMINISTRATOR.EDIT_ADMINISTRATOR_SUCCESS'),
                  }),
                ]);
              }
              return actions;
            }),
            catchError((error: WebError) => {
              return merge(
                of(AccountActions.updateAccountFailure({ error })),
                of(
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    target: AlertBannerTarget.MODAL,
                    message: this.i18nService.getLocalizedError('ADMINISTRATOR.EDIT_ADMINISTRATOR_FAILURE', error),
                  }),
                ),
              );
            }),
          );
        },
      ),
    ),
  );

  /**
   * updateAccounts$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public updateAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateAccounts),
      withLatestFrom(
        this.store.select(AccountSelectors.getSelectedAccounts),
        this.store.select(AccountSelectors.getSelectedDataAccessPolicyForAccount),
        this.store.select(DataAccessPolicySelectors.getAssignedAccounts),
      ),
      switchMap(([_payload, accounts, dataAccessPolicy, accountsForPolicy]: [any, Account[], DataAccessPolicy, Account[]]) => {
        return this.accountService.updateUsers(accounts).pipe(
          mergeMap(() => {
            let actions = [];
            actions.push(AccountActions.updateAccountsSuccess());
            if (dataAccessPolicy) {
              const policyAccountIds = _map(accountsForPolicy, 'id');
              const updatedAccountIds = _map(accounts, 'id').filter((id: string) => !policyAccountIds.includes(id));
              const accountIds = policyAccountIds.concat(updatedAccountIds);
              const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
                policyIds: [dataAccessPolicy.id],
                accountIds: accountIds.map((id: string) => new UserDescriptor({ id })),
                redirectToPolicyHome: false,
                successMessageKey: 'ADMINISTRATOR.EDIT_ADMINISTRATORS_SUCCESS',
              });
              actions.push(DataAccessPolicyActions.assignUsers({ bulkAssignRequest }));
            } else {
              actions = actions.concat([
                AccountActions.goToAdministratorHome(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.EDIT_ADMINISTRATORS_SUCCESS'),
                }),
              ]);
            }
            return actions;
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.updateAccountsFailure({ error })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.EDIT_ADMINISTRATORS_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * getAssignPolicyPreviewAndSave$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getAssignPolicyPreviewAndSave$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAssignPolicyPreviewAndSave),
      withLatestFrom(
        this.store.select(AccountSelectors.getSelectedDataAccessPolicyForAccount),
        this.store.select(DataAccessPolicySelectors.getAssignedAccounts),
      ),
      switchMap(
        ([{ selectedAccounts }, dataAccessPolicy, accountsForPolicy]: [
          ReturnType<typeof AccountActions.getAssignPolicyPreviewAndSave>,
          DataAccessPolicy,
          Account[],
        ]) => {
          if (!dataAccessPolicy) {
            return of(AccountActions.updateAccounts());
          }
          const policyAccountIds = _map(accountsForPolicy, 'id');
          const updatedAccountIds = _map(selectedAccounts, 'id').filter((id: string) => !policyAccountIds.includes(id));
          const accountIds = policyAccountIds.concat(updatedAccountIds);
          const bulkAssignRequest = new DataAccessPolicyBulkAssignRequest({
            policyIds: [dataAccessPolicy.id],
            accountIds: accountIds.map((id: string) => new UserDescriptor({ id })),
          });
          return this.dataAccessPolicyService.getAssignUsersPreview(bulkAssignRequest).pipe(
            map((preview: DataAccessPolicyAssignPreview) => {
              if (isEmpty(preview.accountsToMove)) {
                return AccountActions.updateAccounts();
              }
              return DataAccessPolicyActions.setAssignUsersPreview({
                preview,
              });
            }),
            catchError((error: WebError) => {
              return of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.EDIT_ADMINISTRATORS_FAILURE', error),
                }),
              );
            }),
          );
        },
      ),
    ),
  );

  /**
   * getAccountGroups$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getAccountGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAccountGroups),
      switchMap(({ searchRequest }: ReturnType<typeof AccountActions.getAccountGroups>) => {
        return this.accountGroupService.getAccountsGroupsWithAssignment(searchRequest).pipe(
          map((accountGroupSearchResponse: AccountGroupSearchResponse) => {
            return AccountActions.getAccountGroupsSuccess({ accountGroupSearchResponse });
          }),
          catchError((error: WebError) => of(AccountActions.getAccountGroupsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * getIntegrationGroups$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getIntegrationGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getIntegrationGroups),
      // Backend communicates with Azure AD for fetching the groups
      // Limiting the rate at which this call is fired
      debounceTime(400),
      switchMap(({ searchRequest }: ReturnType<typeof AccountActions.getIntegrationGroups>) => {
        return this.accountGroupService.getIntegrationGroups(searchRequest).pipe(
          map((integrationGroups: IntegrationGroup[]) => AccountActions.getIntegrationGroupsSuccess({ integrationGroups })),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.getIntegrationGroupsFailure({ error })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.GROUP_SEARCH_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * addGroup$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public addGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.addGroup),
      switchMap(({ accountGroupRolesRequest }: ReturnType<typeof AccountActions.addGroup>) => {
        return this.accountGroupService.addGroup(accountGroupRolesRequest).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.addGroupSuccess()),
              of(AccountActions.goToAdministratorGroupHome()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.ADD_GROUP_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.addGroupFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.ADD_GROUP_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * updateGroup$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public updateGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateGroup),
      switchMap(({ accountGroup, isEditMode }: ReturnType<typeof AccountActions.updateGroup>) => {
        const serviceCalls: Array<Observable<boolean>> = [this.accountGroupService.updateGroup(accountGroup)];
        // Group is always active when it's created, thus in edit mode only we are enabling/disabling a group
        if (isEditMode) {
          serviceCalls.push(this.accountGroupService.updateGroupStatus(accountGroup));
        }
        return forkJoin(serviceCalls).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.updateGroupSuccess()),
              of(AccountActions.goToAdministratorGroupHome()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.EDIT_GROUP_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.updateGroupFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.EDIT_GROUP_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * deleteGroup$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public deleteGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteGroup),
      switchMap(({ id }: ReturnType<typeof AccountActions.deleteGroup>) => {
        return this.accountGroupService.deleteAccountGroup(id).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.refreshGroups()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.DEACTIVATE_GROUP_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.deleteGroupFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.DEACTIVATE_GROUP_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * deleteGroups$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public deleteGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteGroups),
      withLatestFrom(this.store.select(AccountSelectors.getSelectedGroups), (action: Action, groups: AccountGroup[]) =>
        groups.map((g) => g.id),
      ),
      switchMap((ids: string[]) => {
        return this.accountGroupService.deleteAccountGroups(ids).pipe(
          mergeMap(() => {
            return merge(
              of(AccountActions.refreshGroups()),
              of(AccountActions.goToAdministratorGroupHome()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('ADMINISTRATOR.DEACTIVATE_GROUPS_SUCCESS'),
                }),
              ),
            );
          }),
          catchError((error: WebError) => {
            return merge(
              of(AccountActions.deleteGroupsFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.getLocalizedError('ADMINISTRATOR.DEACTIVATE_GROUPS_FAILURE', error),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * refreshGroups$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public refreshGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.refreshGroups),
      withLatestFrom(
        this.store.select(AccountSelectors.getGroupSearchRequest),
        (action: Action, searchRequest: GenericSearchRequest) => searchRequest,
      ),
      map((searchRequest: GenericSearchRequest) => {
        return AccountActions.getAccountGroups({ searchRequest });
      }),
    ),
  );

  /**
   * getAccountGroupByDirectoryGroupId$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public getAccountGroupByDirectoryGroupId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAccountGroupByDirectoryGroupId),
      switchMap(({ directoryGroupId, directoryType }: ReturnType<typeof AccountActions.getAccountGroupByDirectoryGroupId>) => {
        return this.accountGroupService.getAccountGroupByDirectoryGroupId(directoryGroupId, directoryType).pipe(
          map((accountGroup: AccountGroup) => AccountActions.getAccountGroupByDirectoryGroupIdSuccess({ accountGroup })),
          catchError(() => of(AccountActions.getAccountGroupByDirectoryGroupIdFailure())),
        );
      }),
    ),
  );

  /**
   * @type {Observable<any>}
   * @memberof AccountEffects
   */
  public handleAadCommunicationError$ = createEffect(() =>
    this.actions$.pipe(
      startWith(AccountActions.handleAadCommunicationError()),
      withLatestFrom(this.store.select(UserPreferenceSelectors.getInitialUrlQueryParams)),
      filter(([_action, initialUrlQueryParams]: [Action, GenericObject]) => initialUrlQueryParams?.aadCommunicationError === 'true'),
      take(1),
      map(() =>
        AlertBannerActions.showAlertBanner({
          alertType: ALERT_BANNER_TYPE.DANGER,
          target: AlertBannerTarget.APP,
          message: this.i18nService.translate('INTEGRATIONS.AZURE.COMMUNICATION_FAILURE_MESSAGE'),
        }),
      ),
    ),
  );

  /**
   * selectAccounts$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public selectAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.selectAccounts),
      map(({ selectedAccounts }: ReturnType<typeof AccountActions.selectAccounts>) => {
        const selectedDataAccessPolicy =
          size(selectedAccounts) === 1 && !isEmpty(selectedAccounts[0].dataAccessPolicies)
            ? selectedAccounts[0].dataAccessPolicies[0]
            : null;
        return AccountActions.selectDataAccessPolicy({ selectedDataAccessPolicy });
      }),
    ),
  );

  /**
   * selectDataAccessPolicy$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public selectDataAccessPolicy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.selectDataAccessPolicy),
      withLatestFrom(this.store.select(UserPreferenceCommonSelectors.getSystemObjectId(AppConstants.DEFAULT_POLICY_SYSTEM_ID_KEY))),
      filter(([{ selectedDataAccessPolicy }, defaultPermitAllId]: [ReturnType<typeof AccountActions.selectDataAccessPolicy>, string]) => {
        return selectedDataAccessPolicy && !selectedDataAccessPolicy.isDefaultPolicyAssigned(defaultPermitAllId);
      }),
      map(([{ selectedDataAccessPolicy }, _defaultPermitAllId]: [ReturnType<typeof AccountActions.selectDataAccessPolicy>, string]) => {
        return DataAccessPolicyActions.getAssignedUsers({
          id: selectedDataAccessPolicy.id,
        });
      }),
    ),
  );

  /**
   * searchIntelligenceRoles$
   * @type {Observable<Action>}
   * @memberof AccountEffects
   */
  public searchIntelligenceRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.searchIntelligenceRoles, AccountActions.setIntelligenceRolesSearchTerm),
      withLatestFrom(this.store.select(AccountSelectors.getIntelligenceRolesSearchRequest)),
      switchMap(([_action, request]: [Action, GenericSearchRequest]) => {
        return this.accountService.searchIamRoles(request).pipe(
          map((response: RolesSearchResponse) =>
            AccountActions.getIntelligenceRolesSuccess({
              intelligenceRolesResponse: response,
            }),
          ),
          catchError((webError: WebError) => [
            AccountActions.getIntelligenceRolesFailure({ error: webError }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMINISTRATOR.GET_SERVICE_ROLES_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * Creates an instance of AccountEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @param {AccountService} accountService
   * @param {AccountGroupService} accountGroupService
   * @param {I18NService} i18nService
   * @param {DataAccessPolicyService} dataAccessPolicyService
   * @param {RouterExtensions} routerExtensions
   * @memberof AccountEffects
   */
  constructor(
    private store: Store<MerlotState>,
    private actions$: Actions,
    private accountService: AccountService,
    private accountGroupService: AccountGroupService,
    private i18nService: I18NService,
    private dataAccessPolicyService: DataAccessPolicyService,
    private routerExtensions: RouterExtensions,
  ) {}
}
