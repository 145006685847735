/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { CORE_EFFECTS } from '@ws1c/intelligence-core';
import { AccountEffects } from './account.effects';
import { AppLoadsEffects } from './app-loads.effects';
import { ConnectionEffects } from './connection.effects';
import { DASHBOARD_EFFECTS } from './dashboard';
import { DataAccessPolicyEffects } from './data-access-policy.effects';
import { DataExplorerEffects } from './data-explorer.effects';
import { GlobalSearchEffects } from './global-search.effects';
import { IncidentDashboardEffects } from './incident-dashboard.effects';
import { IntegrationEffects } from './integration.effects';
import { NonAdminEffects } from './non-admin.effects';
import { ServiceAccountEffects } from './service-accounts.effects';
import { SystemLimitsEffects } from './system-limits.effects';
import { UserAdminAccountEffects } from './user-admin-account.effects';
import { UserEffects } from './user.effects';
import { WS1BrokerEffects } from './ws1-broker.effects';

/**
 * This export can be simplified by using [Effect-1, Effect-2, ...Effect-n].map((effect) => effect)).
 * However, AOT compilation will fail if we do that way. That's why we see repeat EffectsModule.runAfterBootstrap call here.
 * runAfterBootstrap is required for effects that use services from @angular/router
 */
export const appEffectsRun = [...CORE_EFFECTS];

export const featureEffectsRun = [
  ...DASHBOARD_EFFECTS,
  AccountEffects,
  AppLoadsEffects,
  ConnectionEffects,
  DataAccessPolicyEffects,
  DataExplorerEffects,
  GlobalSearchEffects,
  IncidentDashboardEffects,
  IntegrationEffects,
  NonAdminEffects,
  ServiceAccountEffects,
  SystemLimitsEffects,
  UserAdminAccountEffects,
  UserEffects,
  WS1BrokerEffects,
];

export * from './account.effects';
export * from './app-loads.effects';
export * from './connection.effects';
export * from './dashboard';
export * from './data-access-policy.effects';
export * from './data-explorer.effects';
export * from './incident-dashboard.effects';
export * from './integration.effects';
export * from './service-accounts.effects';
export * from './system-limits.effects';
export * from './user-admin-account.effects';
export * from './user.effects';
