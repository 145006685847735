/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { IntegrationActions, LoginRiskDashboardActions, LoginRiskDashboardSelectors } from '@dpa-shared-merlot/store';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { DashboardActions } from '@ws1c/intelligence-core';
import { Integration, ROUTE_NAMES, SettingsFilterTag, StandardDashboardRequest } from '@ws1c/intelligence-models';

/**
 * LoginRiskDashboardEffects
 * @export
 * @class LoginRiskDashboardEffects
 */
@Injectable()
export class LoginRiskDashboardEffects {
  /**
   * loadDashboard$
   * @type {Observable<Action>}
   * @memberof LoginRiskDashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginRiskDashboardActions.loadDashboard, LoginRiskDashboardActions.setDashboardFilters),
      withLatestFrom(this.store.select(LoginRiskDashboardSelectors.getStandardDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * goToIDMSetup$
   * @memberof LoginRiskDashboardEffects
   */
  public goToIDMSetup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginRiskDashboardActions.goToIDMSetup),
        exhaustMap(() => {
          const route = ROUTE_NAMES.INTEGRATIONS.HOME;
          this.routerExtensions.navigate([route], {
            queryParams: {
              filter: SettingsFilterTag[SettingsFilterTag.INTEGRATION],
            },
          });
          return [
            IntegrationActions.setCurrentIntegration({ integration: Integration.IDM }),
            IntegrationActions.showSetupModal({ showSetupModal: true }),
          ];
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of LoginRiskDashboardEffects.
   * @param {Actions} actions$
   * @param {Store} store
   * @param {RouterExtensions} routerExtensions
   * @memberof LoginRiskDashboardEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store,
    private routerExtensions: RouterExtensions,
  ) {}
}
