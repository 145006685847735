/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, SortOn, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { GlobalSearchActions, MerlotState } from '@dpa-shared-merlot/store';
import { RouterExtensions } from '@ws1c/intelligence-common';
import {
  AutomationService,
  CustomReportService,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core';
import { DashboardService } from '@ws1c/intelligence-core/services/dashboard.service';
import {
  AutomationSearchRequest,
  COLUMN_NAMES,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  DashboardView,
  Device,
  DeviceUser,
  GenericSearchRequest,
  ROUTE_NAMES,
  SearchTerm,
  StandardDashboard,
  Trend,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for Global search actions
 * @export
 * @class GlobalSearchEffects
 */
@Injectable()
export class GlobalSearchEffects {
  /**
   * getOsMetadata$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public getOsMetadata$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.getOsMetadata),
      withLatestFrom(
        this.store.select(UserPreferenceFeatureControlsSelectors.isUemV2DashboardToggleEnabled),
        this.store.select(UserPreferenceUIPreferenceSelectors.isUemV2DashboardToggleEnabled),
      ),
      switchMap(
        ([{ request }, isUemV2DashboardToggleEnabledInFF, isUemV2DashboardToggleEnabledInUI]: [
          ReturnType<typeof GlobalSearchActions.getOsMetadata>,
          boolean,
          boolean,
        ]) => {
          const isUemV2Enabled: boolean = isUemV2DashboardToggleEnabledInFF && isUemV2DashboardToggleEnabledInUI;
          return this.dashboardService.getStandardDashboardByType(request, true, isUemV2Enabled).pipe(
            map((dashboard: StandardDashboard) => {
              return GlobalSearchActions.getOsMetadataSuccess({ osMetaData: dashboard.trendDefinitionMap });
            }),
            catchError((error: WebError) => of(GlobalSearchActions.getOsMetadataFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * searchOsUpdate$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchOsUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchOsUpdate),
      debounceTime(300),
      switchMap(({ query }: ReturnType<typeof GlobalSearchActions.searchOsUpdate>) => {
        return this.dashboardService.getStandardWidgetsData(query, true).pipe(
          map((results: Map<string, Trend>) => {
            return GlobalSearchActions.searchOsUpdateSuccess({ results });
          }),
          catchError((error: WebError) => of(GlobalSearchActions.searchOsUpdateFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public goToOsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalSearchActions.goToOsPage),
        tap(({ name }: ReturnType<typeof GlobalSearchActions.goToOsPage>) =>
          this.routerExtensions.navigate([ROUTE_NAMES.DASHBOARD.OS, name]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * searchWidgets$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchWidgets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchWidgets),
      debounceTime(300),
      switchMap(({ query }: ReturnType<typeof GlobalSearchActions.searchWidgets>) => {
        const searchRequest: GenericSearchRequest = Object.assign(new GenericSearchRequest(), {
          searchTerm: Object.assign(new SearchTerm(), {
            value: query,
          }),
        });
        return this.dashboardService.getDashboardWidgetsByName(searchRequest).pipe(
          map((response: DashboardView[]) => {
            return GlobalSearchActions.searchWidgetsSuccess({ dashboards: response });
          }),
          catchError((error: WebError) => of(GlobalSearchActions.searchWidgetsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * searchDevices$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchDevices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchDevices),
      debounceTime(300),
      switchMap(({ query }: ReturnType<typeof GlobalSearchActions.searchDevices>) => {
        const searchRequest: GenericSearchRequest = new GenericSearchRequest({
          searchTerm: new SearchTerm({
            value: query,
          }),
        });
        return this.dashboardService.getDevicesByName(searchRequest).pipe(
          map((response: Device[]) => GlobalSearchActions.searchDevicesSuccess({ devices: response })),
          catchError((error: WebError) => of(GlobalSearchActions.searchDevicesFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * searchUsers$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchUsers),
      debounceTime(300),
      switchMap(({ query }: ReturnType<typeof GlobalSearchActions.searchUsers>) => {
        const searchRequest: GenericSearchRequest = new GenericSearchRequest({
          searchTerm: new SearchTerm({
            value: query,
          }),
        });
        return this.dashboardService.getUsersByName(searchRequest).pipe(
          map((users: DeviceUser[]) => GlobalSearchActions.searchUsersSuccess({ users })),
          catchError((error: WebError) => of(GlobalSearchActions.searchUsersFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * searchReports$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchReports$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchReports),
      debounceTime(300),
      map(({ query }: ReturnType<typeof GlobalSearchActions.searchReports>) => {
        return new CustomReportSearchRequest({
          from: 0,
          size: 50,
          searchTerms: [
            new SearchTerm({
              value: query,
              fields: [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description],
            }),
          ],
          sortOns: [
            new SortOn({
              by: COLUMN_NAMES.byName.name,
              reverse: false,
            }),
          ],
        });
      }),
      switchMap((searchRequest: CustomReportSearchRequest) => {
        return this.customReportsService.getCustomReports(searchRequest).pipe(
          map((response: CustomReportSearchResponse) => GlobalSearchActions.searchReportsSuccess({ reports: response.results })),
          catchError((error: WebError) => of(GlobalSearchActions.searchReportsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * searchAutomations$
   * @type {Observable<Action>}
   * @memberof GlobalSearchEffects
   */
  public searchAutomations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.searchAutomations),
      debounceTime(300),
      map(({ query }: ReturnType<typeof GlobalSearchActions.searchAutomations>) => {
        return new AutomationSearchRequest({
          from: 0,
          size: 50,
          searchTerms: [
            new SearchTerm({
              value: query,
              fields: [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description],
            }),
          ],
          sortOns: [
            new SortOn({
              by: COLUMN_NAMES.byName.name,
              reverse: false,
            }),
          ],
        });
      }),
      switchMap((searchRequest: AutomationSearchRequest) => {
        return this.automationService.getAutomations(searchRequest).pipe(
          map((response: GenericObject) => GlobalSearchActions.searchAutomationsSuccess({ automations: response.results })),
          catchError((error: WebError) => of(GlobalSearchActions.searchAutomationsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * constructor
   * @param {Actions} actions$
   * @param {Store<MerlotState>} store
   * @param {DashboardService} dashboardService
   * @param {RouterExtensions} routerExtensions
   * @param {CustomReportService} customReportsService
   * @param {AutomationService} automationService
   * @memberof GlobalSearchEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store<MerlotState>,
    private dashboardService: DashboardService,
    private routerExtensions: RouterExtensions,
    private customReportsService: CustomReportService,
    private automationService: AutomationService,
  ) {}
}
